import React, { Component } from "react";
import { each, isEqual, sortBy, get, filter } from "lodash";
import PropTypes from "prop-types";
import { FocusPicker } from "image-focus";
import { toast } from "react-toastify";
import ValidationUtils from "utils/ValidationUtils";
import { ToastComponent } from "components/toastComponent";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";

import ToastUtils from "utils/handleToast";

const EMPTY_FEILD_ERROR_MESSAGE = "This field is required.";
const SPECIAL_CHAR_ERROR_MESSAGE = "Please do not enter the special character.";
const WHITE_SPACE_ERROR_MESSAGE = "Please enter a valid input.";
const DELETE_THEME =
  "Do you want to remove this image from existing presentations?";

const container = Main =>
  class Container extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isEditing: false,
        selectedData: {},
        currentIndex: "",
        error: false,
        accordionTypes: [
          {
            label: "Category",
            title: "Category",
            value: "categoryAttributes",
            isFixed: false,
            isOpen: true
          },
          {
            label: "MetaData",
            title: "Meta Data",
            value: "metaData",
            isFixed: true
          }
        ],
        editedData: {},
        focalPoint: { x: null, y: null },
        isShowImagePreview: true,
        clipboard: [],
        isFolderSelecting: false,
        selectedPath: "",
        isSaved: true,
        categoryData: [],
        selectedImageData: {},
        editData: {
          value: "",
          error: null
        },
        imageTitle: "",
        selectedFolder: "",
        selectedCategory: []
      };
    }

    static propTypes = {
      onClose: PropTypes.func
    };

    componentWillReceiveProps(nextProps) {}

    componentDidMount() {
      this._categoryData();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.imageCategory !== this.props.imageCategory) {
        this._categoryData();
      }

      if (prevProps.imageAttributes !== this.props.imageAttributes) {
        let { title } = this.props.imageAttributes;
        let { editData, imageTitle } = this.state;

        editData.value = title || "";
        editData.error = null;
        imageTitle = title || "";

        this._setBreadCrumb(this.props.imageAttributes);

        this.selectTheCategory(this.props.imageAttributes);

        this.setState({
          selectedImageData: this.props.imageAttributes,
          editData,
          imageTitle
        });
      }
    }

    _categoryData = () => {
      let { imageCategory } = this.props;
      let newData = JSON.parse(JSON.stringify(imageCategory));

      // add all
      Array.isArray(newData) &&
        newData.length &&
        each(newData, (cat, index) => {
          let { children, _id } = cat;

          Array.isArray(children) &&
            children.length &&
            children.splice(0, 0, {
              checked: false,
              parent: _id,
              title: "All",
              _id: `a${index}`
            });
        });

      this.setState({
        categoryData: newData
      });
    };

    _saveTitle = (inputValue, operation) => {
      let { editData, imageTitle } = this.state;
      editData.error = null;

      if (operation === "save") {
        editData.value = inputValue.value;
        imageTitle = inputValue.value;
        this.setState(previousState => ({
          editData,
          imageTitle
        }));
      } else {
        editData.value = imageTitle;
        this.setState({
          editData
        });
      }
    };

    _getDroppedElement = ({
      gData,
      itemKey,
      children,
      fetchMergeList,
      modifyLevel,
      isCategory,
      isChecked,
      parentId
    }) => {
      let droppedElement = {};
      let allLevelList = [];

      if (isCategory) {
        const resultData = (levelList, level = 0, parent = []) =>
          each(levelList, item => {
            item.level = level;
            if (item._id === itemKey || item._id === parentId) {
              item.checked = isChecked;
            }
            if (
              item.children &&
              item.children.length &&
              !(item._id === itemKey)
            ) {
              resultData(item.children, level + 1, item);
            }
          });

        resultData(gData);
      } else {
        const resultData = (levelList, level = 0, breadcrumbString = "") =>
          each(levelList, item => {
            item.level = level;
            if (item._id === itemKey) {
              item.path = `${breadcrumbString}${item.title}`;
              if (
                modifyLevel &&
                typeof modifyLevel === "object" &&
                modifyLevel.constructor === Object
              ) {
                item = { ...modifyLevel };
              } else if (children) {
                item.children = children;
              } else {
                item.level = level;
                droppedElement = item;
                return;
              }
            } else if (
              Array.isArray(item.children) &&
              item.children.length &&
              !(item._id === itemKey)
            ) {
              resultData(
                item.children,
                level + 1,
                `${breadcrumbString}${item.title}/`
              );
            }
          });

        resultData(gData);
      }

      if (modifyLevel) {
        return gData;
      }

      if (fetchMergeList) {
        return allLevelList;
      }
      return children ? gData : droppedElement;
    };

    _setBreadCrumb = itemObj => {
      let { folderParent } = itemObj;
      let item = this._getDroppedElement({
        itemKey: folderParent,
        gData: this.props.imageFolder
      });

      this.setState(previousState => ({
        isFolderSelecting: false,
        selectedData: {
          ...previousState.selectedData,
          selectedPath: item.path
        }
      }));
    };

    handleMoveFolder = value => e => {
      let { title } = value;
      if (title && title.length) {
        this._setBreadCrumb(value);
      }
      this.setState({
        isFolderSelecting: false,
        isSaved: false
      });
    };

    handleOperation = (operation, inputEle) => {
      if (operation === "edit") {
        this.setState(
          {
            isEditing: true
          },
          () => {
            // inputEle.focus();
            // setCaretPosition(inputEle, inputEle.value.length);
          }
        );
      } else if (operation === "save" || operation === "clear") {
        let { editData } = this.state;

        if (operation === "save" && editData.error) {
          return;
        }

        this._saveTitle(inputEle, operation);

        this.setState({
          isEditing: false
        });
      } else if (operation === "attach") {
        let { imageAttributes } = this.props;
        let { isSaved } = this.state;

        let id = imageAttributes._id;

        if (!isSaved) {
          DeleteConfirmationAlert({
            message:
              "All your unsaved changes will be lost. Do you still want to continue?",
            onYesClick: () => {
              this._resetStateValue();
              this.props.handleImageReupload(id);
            }
          });
        } else {
          this.props.handleImageReupload(id);
        }
      }
    };

    /**
     * handle the image title input changes
     */
    handleTitleChange = e => {
      let inputValue = e.target.value;

      let { editData } = this.state;
      editData.value = inputValue;
      editData.error = this.checkValidation(inputValue);

      this.setState({
        editData,
        isSaved: false
      });
    };

    handleImageEdit = imageRef => {
      this.openFocalPointImage(imageRef);
      this.setState({
        isImageEditing: true,
        isShowImagePreview: true
      });
    };

    onSaveImageData = (imageRef, event) => {
      event.stopPropagation();
      event.preventDefault();
      this.setState(
        prevProps => ({
          isImageEditing: !prevProps.isImageEditing,
          isSaved: false,
          isShowImagePreview: false
        }),
        () => {
          this.setState({
            isShowImagePreview: true
          });
        }
      );
    };

    /**
     * handle category checbox selection
     *
     * @param {*} item sinlge attribute
     * @param {*} list list of attribute
     */
    handleCategorySelection = (item, list) => {
      let { selectedCategory } = this.state;
      let { title, _id, checked } = item;
      // when all is selected
      if (title === "All" && list) {
        if (checked) {
          each(list, singleItem => {
            selectedCategory.indexOf(singleItem._id) < 0 &&
              singleItem.title !== "All" &&
              selectedCategory.push(singleItem._id);
          });
        } else {
          each(list, singleItem => {
            selectedCategory = filter(
              selectedCategory,
              catId => catId !== singleItem._id
            );
          });
        }
        // when single checkbox is added
      } else if (selectedCategory.indexOf(_id) < 0) {
        selectedCategory.push(_id);
      } else {
        // when single checkbox is removed
        selectedCategory = filter(selectedCategory, catId => catId !== _id);
      }

      this.setState({
        isSaved: false,
        selectedCategory
      });
    };

    /**
     *On image focal point edit click get image ref and show focal point
     *
     * @param {*} imageRef
     */
    openFocalPointImage = imageRef => {
      const { selectedImageData } = this.state;
      // Show selected image dimensions if present else show center as focus point
      const imageFocus = {
        x:
          get(selectedImageData, "focalPoint.x") ||
          this.state.focalPoint.x ||
          0,
        y:
          get(selectedImageData, "focalPoint.y") || this.state.focalPoint.y || 0
      };

      if (imageRef) {
        this.focusPicker = new FocusPicker(imageRef, {
          focus: imageFocus,
          onChange: focus => {
            this._setFocalPoint(focus);
          }
        });
      }
    };

    /**
     * Set the current focal point of the edited image
     */
    _setFocalPoint = ({ x = 0, y = 0 }) => {
      this.setState({
        focalPoint: { x, y }
      });
    };

    _checkIfArrayEqual = (arr1, arr2) => {
      return isEqual(sortBy(arr1), sortBy(arr2));
    };

    /**
     * Function to add the new meta data when enter or click action is performed
     * @param {Array} metaDataValues - contains the list of meta datathat are being added
     */
    addMetaData = metaDataValues => {
      let tempMetaData = JSON.parse(
        JSON.stringify(this.state.selectedImageData["metaData"])
      );
      let newMeta = tempMetaData
        ? [...tempMetaData, ...metaDataValues]
        : [...metaDataValues];
      let isValueChange = this._checkIfArrayEqual(this.state.metaData, newMeta);
      this.setState(previousState => ({
        selectedImageData: {
          ...previousState.selectedImageData,
          metaData: newMeta
        },
        isSaved: isValueChange
      }));
    };

    /**
     * Function to handle the copy action
     */
    handleCopy = () => {
      let { selectedImageData } = this.state;
      let commaSepratedValue = selectedImageData["metaData"].join(",");
      this.setState({
        clipboard: commaSepratedValue
      });
      this.copyToClipboard(commaSepratedValue);
    };

    /**
     * Function to copy the content added in textarea
     * @param {String} text -value of the text added
     */
    copyToClipboard = text => {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    };

    /**
     * Function to handle the paste action
     */
    handlePaste = () => {
      let {
        clipboard,
        selectedImageData: { metaData }
      } = this.state;
      let tempData = clipboard.split(",");
      let validatedArray = this._validateIfEmpty(tempData);
      let newData = [...metaData, ...validatedArray];
      this.setState(previousState => ({
        selectedImageData: {
          ...previousState.selectedImageData,
          metaData: newData
        }
      }));
    };

    handleMetaDelete = index => e => {
      e.stopPropagation();
      let tempData = this.state.selectedImageData["metaData"];
      if (tempData && Array.isArray(tempData) && tempData.length) {
        if (index === 0 || index) {
          tempData.splice(index, 1);
        } else {
          tempData.pop();
        }
        this.setState(previousState => ({
          selectedImageData: {
            ...previousState.selectedImageData,
            metaData: tempData
          },
          isSaved: false
        }));
      }
    };

    handleImageChange = direction => {
      let { isSaved, isImageEditing } = this.state;

      if (isImageEditing) {
        return;
      }

      //  Save image on next if unsaved changes are present
      if (!isSaved) {
        this.onSave(direction);
        return;
      }

      // On image change hide image until its loaded
      this.setState({
        isImageLoaded: false
      });

      this.props.handleImageSlider(direction);
    };

    handleFolderPopup = flag => e => {
      if (flag === "open") {
        this.setState({
          isFolderSelecting: true
        });
      } else {
        this.setState({
          isFolderSelecting: false
        });
      }
    };

    handleToast = ({ operation, message }) => {
      if (operation === "dismiss") {
        toast.dismiss(this.toastError);
      } else if (
        operation === "success" &&
        !toast.isActive(this.toastSuccess)
      ) {
        this.toastSuccess = toast.success(
          <ToastComponent message={message} isSussess={true} />,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
            draggable: false
          }
        );
      } else if (operation === "error" && !toast.isActive(this.toastError)) {
        this.toastError = toast.error(
          <ToastComponent message={message} isSussess={false} />,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: false,
            draggable: false
          }
        );
      } else {
        return;
      }
    };

    //Handle the preview modal save
    onSave = async direction => {
      let {
        selectedImageData,
        editData,
        isEditing,
        selectedCategory,
        selectedFolder,
        isImageEditing,
        focalPoint
      } = this.state;
     
      //check if content is being editted
      if (isEditing) {
        ToastUtils.handleToast({
          operation: "error",
          message: "Please save your image name before saving the content."
        });
        return;
      }

      //check if image is being editted
      if (isImageEditing) {
        ToastUtils.handleToast({
          operation: "error",
          message: "Please save your focal point before saving the content."
        });
        return;
      }

      //check if there is error present on input
      if (editData.error) {
        ToastUtils.handleToast({
          operation: "error",
          message: "Invalid entry. Please try again."
        });
        return;
      }

      let body = {
        title: editData.value,
        metaData: selectedImageData.metaData,
        categoryAttributes: selectedCategory,
        folderParent: selectedFolder,
        ...(!isNaN(focalPoint.x) &&
          !isNaN(focalPoint.y) && {
            focalPoint
          })
      };

      let response =
        this.props.updateImageDataAttributes &&
        (await this.props.updateImageDataAttributes(
          body,
          selectedImageData._id
        ));

      if (response.success && direction) {
        this._resetStateValue();
        this.props.handleImageSlider(direction);
      } else if (response.success) {
        //reset state value on success
        this.props._updateGridImagesList();
        this.setState({
          isSaved: true
        });
      }
    };

    /**
     * Handle slide selector tab selection
     */
    handleFolderSelection = selectedFolder => {
      this._setBreadCrumb({
        folderParent: selectedFolder
      });

      this.setState({
        selectedFolder,
        isSaved: false
      });
    };

    handleImageLoad = () => {
      this.setState({
        isImageLoaded: true
      });
    };

    /**
     * Select the category when pop is opened
     * @param {Array} data - It is  array of objects with the details of the image overlay opened
     */
    selectTheCategory = data => {
      let { categoryData, selectedCategory } = this.state;

      selectedCategory = data.categoryAttributes || [];
      const resultData = list =>
        each(list, item => {
          if (
            Array.isArray(data.categoryAttributes) &&
            data.categoryAttributes.indexOf(item._id) >= 0
          ) {
            item.checked = true;
          }

          if (item.children && item.children.length) {
            resultData(item.children);
          }
        });

      resultData(categoryData);

      this.setState({
        categoryData,
        selectedCategory
      });
    };

    /**
     * Function to check the validaiton of the image title input
     * @param {String} value - value of the imagetitle
     */
    checkValidation = value => {
      if (ValidationUtils.checkIfEmptyField(value)) {
        return EMPTY_FEILD_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfWhiteSpace(value)) {
        return WHITE_SPACE_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfspecialChar(value)) {
        return SPECIAL_CHAR_ERROR_MESSAGE;
      }
      return null;
    };

    // remove if array element is empty
    _validateIfEmpty = array => {
      if (array.length) {
        return array.filter(ele => ele.trim().length !== 0);
      }
    };

    /**
     * Function to reset the state data
     */
    _resetStateValue = () => {
      let { editData } = this.state;

      editData.title = "";
      editData.error = null;

      this.setState({
        isEditing: false,
        isImageEditing: false,
        isSaved: true,
        selectedPath: "",
        categoryData: [],
        selectedImageData: {},
        selectedCategory: [],
        editData,
        imageTitle: "",
        isImageLoaded: false,
        focalPoint: {
          x: null,
          y: null
        }
      });
    };

    /**
     * Handle the preview modal status
     * @param {Boolean} checkIfSaved - status of the whether the data changes are saved or not.
     */
    handlePreviewModal = checkIfSaved => {
      let { isNewImageUploaded = false } = this.props;
      if (!checkIfSaved) {
        DeleteConfirmationAlert({
          message:
            "All your unsaved changes will be lost. Do you still want to continue",
          onYesClick: () => {
            this._resetStateValue();
            this.props.updateMainStateData(isNewImageUploaded);
          }
        });
      } else {
        this.props.updateMainStateData(isNewImageUploaded);
      }
    };

    handleImageDelete = () => {
      let { imageAttributes } = this.props;
      DeleteConfirmationAlert({
        message: DELETE_THEME,
        note: `Clicking on "No" will delete the image from the list but will keep them in the existing presentations.
        `,
        onYesClick: () => {
          this._deleteImage(imageAttributes._id, true);
        },
        onNoClick: () => {
          this._deleteImage(imageAttributes._id, false);
        },
        onCancel: closePopupOnCrossMark => {
          closePopupOnCrossMark(false);
        }
      });
    };

    /**
     * Function to delete the images
     * @param {String} id - ID of an image to be deleted.
     * @param {Boolean} deleteFromPresentation - Whether or not to delete image from the existing presentations
     */
    _deleteImage = async (id, deleteFromPresentation) => {
      await this.props.deleteImageFromList(id, deleteFromPresentation);
      this._resetStateValue();
      this.props.updateMainStateData(true);
    };

    render() {
      const Props = {
        handleOperation: this.handleOperation,
        handleImageChange: this.handleImageChange,
        handleTitleChange: this.handleTitleChange,
        handleImageEdit: this.handleImageEdit,
        addMetaData: this.addMetaData,
        handlePaste: this.handlePaste,
        handleCopy: this.handleCopy,
        handleMetaDelete: this.handleMetaDelete,
        handleFolderPopup: this.handleFolderPopup,
        handleMoveFolder: this.handleMoveFolder,
        onSaveImageData: this.onSaveImageData,
        handleCategorySelection: this.handleCategorySelection,
        onSave: this.onSave,
        handleClose: this.handleClose,
        handleImageLoad: this.handleImageLoad,
        handleFolderSelection: this.handleFolderSelection,
        handlePreviewModal: this.handlePreviewModal,
        handleImageDelete: this.handleImageDelete
      };
      return <Main {...this.props} {...this.state} {...Props} />;
    }
  };

export default container;

import React from "react";
import styled, { css } from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { default as ReactSelect } from "react-select";

const SetupType = props => {
  let {
    handleMainButtonClick,
    selectedOption,
    handleChange,
    documentTypeOption
  } = props;

  return (
    <DetailsContainer>
      <LabelGroup>
        <Label>{"Choose by Document Type"}</Label>
        <SelectContainer>
          <ReactSelect
            placeholder={"Select"}
            value={selectedOption}
            onChange={handleChange}
            options={documentTypeOption}
            className="custom-select"
            classNamePrefix="custom-select"
          />
        </SelectContainer>
      </LabelGroup>

      <SeparatorContainer>OR</SeparatorContainer>

      <ButtonWrapper>
        <Label>{"Choose by Content Type"}</Label>
        <GetStartedButton onClick={() => handleMainButtonClick(false, 0)}>
          Get Started
        </GetStartedButton>
      </ButtonWrapper>
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div`
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  padding: 11px 12% 23px;
  margin-top: 7px;
  background-color: ${props => props.theme.COLOR.WHITE};
  .dropdown-container {
    margin: 20px 0;
    label {
      padding-top: 8px;
    }
  }
  button {
    width: 100%;
  }
`;

const SeparatorContainer = styled.span`
  margin: 10px 0;
  display: block;
  text-align: center;
  opacity: 0.5;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
`;

const SelectContainer = styled.div`
  padding-bottom: 0px;

  div {
    cursor: pointer;
  }

  label {
    top: -2px;
  }

  /*Override custom select list*/
  .custom-select__control {
    /*When select list is focuses*/
    &--is-focused {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }

    /*When select list is in active state*/
    &--menu-is-open {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }

  /*Overriding option styling*/
  .custom-select__menu {
    > div > div {
      background-color: ${props => props.theme.COLOR.WHITE};

      &:hover {
        background-color: ${props =>
          hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.2)};
      }
    }

    .custom-select__option--is-selected {
      background-color: ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        background-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }
`;

const LabelCSS = css`
  margin-bottom: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.64)};
`;

const Label = styled.span`
  ${LabelCSS}
`;

const ButtonWrapper = styled.div``;

const GetStartedButton = styled.button`
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_CSS}
  height: 46px;
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  color: ${props => props.theme.COLOR.WHITE};
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background-color: ${props => props.theme.COLOR.WHITE};
    border: 1px solid;
  }
  cursor: ${props => (!props.disabled ? "pointer" : "not-allowed")};
`;

const LabelGroup = styled.div`
  padding-top: ${props => (props.description ? "17px" : 0)};
`;

export default SetupType;

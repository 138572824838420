import React, { Component } from "react";
import ValidationUtils from "utils/ValidationUtils";
import { companyDataValue, primaryDataValue } from "./accountData";
import ToastUtils from "utils/handleToast";

const UI_STRINGS = {
  EMPTY_FIELD_ERROR_MESSAGE: "This field is required.",
  SPECIAL_CHAR_ERROR_MESSAGE: "Please do not enter the special character.",
  WHITE_SPACE_ERROR_MESSAGE: "Please enter a valid input.",
  EMAIL_ERROR_MESSAGE: "Please enter a valid email."
};

const container = Main =>
  class Container extends Component {
    state = {
      companyData: companyDataValue,
      primaryContact: primaryDataValue,
      isDataChange: false,
      isLeadSystemAdmin: false
    };

    componentDidMount() {
      this.props.systemConfigurationData && this.updateAccountData();
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.systemConfigurationData !== this.props.systemConfigurationData
      ) {
        this.updateAccountData();
      }
    }

    /**
     * Update Account data fetched from an API
     */
    updateAccountData = () => {
      let { companyData, primaryContact: primaryData } = { ...this.state };
      let {
        address,
        companyName,
        email,
        family_name,
        given_name,
        phone,
        supportEmail,
        isLeadSystemAdmin
      } = this.props.systemConfigurationData || {};

      //assigning the value if updated in API
      companyData["companyName"].value = companyName || "";
      primaryData["email"].value = email || "";
      primaryData["supportEmail"].value = supportEmail || "";
      primaryData["givenName"].value = given_name || "";
      primaryData["familyName"].value = family_name || "";
      primaryData["phone"].value = phone || "";

      if (!isLeadSystemAdmin) {
        primaryData["givenName"].isReadOnly = true;
        primaryData["familyName"].isReadOnly = true;
        primaryData["email"].isReadOnly = true;
        primaryData["phone"].isReadOnly = true;
      }

      //Assigning the address fields value
      if (address) {
        Object.keys(address || {}).forEach(input => {
          companyData[input].value = address[input];
        });
      }

      this.setState({
        companyData: companyData,
        primaryContact: primaryData,
        isDataChange: false,
        isLeadSystemAdmin
      });
    };

    /** Function to check if there are any errors in the data
     *  @param {Object} This will have the Company Data
     *  @param {Object} This will have the Primary Contact Data
     */
    checkError = (companyData, primaryData) => {
      let error = false;

      Object.keys(companyData).forEach(input => {
        let data = companyData[input];
        this.handleInputChange(data.value, data.id, "companyData");
        if (data.error.length) {
          error = true;
        }
      });

      Object.keys(primaryData).forEach(input => {
        //Checked against empty string because length of a number value cannot be check without converting it to string

        let data = primaryData[input];
        this.handleInputChange(data.value, data.id, "primaryContact");
        if (data.error.length) {
          error = true;
        }
      });

      return error;
    };

    /**
     *  Function to update the value for the data inputs
     * @param {Object} This will have an object of value and id of the Input
     */
    handleInputChange = ({ value, id, dataValue }) => {
      let accountData = { ...this.state };
      let updatedAccountData = this._validateInput(
        accountData[dataValue],
        value,
        id
      );

      accountData["isDataChange"] = true;
      accountData[dataValue] = updatedAccountData;
      this.setState(accountData);
    };

    /**
     * Common fucntion for handling the actions on input
     * @param {Object} This will have the Object data of where the Input field belong to
     * @param {String} Value of an input field
     * @param {String} Id of an Input
     */
    _validateInput = (accountData, value, id) => {
      let data = {};
      Object.keys(accountData || {}).map(input => {
        let item = accountData[input];
        if (item.id === id) {
          item.value = value;
          item.error = this.handleAccountValidation(value, item.type) || "";
        }
        return (data[input] = item);
      });
      return data;
    };

    /**
     * Function to validate the input data
     * @param {String} will have the value of an input field
     * @param {String} The type of the input field
     */
    handleAccountValidation = (value = "", type) => {
      if (type === "number") {
        //Done for Morzilla browser, Because input type nmber allows the text to beadded whereas chromes doesnot.
        if (value) {
          return ValidationUtils.validateNumber(value)
            ? ""
            : UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
        } else {
          return UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
        }
      } else if (ValidationUtils.checkIfEmptyField(value)) {
        return UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfWhiteSpace(value)) {
        return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
      } else if (type === "email") {
        return ValidationUtils.validateEmail(value)
          ? ""
          : UI_STRINGS.EMAIL_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfspecialChar(value)) {
        return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
      }
    };

    saveButtonHandler = () => {
      let { companyData, primaryContact: primaryData, isDataChange } = {
          ...this.state
        },
        { isLeadSystemAdmin } = this.props.systemConfigurationData || {};

      if (!isDataChange) {
        return;
      }

      let error = this.checkError(companyData, primaryData);

      if (error) {
        ToastUtils.handleToast({
          operation: "error",
          message: "Please fill all the fields"
        });
        return;
      }

      let address = {};

      const {
        addressOne: addressOneData = {},
        addressTwo: addressTwoData = {},
        city: cityData = {},
        state: stateData = {},
        country: CountryData = {},
        postalCode: postalCodeData = {},
        companyName: companyNameData = {}
      } = companyData;

      const {
        email: emailData = {},
        phone: phoneData = {},
        givenName: firstName = {},
        familyName: lastName = {},
        supportEmail: supportEmailData = {}
      } = primaryData;

      address = {
        addressOne: addressOneData.value.trim(),
        addressTwo: addressTwoData.value.trim(),
        city: cityData.value.trim(),
        state: stateData.value.trim(),
        country: CountryData.value.trim(),
        postalCode: postalCodeData.value
      };

      //creating the body of the API
      let apiBody = {
        address: address,
        companyName: companyNameData.value.trim(),
        ...(isLeadSystemAdmin && {
          email: emailData.value.trim(),
          phone: phoneData.value.trim(),
          firstName: firstName.value.trim(),
          lastName: lastName.value.trim()
        }),

        supportEmail: supportEmailData.value.trim()
      };
      
      //save data
      this.props.saveSystemConfigurationData(apiBody);
    };

    _cancelButtonHandler = () => {
      let { companyData, primaryContact } = { ...this.state };

      Object.keys(companyData).forEach(input => {
        companyData[input].error = "";
      });

      Object.keys(primaryContact).forEach(input => {
        primaryContact[input].error = "";
      });

      this.setState({
        companyData,
        primaryContact
      });

      this.updateAccountData();
    };

    /**
     * Function to handle key down of the number field
     */
    handleKeyPress = e => {
      let charCode = e.which || e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      }
    };

    render() {
      const { state } = this;

      const MainProps = {
        ...state,
        isLoading: false,
        handleInputChange: this.handleInputChange,
        saveButtonHandler: this.saveButtonHandler,
        updateAccountData: this.updateAccountData,
        cancelButtonHandler: this._cancelButtonHandler,
        handleKeyPress: this.handleKeyPress
      };

      return <Main {...MainProps} />;
    }
  };

export default container;

import React from "react";
import CheckBox from "components/checkbox";
import styled from "styled-components";
import { map, includes, get } from "lodash";
import ShadowScrollbars from "components/custom-scrollbars/ShadowScrollbars";

const Repos = props => {
  let { contentRepositoriesValue } = props.form;
  let isAllSelected =
    get(contentRepositoriesValue, "value.length") ===
    get(props, "contentRepoDropdown.length");
  let space = get(props, "form.firstName.value") ? " " : "";

  let fullName = `${get(props, "form.firstName.value", "")}${space}${get(
    props,
    "form.lastName.value",
    ""
  )}`;

  return (
    <RepoWrapper>
      <CheckboxWrapper>
        <CheckBox
          label="Add this new user to all repo (select all)"
          id="access"
          handleChange={() => {
            let flags = isAllSelected ? "none" : "all";
            props.checkboxOperation && props.checkboxOperation(flags);
          }}
          checked={isAllSelected}
        />
      </CheckboxWrapper>
      <LineWrapper>
        <LineBreak />
      </LineWrapper>
      <CheckBoxHeading>
        Select repositories below to grant access.
      </CheckBoxHeading>
      {contentRepositoriesValue.error && (
        <ErrorMessage>{contentRepositoriesValue.error}</ErrorMessage>
      )}
      {fullName && fullName.length && (
        <UserDetails>
          <UserLabel>User</UserLabel>
          <UserName>{fullName}</UserName>
        </UserDetails>
      )}
      <CheckboxContainer>
        <ShadowScrollbars
          scrollcontenttotop={"yes"}
          style={{ height: "400px" }}
        >
          {map(props.contentRepoDropdown, ({ _id, name }) => (
            <CheckboxWrapper key={_id}>
              <CheckBox
                label={name}
                id={_id}
                chooseContent={true}
                handleChange={e =>
                  props.manageInputStates(e, {
                    propName: "contentRepositoriesValue",
                    value: e.target.checked,
                    type: "contentRepoCheckbox",
                    label: _id,
                    keyName: "contentRepositoriesValue"
                  })
                }
                checked={includes(contentRepositoriesValue.value, _id)}
              />
            </CheckboxWrapper>
          ))}
        </ShadowScrollbars>
      </CheckboxContainer>
      <ButtonWrapper>
        <AddButton
          onClick={props.isEdit ? props.saveEditedUser : props.addNewUser}
        >
          Save
        </AddButton>
        <ClearButton onClick={props.resetFormDetails}>Cancel</ClearButton>
      </ButtonWrapper>
    </RepoWrapper>
  );
};

const CheckboxContainer = styled.div`
  padding: 10px 0;

  input[type="checkbox"] {
    display: inline-block;
  }

  label {
    width: 89%;
    vertical-align: middle;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const RepoWrapper = styled.div`
  .save-btn {
    margin-left: 8px;
  }
`;

const CheckBoxHeading = styled.p`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR.MAIN};
  font-size: 12px;
  margin-top: 27px;
`;

const LineBreak = styled.span`
  width: 91px;
  height: 2px;
  opacity: 0.46;
  border: solid 1px #979797;
  display: inline-block;
`;

const CheckboxWrapper = styled.div`
  margin-top: -7px;
  padding: 10px;
`;

const LineWrapper = styled.div`
  text-align: center;
`;

const UserDetails = styled.div`
  margin: 10px 0 13px;
`;

const AddButton = styled.button`
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_CSS}
  color: ${props => props.theme.COLOR.WHITE};
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background-color: ${props => props.theme.COLOR.WHITE};
    border: 1px solid;
  }
`;

const ClearButton = styled.button`
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_CSS}
  margin-left: 6px;
  background: transparent;
  border: solid 1px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  &:hover {
    color: ${props => props.theme.COLOR.WHITE};
    background-color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  }
`;

const UserLabel = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR.MAIN};
  font-size: 12px;
  font-weight: bold;
  opacity: 0.7;
`;

const UserName = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR.HEADING};
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: inline-block;
  margin-top: 14px;
  button {
    height: 40px;
  }
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
`;

export default Repos;

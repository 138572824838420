import React, { Component } from "react";
import styled from "styled-components";
import { get, round, each } from "lodash";
import { Clone, Show as Preview, Hidden, EditWithNoShadow } from "assets/icons";
//reducer action and props
import { connect } from "react-redux";
import { mapStateToProps, actions } from "./mapStateToProps";
import ValidationUtils from "utils/ValidationUtils";
import ToastUtils from "utils/handleToast";
import { Link } from "react-router-dom";

const landingPageColumn = [
  {
    col1: "Name",
    col2: "Dimension",
    col3: "No. Slides",
    col4: "Max",
    col5: "Actions"
  }
];

const EMPTY_FIELD_ERROR_MESSAGE = "This field is required.";
const SPECIAL_CHAR_ERROR_MESSAGE = "Please do not enter the special character.";
const WHITE_SPACE_ERROR_MESSAGE = "Please enter a valid input.";
const NUMBER_FIELD_ERROR_MESSAGE = "Please enter a valid number";

const landingPageColumnWidth = [300, 141, 142, 140, 105];
const landingPageSearchFields = ["name"];

const landingPageColumnHeader = [
  "name",
  "dimensions.ratio",
  "numberOfSlides",
  "maximumSlides",
  ""
];

const Container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class ContentRepo extends Component {
      constructor(props) {
        super(props);

        this.state = {
          landingPageColumn,
          landingPageColumnWidth,
          landingPageSearchFields,
          landingPageColumnHeader,
          isRenderTable: true,
          hideTableOnResize: false,
          selectedRepoDetail: [],
          selectedRepoId: null,
          isHideRepoDetail: true,
          selectedDimension: "16:9",
          noOfSlides: 0,
          selectedRowDetail: {},
          isEditClicked: false
        };
      }

      componentDidMount() {
        this.changeTableWidthOnResize();
        window.addEventListener("resize", this.changeTableWidthOnResize);
        this.props.getContentRepoList();
      }

      componentWillUnmount() {
        window.removeEventListener("resize", this.changeTableWidthOnResize);
      }

      renderHead = () => {
        const HELPER_TEXT =
          'Welcome to the Content Repo Dashboard. You can enable/disable, clone or edit the content repositories below or add a new content repository by clicking “Add Repo”. Click the hyperlinked "Name" to view, add and make changes to slide content.';
        return (
          <div className="heading">
            <HeadingName>Content Repo</HeadingName>
            <HelperText>{HELPER_TEXT}</HelperText>
          </div>
        );
      };

      // change repo lists table on resize window
      changeTableWidthOnResize = () => {
        // timeout to manage table render with new column width on load and resize
        clearTimeout(this.resizeTimer);
        this.setState({
          hideTableOnResize: true
        });

        this.resizeTimer = setTimeout(() => {
          let { landingPageColumnWidth } = this.state;

          const viewportWidth = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
          );

          if (viewportWidth > 1164) {
            landingPageColumnWidth = [282, 130, 130, 130, 150];
          } else if (viewportWidth >= 1039 && viewportWidth <= 1164) {
            landingPageColumnWidth = [215, 130, 130, 130, 129];
          } else if (viewportWidth > 1024) {
            landingPageColumnWidth = [245, 110, 84, 71, 128];
          } else {
            landingPageColumnWidth = [236, 110, 84, 71, 118];
          }
          this.setState({
            landingPageColumnWidth,
            hideTableOnResize: false
          });
        }, 250);
      };

      /**
       * called for rendering table cells
       *
       */
      renderTableCell = ({ row, col, highlightText, colName, index }) => {
        if (col === "Name") {
          let description = `${get(row.original, `description`, "") ||
            get(row.row, `description`, "")}`;
          return (
            <span>
              <StyledLink
                to={`/presentation/admin/content-repo/${get(
                  row,
                  `original._id`
                ) || get(row.row, `_id`)}/content-slides`}
              >
                <RepoNameText title={row.value}>
                  {highlightText(row.value)}
                </RepoNameText>
              </StyledLink>
              {description && (
                <RepoDescription title={description}>
                  {`Description: ${description}`}
                </RepoDescription>
              )}
            </span>
          );
        } else if (col === "Dimension") {
          let repoWidthHeight = `${round(
            get(row.original, `dimensions.width`) ||
              get(row.row, `dimensions.width`),
            2
          )} x ${round(
            get(row.original, `dimensions.height`) ||
              get(row.row, `dimensions.height`),
            2
          )}`;

          return (
            <span>
              <RepoNameText title={row.value} col>
                {get(row.row, `dimensions.ratio`)}
              </RepoNameText>
              <RepoDescription title={repoWidthHeight}>
                {repoWidthHeight}
              </RepoDescription>
            </span>
          );
        } else if (index + 1 === Object.values(colName).length) {
          let _row = row.original || row.row;
          return this.showIcon(_row);
        } else if (typeof row.value !== "object") {
          return <span title={row.value}> {row.value} </span>;
        }
      };

      //call function after Click Add repo cta
      onClickAddRepo = () => {
        this.handleSelectRepo({ e: null, rowData: null });
      };

      checkIfFieldIsEdited = (rowData, field) => {
        let { selectedRepoDetail } = this.state;

        for (let index = 0; index < selectedRepoDetail.length; index++) {
          let item = selectedRepoDetail[index];

          if (item.key === field && item.input === rowData[field]) {
            return false;
          }
        }
        return true;
      };

      // called on edit icon click and for clearing repo fields on submit click
      handleSelectRepo = ({ e, rowData }) => {
        this.setState({
          isEditClicked: false
        });
        const dropDownOption = ["16:9", "4:3", "Custom"];

        let ratioValue = get(rowData, "dimensions.ratio")
          ? dropDownOption.indexOf(get(rowData, "dimensions.ratio")) === -1
            ? "Custom"
            : get(rowData, "dimensions.ratio")
          : "16:9";

        const selectedRepoDetail = [
          {
            key: "name",
            valueType: "input",
            label: "Name",
            input: get(rowData, `name`) || "",
            error: null
          },
          {
            key: "description",
            valueType: "textarea",
            label: "Description",
            input: get(rowData, `description`) || "",
            note: "250 characters",
            error: null,
            isFieldEdited: !!!rowData
          },
          {
            key: "ratio",
            valueType: "dropDown",
            label: "Dimensions",
            input: ratioValue,
            error: null,
            isFieldEdited: !!!rowData
          },
          {
            key: "width",
            valueType: "input",
            label: "Slide Width (inches)",
            input: get(rowData, "dimensions.width") || "",
            isItemDimension: true,
            error: null
          },
          {
            key: "height",
            valueType: "input",
            label: "Slide Height (inches)",
            input: get(rowData, "dimensions.height") || "",
            isItemDimension: true,
            error: null
          },
          {
            key: "maximumSlides",
            valueType: "input",
            label: "Max Slides",
            input: get(rowData, "maximumSlides"),
            note:
              "Maximum number of slides allowed in an exported presentation",
            error: null
          }
        ];

        this.setState(
          {
            isHideRepoDetail: true,
            selectedDimension: get(rowData, `dimensions.ratio`) || "16:9",
            noOfSlides: get(rowData, `numberOfSlides`) || 0,
            selectedRowDetail: rowData // original row detail
          },
          () => {
            this.setState({
              selectedRepoId: get(rowData, `_id`),
              selectedRepoDetail,
              isHideRepoDetail: false,
              isEditClicked: true
            });
          }
        );
      };

      // show action icons
      showIcon = rowData => {
        const { selectedRepoId } = this.state;
        return (
          <>
            <EnableIconWrapper
              onClick={() => this.enableHandler(rowData._id, rowData.enable)}
              title={rowData.enable ? "Enabled" : "Disabled"}
            >
              {rowData.enable ? <PreviewIcon /> : <HiddenIcon />}
            </EnableIconWrapper>
            <IconWrapper
              enable={rowData.enable}
              title={rowData.enable ? "Clone" : ""}
            >
              <CloneIcon
                onClick={() => this.contentRepoCloneHandler(rowData._id)}
              />
            </IconWrapper>
            <IconWrapper
              title={rowData.enable ? "Edit" : ""}
              enable={rowData.enable}
            >
              <EditSquareIcon
                className={`select-repo ${
                  selectedRepoId === rowData._id ? "active-select-repo" : ""
                }`}
                onClick={e =>
                  this.handleSelectRepo({
                    e,
                    rowData
                  })
                }
              />
            </IconWrapper>
          </>
        );
      };

      /**
       *  called on cancel click
       */
      clearSelectedRepo = () => {
        const { selectedRepoDetail } = this.state;
        each(selectedRepoDetail, eachField => {
          eachField.input = "";
          eachField.error = null;

          if (eachField.key === "ratio") {
            eachField.input = "16:9";
          }
        });
        this.setState({
          selectedRepoDetail: [],
          selectedRepoId: null,
          selectedDimension: "16:9",
          noOfSlides: 0
        });
      };

      /**
       * Change Dimensions on dropdown option change
       * @param {String} selectedOption option that was selected
       *
       */
      changeDimension = selectedDimension => {
        const { selectedRepoDetail } = this.state;

        each(selectedRepoDetail, field => {
          if (field.key === "width" || field.key === "height") {
            field.input = "";
            field.error = "";
          }
        });

        this.setState({
          selectedDimension,
          selectedRepoDetail
        });
      };

      handleFormValidation = ({ value, key }) => {
        let numberFields = ["width", "height", "maximumSlides"];
        let noValidationFields = ["description"];

        if (noValidationFields.indexOf(key) !== -1) {
          return null;
        }

        if (ValidationUtils.checkIfEmptyField(value)) {
          return EMPTY_FIELD_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfspecialChar(value)) {
          return SPECIAL_CHAR_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(value)) {
          return WHITE_SPACE_ERROR_MESSAGE;
        } else if (
          (!ValidationUtils.validateFloatNumber(value) &&
            numberFields.indexOf(key) !== -1) ||
          value === "0" ||
          (key === "maximumSlides" && !ValidationUtils.validateNumber(value))
        ) {
          return NUMBER_FIELD_ERROR_MESSAGE;
        }
      };

      /**
       * @param {*} contentRepoId Content repository id to be cloned
       */
      contentRepoCloneHandler = async contentRepoId => {
        let response = await this.props.cloneContentRepo(contentRepoId);

        this.props.getContentRepoList();

        if (response.success && response.data) {
          let rowData = response.data;

          this.handleSelectRepo({
            e: null,
            rowData
          });
        }
      };

      // Change value of slected repo
      onChangeInput = ({ key, e, valueType }) => {
        const {
          selectedRepoDetail,
          selectedRepoId,
          selectedRowDetail
        } = this.state;
        const dimensionsField = ["width", "height", "ratio"];

        each(selectedRepoDetail, item => {
          if (item.key === key && item.key !== "ratio") {
            item.error = this.handleFormValidation({
              value: e.target.value,
              key
            });
            item[valueType] = e.target.value;
            item.isFieldEdited =
              selectedRowDetail && item.key === "name"
                ? this.checkIfFieldIsEdited(selectedRowDetail, "name")
                : true;
          } else if (item.key === key) {
            item.input = e.target.value;
            item.isFieldEdited = true;
            this.changeDimension(e.target.value);
          }

          // if width or height is edited in while repo edit, send ratio, width and height is post data
          if (
            selectedRepoId &&
            (item.key === "width" || item.key === "height") &&
            item.isFieldEdited
          ) {
            each(selectedRepoDetail, item => {
              if (dimensionsField.some(field => item.key === field)) {
                item.isFieldEdited = true;
              }
            });
          }
        });

        this.setState({
          selectedRepoDetail
        });
      };

      /**
       * check if the form's mandatory fields have been filled
       * @returns Boolean stating whether the form is valid or not
       */
      checkIfFormIsValid = () => {
        const { selectedRepoDetail, selectedDimension } = this.state;
        let dimensions = ["width", "height", "ratio"];
        let fieldError = [];

        each(selectedRepoDetail, field => {
          field.error = this.handleFormValidation({
            value: field.input,
            key: field.key
          });

          // do not show error message if 4:3, 16:9 is selected for width and height fields
          if (
            (selectedDimension === "16:9" || selectedDimension === "4:3") &&
            dimensions.indexOf(field.key) !== -1
          ) {
            field.error = "";
          }
          field.error && fieldError.push(field.error);
        });

        if (fieldError.length > 0) {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please fill all the required fields."
          });

          this.setState({
            selectedRepoDetail
          });

          return true;
        }
        return false;
      };

      /**
       * check if the form is edited and save data if edited
       * @returns Boolean stating whether data has been edited or not
       */
      checkIfFormIsEdited = () => {
        const { selectedRepoDetail } = this.state;
        const selectedRepoDetailLength = selectedRepoDetail.length;

        for (let index = 0; index < selectedRepoDetailLength; index++) {
          if (selectedRepoDetail[index].isFieldEdited) {
            return true;
          }
        }

        return false;
      };

      onSubmitSelectedRepo = async () => {
        const {
          selectedRepoDetail,
          landingPageData,
          selectedRepoId
        } = this.state;

        if (this.checkIfFormIsValid() || !this.checkIfFormIsEdited()) {
          return;
        }

        let postData = {};
        let dimensionsArray = ["ratio", "width", "height"];

        each(selectedRepoDetail, field => {
          if (field.isFieldEdited) {
            if (dimensionsArray.indexOf(field.key) !== -1) {
              postData["dimensions"] = postData["dimensions"]
                ? postData["dimensions"]
                : {};
              postData["dimensions"][get(field, `key`)] = get(field, `input`);
            } else {
              postData[get(field, `key`)] = get(field, `input`).trim();
            }
          }
        });

        await this.props.createAndEditContentRepo(postData, selectedRepoId);
        await this.props.getContentRepoList();
        this.handleSelectRepo({ e: null, rowData: null });

        this.setState(
          {
            landingPageData,
            isRenderTable: false,
            selectedRepoId: null
          },
          () => {
            this.setState({
              isRenderTable: true
            });
          }
        );
      };

      /**
       * Function to handle the enable and disable state of the content repo
       * @param {String} Id of the content repo
       * @param {Boolean} Value of the content repo toggle state
       */
      enableHandler = async (id, show) => {
        let payload = {
          enable: !show
        };

        let message = `Content Repo has been successfully ${
          !show ? "enabled." : "disabled."
        }`;
        await this.props.createAndEditContentRepo(payload, id, message);
        this.props.getContentRepoList();
      };

      handleDimensionKeyDown = ({ key, e, valueType }) => {
        // dont let the user enter more than 2 decimal points
        let decimalPlaces = e.target.value.split(".");

        if (get(decimalPlaces[1], `length`) > 2) return;

        this.onChangeInput({ key, e, valueType });
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...this.props,
          ...JSON.parse(JSON.stringify($this.state))
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

const IconWrapper = styled.span`
  cursor: ${props => (props.enable ? "pointer" : "none")};
  pointer-events: ${props => (props.enable ? "auto" : "none")};
  &:hover {
    opacity: 0.7;
  }
`;

const EnableIconWrapper = styled.span`
  &:hover {
    opacity: 0.7;
  }
`;

const CloneIcon = styled(Clone)``;

const EditSquareIcon = styled(EditWithNoShadow)`
  margin-left: 20px;
  width: 16px;
  height: 17px;
`;

const RepoNameText = styled.span`
  width: 100%;
  padding-right: 5px;
  display: block;
  font-weight: bold;
  text-overflow: ellipsis;
  opacity: 0.7;
  overflow: hidden;
  white-space: nowrap;
`;

const RepoDescription = styled.span`
  width: 100%;
  display: block;
  padding-right: 5px;
  color: ${props => props.theme.COLOR.HEADING};
  text-overflow: ellipsis;
  font-size: 10px;
  opacity: 0.54;
  overflow: hidden;
  white-space: nowrap;
`;

const HelperText = styled.p`
  ${props => props.theme.SNIPPETS.HELPER_TEXT};
  margin-bottom: 16px;
`;

const HeadingName = styled.span`
  margin-left: -2px;
  display: inline-block;
  margin-bottom: 10px;
`;

const PreviewIcon = styled(Preview)`
  cursor: pointer;
  margin-right: 15px;
  g {
    opacity: 1;
  }
`;

const HiddenIcon = styled(Hidden)`
  cursor: pointer;
  margin-right: 15px;
  g {
    opacity: 1;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  &:hover {
    text-decoration: underline;
  }
`;
export default Container;

import React from "react";
import styled from "styled-components";
import { map, get } from "lodash";
import Moment from "react-moment";

const AnnouncementContent = props => {
  let {
    announcementList,
    showAllAnnouncement,
    updateAnnouncementHandler,
    limitCharacter
  } = props;

  return (
    <AnnouncementContentWrapper>
      {map(
        get(announcementList, `data.announcements`),
        (eachAnnouncement, index) => {
          return (
            <AnnouncementWrapper
              key={index}
              onClick={e => {
                get(eachAnnouncement, `announcementState`) === "Unread" &&
                  updateAnnouncementHandler(get(eachAnnouncement, "_id"));
                showAllAnnouncement(
                  e,
                  "announcementPopup",
                  get(eachAnnouncement, "_id")
                );
              }}
              announcementStatus={
                get(eachAnnouncement, `announcementState`) === "Unread"
              }
            >
              <AnnouncementTitle title={get(eachAnnouncement, `title`)}>
                {get(eachAnnouncement, `title`)}
              </AnnouncementTitle>
              <AnnouncementDate>
                <Moment format="MM/DD/YYYY">
                  {get(eachAnnouncement, `date`)}
                </Moment>
              </AnnouncementDate>
              <AccouncementBody
                ref={ref => ref && limitCharacter(ref)}
                dangerouslySetInnerHTML={{
                  __html: get(eachAnnouncement, `description`)
                }}
              />
            </AnnouncementWrapper>
          );
        }
      )}
    </AnnouncementContentWrapper>
  );
};

const AnnouncementContentWrapper = styled.div``;

const AnnouncementWrapper = styled.div`
  padding: 12px 30px 15px;
  border-bottom: 1px solid ${props => props.theme.COLOR_PALLETE.SEPARATOR};

  background-color: ${props =>
    props.announcementStatus && props.theme.COLOR.LIGHT_BLUE};
`;

const AnnouncementDate = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR.HEADING};
  opacity: 0.45;
  float: right;
  cursor: pointer;
  margin-top: 5px;
  font-size: 8px;
`;

const AnnouncementTitle = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR.HEADING};
  opacity: 0.45;
  display: inline-block;
  font-weight: bold;
  margin-bottom: 7px;
  width: 80%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AccouncementBody = styled.div`
  ${props => props.theme.SNIPPETS.HELPER_TEXT};
  width: 100%;
  font-size: 11px;
  opacity: 0.54;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export default AnnouncementContent;

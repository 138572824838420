import React, { Fragment, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
import Button from "./components/button";
import { SlideUpload } from "assets";
import ImageCropper from "components/imageCropper";
import { assign } from "lodash";

import ToastUtils from "utils/handleToast";

const getColor = props => {
  if (props.isDragAccept) {
    return props.theme.COLOR_PALLETE.APPLE_GREEN;
  }
  if (props.isDragReject) {
    return props.theme.COLOR_PALLETE.ERROR;
  }
  if (props.isDragActive) {
    return hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.7);
  }
  return props.theme.COLOR.USER_PRIMARY;
};

const Container = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 15px 20px;
  border-radius: 4px;
  border: 2px solid ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const StyledDropzone = props => {
  const [files, setFiles] = useState([]);
  const [isCropping, setIsCropping] = useState(false);
  const [editedImage, setEditedImage] = useState("");
  const renderImage = editedImage || (files.length && files[0].preview);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: acceptedFiles => {
      if (!acceptedFiles.length) {
        return ToastUtils.handleToast({
          operation: "error",
          message: "Please enter a valid file (.jpg or .png)"
        });
      }
      let { onFileUpload } = props;
      setFiles(
        acceptedFiles.map(file => {
          return assign(file, {
            preview: URL.createObjectURL(file)
          });
        })
      );

      //to empty the previous editted Image state
      setEditedImage("");
      if (onFileUpload) {
        onFileUpload(acceptedFiles);
      }
    }
  });

  // handle edit button click
  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    let { handleEdit } = props;
    setIsCropping(!isCropping);
    if (handleEdit) {
      handleEdit();
    }
  };

  const handleSave = (img, data) => {
    let { handleEdit } = props;
    setIsCropping(!isCropping);
    setEditedImage(img);
    if (handleEdit) {
      handleEdit(img, data);
    }
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  let {
    title,
    handleSelectFile,
    imageDetails,
    showDefault,
    originalImagePath,
    setDefaulImageHandler,
    isDisabled
  } = props;

  return (
    <div className="container">
      <HeadingContainer>
        <SubHeading>{title}</SubHeading>
        {title === "Hero Image" && (
          <Button
            text="Default Image"
            width={112}
            type="primary"
            buttonClass="default-image"
            onClick={setDefaulImageHandler}
            isDisabled={isDisabled}
          />
        )}
      </HeadingContainer>
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        {(!showDefault && files.length !== 0) || props.path ? (
          <ImageWrapper>
            <Image src={showDefault ? props.path : renderImage || props.path} />
          </ImageWrapper>
        ) : (
          <Fragment>
            <DesignUpload />
            <Hint>Drag and drop</Hint>
          </Fragment>
        )}
        <ButtonContainer className="btn-container">
          <ButtonWrapper isMargin={!!files.length || !!props.path}>
            <Button
              text="Choose File"
              width={112}
              isFixed={true}
              type="primary"
              onClick={handleSelectFile}
              isDisabled={false}
            />
          </ButtonWrapper>
          {(!showDefault && files.length) || props.path ? (
            <Button
              text="Edit"
              width={112}
              isFixed={true}
              type="secondary"
              onClick={handleClick}
            />
          ) : null}
        </ButtonContainer>
      </Container>
      {isCropping && (
        <ImageCropper
          isOpen={isCropping}
          onClose={handleClick}
          handleSave={handleSave}
          imageSrc={
            originalImagePath || (files.length && files[0].preview) || ""
          }
          zoomable={false}
          zoomOnTouch={false}
          zoomOnWheel={false}
          cropDetail={imageDetails}
        />
      )}
    </div>
  );
};

const HeadingContainer = styled.div`
  margin-bottom: 15px;
  position: relative;

  .default-image {
    position: absolute;
    right: 0;
    bottom: -7px;
  }
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: ${props => (props.isMargin ? `15px` : `0`)};
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const ImageWrapper = styled.div`
  margin-bottom: 10px;
  height: 145px;
  max-height: 145px;
`;

const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  display: block;
`;

const DesignUpload = styled(SlideUpload)`
  height: 42px;
  width: 52px;
`;

const Hint = styled.span`
  display: block;
  margin: 15px auto 25px;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.DARK_GREY};
`;

const SubHeading = styled.span`
  display: block;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  text-align: left;
`;

export default StyledDropzone;

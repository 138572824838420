import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import {
  get
} from "lodash";

import {
  onThemeListSuccess,
  isThemeDataLoading
} from "./actions";


/**
 * Delete a theme/deactivate based on user response
 * @param {string} contentRepositoryId
 * @param {string} themeId
 * @param {string} action - deleted/deactivated based on user response
 */
const deleteOrDeactivateTheme = (contentRepositoryId, themeId, action = 'deleted') => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes/${themeId}`;

  // start loading
  dispatch(isThemeDataLoading({
    isThemeLoading: true
  }));

  const response = await FetchUtils.deleteData(URL, "Delete/deactivate a Theme");

  // stop loading
  dispatch(isThemeDataLoading({
    isThemeLoading: false
  }));

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: `Theme ${action} successfully.`
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

}

/**
 * Get Content repositories list
 *
 */
const getThemeList = contentRepositoryId => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes`;

  // start loading
  dispatch(isThemeDataLoading({
    isThemeLoading: true
  }));

  const response = await FetchUtils.getData(URL);

  // stop loading
  dispatch(isThemeDataLoading({
    isThemeLoading: false
  }));

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onThemeListSuccess({
        themeList: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const saveTheme = (contentRepositoryId, themeId, body, message) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes/${themeId}`;

  // start loading
  dispatch(isThemeDataLoading({
    isThemeLoading: true
  }));

  const response = await FetchUtils.patchData(URL, body, "save theme data");

  // hide loader
  dispatch(isThemeDataLoading({
    isThemeLoading: false
  }));

  if (response && response.success) {
    // dispatch logo list on success
    ToastUtils.handleToast({
      operation: "success",
      message: message || "Theme has been successfully updated.",
      autoclose: 3000
    });

  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
}

const getContentRepoName = (contentRepositoryId) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}`;

  // start loading
  dispatch(isThemeDataLoading({
    isThemeLoading: true
  }));

  const response = await FetchUtils.getData(URL);

  // stop loading
  dispatch(isThemeDataLoading({
    isThemeLoading: false
  }));

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onThemeListSuccess({
        selectedContentRepo: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
}

/**
 * Reorder themes under a content repo
 * @param {string} contentRepositoryId
 * @param {array} payload
 */
const reorderThemes = (contentRepositoryId, payload) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes/reorder`;
  // start loading
  dispatch(isThemeDataLoading({
    isThemeLoading: true
  }));

  const response = await FetchUtils.putData(URL, payload, "Reorder themes");

  // stop loading
  dispatch(isThemeDataLoading({
    isThemeLoading: false
  }));

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Theme has been successfully reordered."
    });

  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
}

const createTheme = (contentRepositoryId, body) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes`;

  dispatch(isThemeDataLoading({
    isThemeLoading: true
  }));

  const response = await FetchUtils.postData(URL, body, "save theme data");

  if (response.success && response.data) {
    // dispatch logo list on success
    dispatch(
      onThemeListSuccess({
        themePresignedIngestDetails: response.data
      })
    );

  } else {
    // hide loader
    dispatch(isThemeDataLoading({
      isThemeLoading: false
    }));

    dispatch(
      onThemeListSuccess({
        themePresignedIngestDetails: {}
      })
    )

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;

}

const uploadThemeToAws = (presignedUrl, file) => async dispatch => {
  const headers = {
    "Content-Type": file.type
  };

  dispatch(isThemeDataLoading({
    isThemeLoading: true
  }))

  const response = await FetchUtils.awsPutData(
    presignedUrl,
    file,
    headers,
    "Upload theme to aws"
  )

  if (response.success) {
    //dispatch data list on success
    dispatch(
      onThemeListSuccess({
        themeUploadStatus: true
      })
    );

  } else {
    // stop loading
    dispatch(isThemeDataLoading({
      isThemeLoading: false
    }));
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;

}

const getFilePollingStatus = id => async dispatch => {
  const URL = `/status/${id}`;

  const response = await FetchUtils.getData(URL, "File polling status");

  dispatch(isThemeDataLoading({
    isThemeLoading: true
  }))

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onThemeListSuccess({
        filePollingStatus: response
      })
    );
    // stop loading
    dispatch(isThemeDataLoading({
      isThemeLoading: get(response, `data.status`) !== "Failed" && get(response, `data.status`) !== "Completed"
    }));
  } else {
    // stop loading
    dispatch(isThemeDataLoading({
      isThemeLoading: false
    }));
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
}

const stopThemeUpload = () => dispatch => {
  dispatch(
    isThemeDataLoading({
      isThemeLoading: false
    })
  );
};

export {
  deleteOrDeactivateTheme,
  getThemeList,
  saveTheme,
  getContentRepoName,
  reorderThemes,
  createTheme,
  getFilePollingStatus,
  stopThemeUpload,
  uploadThemeToAws
};

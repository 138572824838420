import React from "react";
import styled from "styled-components";
import Container from "./container";
import { MapInteractionCSS } from "react-map-interaction";

const DynamicImageEditor = props => {
  let {
    activeImageUrl,
    activeImageAlt,
    imageTransformHandler,
    scale,
    translation,
    dynamicImageContainer,
    dynamicImageRef,
    imageWidth,
    imageHeight,
    imageLoadHandler,
    showDynamicImageOverlay
  } = props;

  let rect = {};
  if (dynamicImageContainer.current) {
    rect = dynamicImageContainer.current.getBoundingClientRect();
  }

  return (
    <EditorWrapper showDynamicImageOverlay={showDynamicImageOverlay}>
      <ImageEditor className="image-editor">
        <MapInteractionCSS
          scale={scale || 1}
          translation={translation}
          minScale={1}
          disableZoom={!showDynamicImageOverlay}
          disablePan={!showDynamicImageOverlay}
          onChange={({ scale, translation }) =>
            imageTransformHandler({ scale, translation }, true)
          }
          translationBounds={{
            xMax: 0,
            yMax: 0,
            xMin: -(imageWidth - rect.width),
            yMin: -(imageHeight - rect.height)
          }}
        >
          <Image
            src={activeImageUrl}
            alt={activeImageAlt}
            ref={dynamicImageRef}
            onLoad={imageLoadHandler}
          />
        </MapInteractionCSS>
      </ImageEditor>
    </EditorWrapper>
  );
};

const EditorWrapper = styled.div`
  .image-editor div div {
    cursor: ${props =>
      props.showDynamicImageOverlay ? "all-scroll" : "default"} !important;
  }
`;

const Image = styled.img`
  height: auto;
`;

const ImageEditor = styled.div``;

export default Container(DynamicImageEditor);

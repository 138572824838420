import React from "react";
import PreviewModal from "components/previewModal";
import styled from "styled-components";
import { RightArrow, SlidePreviewLeftArrow } from "assets/icons";
import { map, get } from "lodash";
import AnnouncementList from "./announcementList";
import ShadowScrollbars from "components/custom-scrollbars/ShadowScrollbars";

const ViewMore = props => {
  let {
    showAllAnnouncement,
    pageNumber,
    announcementList,
    paginationHandler,
    isAnnouncementLoading,
    announcementLimit
  } = props;

  let recordsCountFrom = (pageNumber - 1) * announcementLimit + 1;
  let recordsCountTo =
    recordsCountFrom + get(announcementList, `data.announcements.length`) - 1;

  return (
    <PageWrapper>
      <PreviewModal width="92%" maxWidth="940" closeModal={showAllAnnouncement}>
        <TitleHeader>
          <Title>Announcements</Title>
          <Pagination>
            {get(announcementList, "hasPrevPage") && (
              <StyledLeftArrow onClick={() => paginationHandler("left")} />
            )}
            {!isAnnouncementLoading ? (
              <PaginationText>
                {recordsCountFrom} - {recordsCountTo} of{" "}
                {get(announcementList, `totalDocs`)}
              </PaginationText>
            ) : (
              <LoadingText>Loading...</LoadingText>
            )}
            {get(announcementList, `hasNextPage`) && (
              <StyledRightArrow onClick={() => paginationHandler("right")} />
            )}
          </Pagination>
        </TitleHeader>

        <ShadowScrollbars autoHeight={true} autoHeightMax={500}>
          <PreviewContent>
            {map(get(announcementList, `data.announcements`), announcement => {
              return (
                <AnnouncementList
                  announcementLimit={announcementLimit}
                  pageNumber={pageNumber}
                  announcement={announcement}
                  key={get(announcement, "_id")}
                  {...props}
                />
              );
            })}
          </PreviewContent>
        </ShadowScrollbars>
      </PreviewModal>
    </PageWrapper>
  );
};

const StyledRightArrow = styled(RightArrow)`
  cursor: pointer;
  margin-left: 11px;
  vertical-align: middle;
  width: 6.9px;
  height: 11.8px;
`;

const PageWrapper = styled.div`
  .overlay-content {
    min-height: 449px;
    position: fixed;
  }
`;

const StyledLeftArrow = styled(SlidePreviewLeftArrow)`
  cursor: pointer;
  margin-right: 11px;
  vertical-align: middle;
  width: 6.9px;
  height: 11.8px;
`;

const TitleHeader = styled.div`
  padding: 30px 37px;
  text-align: left;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.06);
  border-radius: 3px;
`;

const Title = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  color: ${props => props.theme.COLOR.HEADING};
`;

const Pagination = styled.div`
  float: right;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
  color: ${props => props.theme.COLOR.HEADING};
`;

const LoadingText = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
  opacity: 0.3;
  color: ${props => props.theme.COLOR.HEADING};
`;

const PaginationText = styled.span`
  opacity: 0.3;
`;

const PreviewContent = styled.div`
  padding: 0 40px 27px;
  text-align: left;
`;

export default ViewMore;

import React from "react";
import Container from "./container";
import { Redirect } from "react-router-dom";
import Loader from "components/loader";
import { includes, get } from "lodash";
import ToastUtils from "utils/handleToast";

const HomePage = props => {
  let roles = get(props, "userProfileMeta.roles") || [];
  let $isLoading = roles.length ? false : true;

  /**
   * If the selected tool is not present allow redirection else check if the selected tool and available roles match
   *1. When clicking on site logo send the selected tool in state to compare and redirect on the selected tool route default page
   *2. When changing the tool from tool selection dropdown send the selected tool
   *
   * @param {*} tool Send the currently selected tool
   * @returns Boolean value
   */
  let checkToolAccess = tool => {
    if (get(props, "location.state.selectedTool.value")) {
      return tool === get(props, "location.state.selectedTool.value");
    } else {
      return true;
    }
  };

  // Redirect to sales after login
  if (!$isLoading) {
    if (includes(roles, "RG-Admin") && checkToolAccess("renewal")) {
      return <Redirect to={"/renewal/admin"} />;
    } else if (
      includes(roles, "RG-Underwriter") &&
      checkToolAccess("renewal")
    ) {
      return <Redirect to={"/renewal/rates"} />;
    } else if (includes(roles, "RG-Sales") && checkToolAccess("renewal")) {
      return <Redirect to={"/renewal/renewals"} />;
    } else if (
      (includes(roles, "PG-Users") || includes(roles, "PG-System-Admin")) &&
      checkToolAccess("presentation")
    ) {
      return <Redirect to={"/presentation/list"} />;
    } else if (
      (includes(roles, "MG-Admin") || includes(roles, "MG-Users")) &&
      checkToolAccess("placemat")
    ) {
      return <Redirect to={"/marketing-materials/documents"} />;
    } else {
      //Show if user is created but no roles are assigned to user
      ToastUtils.handleToast({
        operation: "error",
        message: "No roles have been assigned to the user. Please contact an admin for assistance."
      });
      return <Redirect to={"/logout"} />;
    }
  } else {
    return <Loader />;
  }
};

export default Container(HomePage);

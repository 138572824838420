import React from "react";
import Container from "./container";
import SortableTree, { walk } from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import styled from "styled-components";

import { get } from "lodash";
import DragIcon from "assets/icons/dragIcon.svg";

//components
import SortableTreeHeader from "./components/sortableTreeHeader";
import CategoryTitleComponent from "./components/categoryTitle";

const SortableTreeContainer = props => {
  let {
    LeftPanelRef,
    treeData,
    categoryLevelContainerRef,
    windowNode,
    selectedParentELem,
    handleTreeOnChange,
    checkIfElementCanBeDropped,
    onMoveNode,
    onDragStateChanged,
    searchQueryString,
    searchFocusIndex,
    searchFinishCallback,
    selectedParentDetails,
    setResetCategory,
    categoryIdToEdit,
    onSave,
    removeNodeFromTree,
    setParentDetails,
    generateButtonNodeList,
    editInput,
    resetNewFocusIndex,
    newFocusIndex,
    isEditedFlag,
    categoryTitle,
    inputError
  } = props;
 
  let ModuleContainerHeight =
    (LeftPanelRef.current &&
      get(LeftPanelRef.current, "childNodes[0].childNodes[0].offsetHeight")) ||
    0;

  let CategoryContainerHeight =
    categoryLevelContainerRef.current &&
    categoryLevelContainerRef.current.offsetHeight;

  let height =
    CategoryContainerHeight && CategoryContainerHeight <= ModuleContainerHeight
      ? ModuleContainerHeight
      : windowNode.innerHeight - 60;

  let reactVitualizedProp = newFocusIndex
    ? { scrollToIndex: newFocusIndex }
    : {};
  const Message =
    "You must create a new level and add a theme before adding slides.";
  //keep expanded parent when selected to hide/unhide/delete/edit
  walk({
    treeData,
    getNodeKey: ({ node }) => node._id,
    callback: rowInfo => {
      let { node } = rowInfo;
      if (selectedParentELem.indexOf(node._id) > -1) node.expanded = true;
    },
    ignoreCollapsed: false
  });

  return (
    <>
      <CategoryLevelContainer
        ref={categoryLevelContainerRef}
        innerHeight={height}
        treeData={treeData}
      >
        <SortableTreeHeader {...props} />
        {treeData.length ? (
          <ContainerSortable className="sort-container" style={{}}>
            <SortableTree
              className={"sortable-wrapper"}
              treeData={treeData}
              onChange={handleTreeOnChange}
              canDrop={checkIfElementCanBeDropped}
              maxDepth={4}
              onMoveNode={onMoveNode}
              onDragStateChanged={onDragStateChanged}
              onlyExpandSearchedNodes
              searchQuery={
                typeof searchQueryString === "string" &&
                searchQueryString.length > 2
                  ? searchQueryString.trim()
                  : null
              }
              shouldCopyOnOutsideDrop={true}
              canDrag={categoryIdToEdit || editInput ? false : true}
              searchFocusOffset={searchFocusIndex !== -1 ? searchFocusIndex : 0}
              getNodeKey={({ node }) => node._id}
              reactVirtualizedListProps={reactVitualizedProp}
              searchMethod={params => {
                const {
                  searchQuery,
                  node: { title }
                } = params;
                return (
                  searchQuery &&
                  title &&
                  typeof searchQueryString === "string" &&
                  searchQueryString.length > 2 &&
                  title
                    .toLowerCase()
                    .indexOf(searchQueryString.trim().toLowerCase()) > -1
                );
              }}
              searchFinishCallback={matches => {
                if (!isEditedFlag) {
                  searchFinishCallback(matches);
                }
              }}
              onVisibilityToggle={({ node, expanded }) => {
                // check elements for multiple items toggled together
                let selectedParentIsArray = Array.isArray(selectedParentELem);
                let nodeIndex = selectedParentIsArray
                  ? selectedParentELem.indexOf(node._id)
                  : -1;

                if (expanded && selectedParentIsArray && nodeIndex === -1) {
                  selectedParentELem.push(node._id);
                } else if (nodeIndex > -1 && selectedParentIsArray) {
                  selectedParentELem.splice(nodeIndex, 1);
                }
              }}
              //
              generateNodeProps={rowInfo => {
                const {
                  node,
                  node: { level, title, _id, label }
                } = rowInfo;
                let searchActive =
                  title &&
                  typeof searchQueryString === "string" &&
                  searchQueryString.length > 2 &&
                  !!searchQueryString.toLowerCase() &&
                  title
                    .toLowerCase()
                    .indexOf(searchQueryString.trim().toLowerCase()) > -1
                    ? "search-active"
                    : "";
                let selectTargetParent =
                  Object.keys(selectedParentDetails).length &&
                  selectedParentDetails._id === _id
                    ? "selected-target"
                    : "";
                return {
                  className: `level${level} ${searchActive} ${selectTargetParent} ${label !==
                    "slide" && "alignTitle"} ${label !== "slide" && label}`,
                  title: (
                    <CategoryTitleComponent
                      {...node}
                      rowInfo={rowInfo}
                      setResetCategory={setResetCategory}
                      categoryIdToEdit={categoryIdToEdit}
                      onSave={onSave}
                      removeNodeFromTree={removeNodeFromTree}
                      setParentDetails={setParentDetails}
                      selectedParentDetails={selectedParentDetails}
                      categoryInput={
                        categoryIdToEdit === _id || editInput === _id
                      }
                      editInput={editInput}
                      resetNewFocusIndex={resetNewFocusIndex}
                      isEditedFlag={isEditedFlag}
                      categoryTitle={categoryTitle}
                      inputError={inputError}
                    />
                  ),
                  buttons:
                    (generateButtonNodeList &&
                      generateButtonNodeList(rowInfo)) ||
                    []
                };
              }}
            />
          </ContainerSortable>
        ) : (
          <TreeDataNotExistText>{Message}</TreeDataNotExistText>
        )}
      </CategoryLevelContainer>
    </>
  );
};

const CategoryLevelContainer = styled.div`
  background: ${props => props.theme.COLOR.WHITE};
  width: 75%;
  display: inline-block;
  border-radius: 3px;
  font-family: ${props => props.theme.FONT.REG};
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  height: ${props => props.innerHeight}px;
  position: relative;
  @media (max-width: 1024px) and (min-width: 980px) {
    width: 648px;
  }

  .rst__lineHalfHorizontalRight::before{
     height:${props => props.treeData &&  props.treeData.length && props.treeData.length<= 1 ? props.treeData[0]['children'] && props.treeData[0]['children'].length?"1 !important":"0px !important":"1 !important"};
    }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: hidden !important;
  }

  .rst__rowLabel {
    max-width: 488px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      max-width: 230px;
    }
  }

  .rst__rowSearchMatch,
  .rst__rowSearchFocus {
    outline: solid 2px transparent;
  }

  .search-active,
  .selected-target {
    outline: solid 2px #0080ff;
  }

  .rst__nodeContent {
    width: 92%;
  }

  .rst__highlightLineVertical::before {
    width: 1px;
    height: 100%;
    border: none !important;
    margin-left: 0;
    background-color: #000;
  }

  .rst__highlightLineVertical::after,
  .rst__highlightBottomLeftCorner::after {
    border: none !important;
  }

  .rst__highlightTopLeftCorner::before {
    width: 50%;
    border-top: solid 1px #000;
    border-left: solid 1px #000;
    margin-top: 0;
  }

  .rst__highlightBottomLeftCorner::before {
    border-bottom: none;
    border-left: solid 1px #000;
    height: calc(100% + 10px);
    right: 12px;
    width: calc(50% - 12px);
  }

  .rst__expandButton,
  .rst__collapseButton {
    z-index: 10;
  }

  .rst__virtualScrollOverride {
    overflow-x: hidden !important;
  }
  .rst__tree {
    padding-top: 10px;
  }

  .rst__node:last-of-type {
    margin-bottom: 40px;
  }

  .rst__rowLabel {
    width: 30%;
    text-overflow: ellipsis;
    height: 100%;
  }

  .level0 {
    .cat-title-input {
      @media screen and (max-width: 1050px) {
        width: 50%;
      }
    }

    @media screen and (min-width: 1025px) {
      .rst__rowLabel {
        width: 62%;
        padding-right: 0;
      }
    }
  }

  .level1 {
    @media screen and (min-width: 1025px) {
      .rst__rowLabel {
        width: 59%;
        padding-right: 0;
      }
    }
  }

  .alignTitle {
    .rst__rowTitle > span {
      height: 100%;
      line-height: 40px;
    }
  }

  .level2 {
    @media screen and (min-width: 1025px) {
      .rst__rowLabel {
        width: 60%;
      }
    }
  }

  .level3 {
    @media screen and (min-width: 1025px) {
      .rst__rowLabel {
        width: 50%;
      }
    }
  }

  .level1 {
    .cat-title-input {
      @media screen and (max-width: 1100px) {
        width: 50%;
      }
      @media screen and (max-width: 1024px) {
        width: 42%;
      }
    }
  }

  .level2 {
    .cat-title-input {
      @media screen and (max-width: 1170px) {
        width: 58%;
      }
      @media screen and (max-width: 1135px) {
        width: 45%;
      }
      @media screen and (max-width: 1024px) {
        width: 31%;
      }
    }
  }

  .category {
    .rst__moveHandle,
    .rst__rowContents {
      background-color: #b3dde6 !important;
    }
  }
  .header {
    .rst__moveHandle,
    .rst__rowContents {
      background-color: #ecf7f9 !important;
    }
  }

  .sub-header {
    .rst__moveHandle,
    .rst__rowContents {
      background-color: #efe9e9 !important;
    }
  }

  .level1 {
    margin-right: 45px;
    &::before {
      margin-right: 45px;
    }
  }

  .level2 {
    margin-right: 90px;
    &::before {
      margin-right: 90px;
    }
  }

  .level3 {
    margin-right: 134px;
    &::before {
      margin-right: 134px;
    }
  }

  .level0,
  .level1,
  .level2,
  .level3 {
    .created-date {
      left: unset;
      right: 170px;
    }
  }

  .rst__node:last-of-type {
    margin-bottom: 40px;
  }

  .rst__rowTitle {
    outline: none;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .rst__moveHandle {
    min-width: 44px;
    background-image: url(${DragIcon});
  }
`;

const ContainerSortable = styled.div`
  width: 100%;
  height: calc(100% - 25px);
  padding-bottom: 30px;
  position: absolute;
  top: 50px;
`;

const TreeDataNotExistText = styled.p`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.theme.COLOR.HEADING};
  font-weight: bold;
  opacity: 0.9;
  text-align: center;
`;
export default Container(SortableTreeContainer);

import React, { Component } from "react";
import styled, { css } from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { FaInfoCircle } from "react-icons/fa";
import ReactHintFactory from "react-hint";
const ReactHint = ReactHintFactory(React);

const StyledInfoIcon = styled(FaInfoCircle)`
  margin-top: 2px;
  size: 10px;
  transform: translate(5px, 1px);
  cursor: pointer;
  color: ${props => props.theme.COLOR.MAIN};
`;

const IconWrapper = styled.span`
  width: 19px;
  height: auto;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: 1px;
`;

const ExpandCollapseButton = styled.button`
  width: 75px;
  height: 20px;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid ${props => hexToRgba(props.theme.COLOR.MAIN, "0.7")};
  color: ${props =>
    !props.expandActive
      ? hexToRgba(props.theme.COLOR.MAIN, "0.6")
      : hexToRgba(props.theme.COLOR.HEADING, "0.7")};
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  text-align: center;
  margin-top: ${props => (props.modules ? "2px" : "5px")};
  ${props => (props.modules ? "float:right" : "")};
  background: transparent;
  outline: none;
  font-family: ${props => props.theme.FONT.REG};
  pointer-events: ${props =>
    props.categoryIdToEdit || props.editInput ? "none" : "auto"};
  opacity: ${props => (props.categoryIdToEdit || props.editInput ? 0.5 : 1)};
`;

// Admin Module Header Styled components
const LabelSharedCSS = css`
  margin-top: 3px;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  opacity: 0.64;
  outline: none;
  border: none;
  background: transparent;
  display: inline-block;
  vertical-align: top;
  text-align: left;
`;

const CategoryName = styled.span`
  ${LabelSharedCSS}
  float: left;
`;

const CreatedDate = styled.span`
  ${LabelSharedCSS}
  position: absolute;
  right: 220px;
`;

const CreateContentTabs = styled.div`
  min-height: 58px;
  max-height: 64px;
  background: #fff;
  position: relative;
  z-index: 20;
  padding: ${props =>
    props.module ? "21px 18px 21px 40px" : "17px 32px 17px 40px"};
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  &:after {
    content: " ";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  .custom-hint__content {
    width: 300px;
    padding: 10px;
    background-color: ${props => props.theme.COLOR.BLACK};
    border-radius: 4px;

    span {
      color: ${props => props.theme.COLOR.WHITE};
    }
  }
`;

const TableColumns = styled.div`
  display: inline-block;
  width: 68%;
  margin-left: 16px;
`;

const NewLevel = styled.button`
  width: 75px;
  height: 20px;
  border-radius: 4px;
  font-size: 12px;
  float: right;
  border: 1px solid ${props => hexToRgba(props.theme.COLOR.MAIN, "0.7")};
  color: ${props => hexToRgba(props.theme.COLOR.HEADING, "0.7")};
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  text-align: center;
  margin-top: 2px;
  margin-right: 3%;
  background: transparent;
  outline: none;
  font-family: ${props => props.theme.FONT.REG};
  pointer-events: ${props =>
    props.categoryIdToEdit || props.editInput ? "none" : "auto"};
  opacity: ${props => (props.categoryIdToEdit || props.editInput ? 0.5 : 1)};
  position: relative;
`;

//messages
const NewLevelHint = `
  To create a category, click on New Level.<br> To create a sub-category, select a category and click on New Level.
`;

/**
 * render hint on hover
 */
const onRenderContent = (target, content) => {
  return (
    <div className="custom-hint__content">
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

//generate admin module repo header
const GenerateAdminModulesTab = () => {
  return (
    <TableColumns>
      <CategoryName>Name</CategoryName>
      <CreatedDate>Created Date</CreatedDate>
    </TableColumns>
  );
};

export default class extends Component {
  render() {
    const {
      gData,
      expandCollapseAllFlag,
      toggleNodeExpansion,
      toggleExpand,
      addNewNode,
      categoryIdToEdit,
      editInput
    } = this.props;

    const ExpandCollapseContainer = (modules = false) => (
      <>
        {gData && gData.length === 0
          ? null
          : expandCollapseAllFlag && (
              <ExpandCollapseButton
                categoryIdToEdit={categoryIdToEdit}
                editInput={editInput}
                modules={modules}
                expandActive={!toggleExpand}
                title={!toggleExpand ? "Expand All" : "Collapse All"}
                onClick={() => toggleNodeExpansion(!toggleExpand)}
              >
                {!toggleExpand ? "Expand All" : "Collapse All"}
              </ExpandCollapseButton>
            )}
      </>
    );

    return (
      <CreateContentTabs>
        <ReactHint events onRenderContent={onRenderContent} />
        <GenerateAdminModulesTab />
        <ExpandCollapseContainer />
        <NewLevel
          onClick={addNewNode}
          categoryIdToEdit={categoryIdToEdit}
          editInput={editInput}
        >
          New Level
          <IconWrapper>
            <StyledInfoIcon
              size={12}
              data-rh={NewLevelHint}
              data-rh-at="right"
            />
          </IconWrapper>
        </NewLevel>
      </CreateContentTabs>
    );
  }
}

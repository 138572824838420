import React, { Component } from "react";
import { Delete, EditWithNoShadow } from "assets/icons";
import styled from "styled-components";
import { mapStateToProps, actions } from "./mapStateToProps";
import { get, includes, set, sortBy, each, map, difference } from "lodash";
import ValidationUtils from "utils/ValidationUtils";
import ToastUtils from "utils/handleToast";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import { connect } from "react-redux";

const EMPTY_FIELD_ERROR_MESSAGE = "This field is required.";
const SPECIAL_CHAR_ERROR_MESSAGE = "Please do not enter the special character.";
const WHITE_SPACE_ERROR_MESSAGE = "Please enter a valid input.";
const EMAIL_FIELD_ERROR_MESSAGE = "Please enter a valid email.";
const REPEAT_PASSWORD_ERROR_MESSAGE =
  "Password and Repeat Password do not match.";
const PASSWORD_VALIDATION_MESSAGE =
  "Your password must be at least 8 characters long, contain at least one number, one special character(!@#$%^&*) and have a mixture of uppercase and lowercase letters.";

const Container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class User extends Component {
      static defaultProps = {
        role: "admin"
      };
      state = {
        data: [],
        cols: [],
        isFullPageLoaderActive: false,
        isRenderTable: true,
        tableColumnHeader: ["nameWithStatus", "email", "roles", ""],
        selectedTabValue: "users",
        uploadedFile: null,
        form: {
          firstName: { value: "", error: "" },
          lastName: { value: "", error: "" },
          email: { value: "", error: "" },
          password: { value: "", error: "" },
          repeatPassword: { value: "", error: "" },
          roles: { value: [], error: "" },
          stateListValue: { value: [], error: "" },
          emailAccess: { value: true },
          contentRepositoriesValue: { value: [], error: "" }
        },
        isEdit: false,
        editedRowData: {},
        stateList: [],
        stateListArray: [],
        editedUserId: null,
        accessibleTools: []
      };
      editedFields = [];
      userColumns = [
        {
          col1: "Name",
          col2: "Email",
          col3: "Role",
          col4: "Actions"
        }
      ];
      columnWidth = [235, 155, 145, 75];
      searchFields = ["nameWithStatus", "email"];

      componentDidMount() {
        // load user list and state list on component mount
        this._fetchData();
      }

      checkBoxValueHandler = async (label, form) => {
        let indexOfLabel = form.roles.value.indexOf(label);
        // add role to the form if not present or else remove it
        if (includes(form.roles.value, label)) {
          form.roles.value.splice(indexOfLabel, 1);
        } else {
          form.roles.error = "";
          form.roles.value.push(label);
        }
        let accessibleTools = this._checkUserPremissions(form.roles.value);
        // Is renewal tool access
        let isRenewal = includes(accessibleTools, "RG");
        !(this.state.stateList || []).length &&
          isRenewal &&
          (await this._fetchStateList());

        // if roles include RG-Admin set all states as selected
        if (form.roles.value.indexOf("RG-Admin") !== -1) {
          this.setState({
            stateListArray: this.state.stateList
          });

          form.stateListValue.value = this.state.stateList;
        }

        this.setState({
          form,
          accessibleTools
        });
      };

      _fetchData = async () => {
        let usersList = await this.props.getUsersList();
        if (usersList && usersList.success) {
          this.formatUsersListResponse(usersList.data);
          this.setState({
            data: usersList.data,
            cols: this.userColumns,
            isRenderTable: true,
            isFullPageLoaderActive: false
          });
        } else {
          ToastUtils.handleToast({ operation: "dismiss" });
          ToastUtils.handleToast({
            operation: "error",
            message: get(usersList, "data.message")
          });
        }
      };

      formatUsersListResponse = userListData => {
        userListData.forEach(item => {
          if (item.blocked) {
            set(item, "nameWithStatus", `${get(item, `name`)} (User Deleted)`);
          } else {
            set(item, "nameWithStatus", `${get(item, `name`)}`);
          }
        });

        this.setState({
          data: userListData
        });
      };

      _fetchStateList = async () => {
        this.setState({
          isFullPageLoaderActive: true
        });
        let stateList = await this.props.fetchStateList();
        if (stateList && stateList.success) {
          let sortedStateList = sortBy(stateList.data, ["state"]);

          this.setState({
            stateList: sortedStateList
          });
        } else {
          ToastUtils.handleToast({ operation: "dismiss" });
          ToastUtils.handleToast({
            operation: "error",
            message: get(stateList, "data.message")
          });
        }
      };

      /**
       * called on click of edit icon
       *
       * @param {Object} rowData row details of row to be edited
       */
      editHandler = rowData => {
        // user should not be edited if the user is deleted
        if (rowData.blocked) {
          return;
        }

        let form = JSON.parse(JSON.stringify(this.state.form));

        Object.keys(form).forEach(formField => {
          form[formField].error = "";
        });

        this.setEditedUserId(rowData._id);
        this.setState({
          isEdit: true,
          editedRowData: rowData
        });

        this.state.selectedTabValue !== "users" &&
          this.setState({
            selectedTabValue: "users" //open the users tab when edit is clicked
          });

        let { stateListArray } = this.state;
        form.firstName.value = get(rowData, "given_name", "");
        form.lastName.value = get(rowData, "family_name", "");
        form.email.value = get(rowData, "email", "");
        form.roles.value = get(rowData, `roles`);
        stateListArray = get(rowData, `states`);
        form.stateListValue.value = rowData.states
          ? rowData.states.map(state => {
              return state._id;
            })
          : [];
        form.emailAccess.value = false;
        form.contentRepositoriesValue.value = map(
          get(rowData, `contentRepositories`, []),
          "_id"
        );

        let accessibleTools = this._checkUserPremissions(form.roles.value);
        // Is renewal tool access
        let isRenewal = includes(accessibleTools, "RG");
        isRenewal && this._fetchStateList();

        this.setState({
          form,
          stateListArray,
          accessibleTools
        });
      };

      /**
       * multi-select dropdown handler
       * @param {Object} value selected option
       */
      selectMultipleOption = value => {
        let form = JSON.parse(JSON.stringify(this.state.form));
        // store the id of all the states
        form.stateListValue.value =
          value &&
          value.map(stateItem => {
            return stateItem._id;
          });

        form.stateListValue.error = value
          ? ""
          : this.checkValidation(value, "stateListValue");
        this.setState(
          {
            stateListArray: value,
            form
          },
          () => {
            let rowData = this.state.editedRowData;
            if (this.state.editedUserId) {
              if (
                rowData.states !== form.stateListValue.value &&
                this.editedFields.indexOf("states") === -1
              ) {
                this.editedFields.push("states");
              }
            }
          }
        );
      };

      /**
       * Save the user data when add is clicked
       */
      saveEditedUser = async () => {
        let form = JSON.parse(JSON.stringify(this.state.form));

        let editedUserData = {};

        this.editedFields.forEach(field => {
          let editedField = field === "states" ? "stateListValue" : field;
          // Check if contentRepository field
          if (field === "contentRepositoriesValue") {
            editedUserData["contentRepositories"] = form[editedField].value;
            return;
          } else if (form["emailAccess"].value === true) {
            editedUserData["email"] = form["email"].value;
          }

          // do nothing when emailaccess checkbox checked or unchecked without editing anything
          if (field === "emailAccess" && form["emailAccess"].value === false) {
            return;
          } else {
            editedUserData[field] = form[editedField].value;
          }
        });

        if (includes(this.editedFields, "email")) {
          // assigning emailAccess value when email changes
          Object.assign(editedUserData, {
            emailAccess: form.emailAccess.value
          });
        }

        this.setState({
          isFullPageLoaderActive: true
        });
        const response = await this.props.editUser(
          editedUserData,
          this.state.editedUserId
        );
        this.setState({
          isFullPageLoaderActive: false
        });

        if (response && response.success) {
          let { stateListArray } = this.state;
          form.firstName.value = response.data.given_name;
          form.lastName.value = response.data.family_name;
          form.email.value = response.data.email;
          form.roles.value = get(response, `data.roles`);
          stateListArray = get(response, `data.states`);
          form.stateListValue.value = response.data.states
            ? response.data.states.map(state => {
                return state._id;
              })
            : [];

          this.setState({
            form,
            stateListArray
          });

          this.resetFormDetails();

          await this._fetchData();

          ToastUtils.handleToast({
            operation: "success",
            message: "User has been updated successfully.",
            autoclose: 3000
          });

          this.editedFields = [];
        } else {
          ToastUtils.handleToast({
            operation: "error",
            message: get(response, "data.message")
          });
        }
      };

      /**
       * set the current tab
       */
      setTab = async ({ propName, value }) => {
        if (this.state.selectedTabValue === value) return;

        // Get content Repo list on tab change
        if (value === "repos") {
          let response = await this.props.getContentRepoList();
          if (response.success) {
            this.setState({
              contentRepoDropdown: response.data || []
            });
          }
        }
        this.setState({
          selectedTabValue: value
        });
      };

      // function to show last column in table
      showIcon = rowData => {
        return (
          <>
            <IconWrapper>
              <EditSquareIcon
                title="Select"
                onClick={() => this.editHandler(rowData)}
              />
            </IconWrapper>
            <IconWrapper last>
              <DeleteIcon
                onClick={() => {
                  DeleteConfirmationAlert({
                    onYesClick: () => {
                      this.deleteUserHandler(rowData);
                    }
                  });
                }}
              />
            </IconWrapper>
          </>
        );
      };

      resetFormDetails = () => {
        let form = JSON.parse(JSON.stringify(this.state.form));
        let formFieldArray = [
          "role",
          "emailAccess",
          "stateListValue",
          "contentRepositoriesValue"
        ];

        Object.keys(form).forEach(formField => {
          if (!formFieldArray.includes(formField)) {
            form[formField].value = "";
            form[formField].error = "";
          } else {
            // empty the checkboxes by emptying the array
            form.roles.value = [];
            form.contentRepositoriesValue.value = [];
            Array.isArray(form.stateListValue.value) &&
              form.stateListValue.value.splice(
                0,
                form.stateListValue.value.length
              );
            form.emailAccess.value = true;
            form[formField].error = "";
          }
        });
        this.setEditedUserId(null);
        this.setTab({ value: "users" });

        this.setState({
          form,
          isEdit: false,
          stateListArray: [],
          accessibleTools: []
        });
      };

      /**
       * check for any errors on click of add button directly
       * @returns boolean stating if error is present or not
       */
      checkForErrors = (isNextClicked = false) => {
        let { accessibleTools, form, editedUserId } = JSON.parse(
          JSON.stringify(this.state)
        );

        // Mandatory fields based on tools
        // Add any tool specific mandatory field in its respective tool key.
        let mandatoryFields = {
          RG: ["stateListValue"],
          PG: ["contentRepositoriesValue"],
          MG: []
        };

        let toolBasedNonMandatoryFields = [];

        // Get the tools which the user does not have access to
        let notAccessibleTools = difference(
          Object.keys(mandatoryFields),
          accessibleTools
        );

        // Handle the conditional fields based on user tool access below
        each(notAccessibleTools, eachTool => {
          toolBasedNonMandatoryFields = [
            ...toolBasedNonMandatoryFields,
            ...mandatoryFields[eachTool]
          ];
        });

        let notMandatoryFields = editedUserId
          ? [
              "password",
              "repeatPassword",
              "status",
              "emailAccess",
              ...toolBasedNonMandatoryFields,
              ...(isNextClicked ? ["contentRepositoriesValue"] : [])
            ]
          : [
              "status",
              "emailAccess",
              ...toolBasedNonMandatoryFields,
              ...(isNextClicked ? ["contentRepositoriesValue"] : [])
            ];

        let errorArray = [];

        // Get unique array elements
        let uniqueSet = new Set(notMandatoryFields);
        let uniqueArray = [...uniqueSet];

        Object.keys(form).forEach(formField => {
          if (!includes(uniqueArray, formField)) {
            form[formField].error = this.checkValidation(
              form[formField].value,
              formField
            );
            if (
              form[formField].error &&
              formField !== "contentRepositoriesValue"
            ) {
              this.setTab({ value: "users" });
            }
            form[formField].error && errorArray.push(form[formField].error);
          }
        });

        // show toast message if there are any validation errors
        if (errorArray.length > 0) {
          this.setState({ form });
          ToastUtils.handleToast({
            operation: "error",
            message: "Please fill all the required fields."
          });
          return true;
        }

        return false;
      };

      /**
       * Check if the fields of the form are valid
       *
       * @param {String} value states value which was entered
       * @param {*} propName states the field for which value was entered
       * @returns appropriate error message
       */
      checkValidation = (value, propName, type) => {
        let form = JSON.parse(JSON.stringify(this.state.form));
        let onlyEmptyFieldValidation = [
          "roleCheckbox",
          "roles",
          "stateListValue",
          "contentRepoCheckbox",
          "contentRepositoriesValue"
        ];

        // checking for empty field and also check if checkboxes are unchecked otherwise value=false for checkIfEmptyField gives false
        if (
          ValidationUtils.checkIfEmptyField(value) ||
          value === null ||
          (!value && type === "roleCheckbox" && form.roles.value.length < 2) ||
          (!value &&
            type === "contentRepoCheckbox" &&
            form.contentRepositoriesValue.value.length < 2)
        ) {
          return EMPTY_FIELD_ERROR_MESSAGE;
        } else if (includes(onlyEmptyFieldValidation, type || propName)) {
          // donot go for further validations for checkboxes field
          return;
        } else if (
          !ValidationUtils.validatePassword(value) &&
          propName === "password"
        ) {
          return PASSWORD_VALIDATION_MESSAGE;
        } else if (
          form.password.value !== value &&
          (propName === "repeatPassword" ||
            (propName === "password" && !!form.repeatPassword.value)) // even if user types in password field and the repeat password does not match the new password field value, repeat password should have an error
        ) {
          return REPEAT_PASSWORD_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(value)) {
          return WHITE_SPACE_ERROR_MESSAGE;
        } else if (
          propName !== "email" &&
          ValidationUtils.checkIfspecialChar(value)
        ) {
          return SPECIAL_CHAR_ERROR_MESSAGE;
        } else if (
          propName === "email" &&
          !ValidationUtils.validateEmail(value)
        ) {
          return EMAIL_FIELD_ERROR_MESSAGE;
        } else {
          return null;
        }
      };

      /**
       * for handling form state
       *
       * @param {String} propName form field to be set
       * @param {String} value value entered by the user
       */
      manageInputStates = (e, { propName, value, type, label, keyName }) => {
        let form = JSON.parse(JSON.stringify(this.state.form));
        type === "contentRepoCheckbox"
          ? this.handleContentRepoCheckbox(form, label)
          : type === "roleCheckbox"
          ? this.checkBoxValueHandler(label, form)
          : (form[propName].value = value);
        if (type === "roleCheckbox") {
          // assign for role directly as propName for checkboxes would be rgAdmin, rgUnderWriter and rgSales
          form.roles.error = this.checkValidation(value, propName, type);
        } else if (type === "contentRepoCheckbox") {
          form.contentRepositoriesValue.error = this.checkValidation(
            value,
            propName,
            type
          );
        } else if (propName === "password" || propName === "repeatPassword") {
          // if user types in password field and the value doesnot match the repeat password value and the value entered is a validated password, repeat password should have the error message
          if (
            propName === "password" &&
            value !== form.repeatPassword.value &&
            !!form.repeatPassword.value &&
            ValidationUtils.validatePassword(value)
          ) {
            // if the password gets validated, the password field should not contain any error message whatsoever
            if (ValidationUtils.validatePassword(value)) {
              form.password.error = "";
            }
            form.repeatPassword.error = this.checkValidation(
              value,
              propName,
              type
            );
          } else if (
            propName === "password" &&
            value === form.repeatPassword.value
          ) {
            form.repeatPassword.error = "";
          } else {
            form[propName].error = this.checkValidation(value, propName, type);
          }
        } else if (type !== "accessCheckbox") {
          form[propName].error = this.checkValidation(value, propName, type);
        }

        this.setState({ form }, () => {
          if (this.state.editedUserId) {
            let rowData = this.state.editedRowData;

            let formField = keyName ? keyName : propName;
            if (
              rowData[formField] !== form[formField].value &&
              this.editedFields.indexOf(formField) === -1
            ) {
              // push the edited fields
              this.editedFields.push(formField);
            }
          }
        });
      };

      onFileUpload = e => {
        this.setState({
          uploadedFile: e.target.files[0]
        });
      };

      deleteUserHandler = async rowData => {
        // user should not be deleted if the user is deleted
        if (rowData.blocked) {
          return;
        }
        const userId = get(rowData, `_id`);

        this.setState({
          isFullPageLoaderActive: true
        });

        const response = await this.props.deleteUser(userId);

        this.setState({
          isFullPageLoaderActive: false
        });

        if (response && response.success) {
          await this._fetchData();

          ToastUtils.handleToast({
            operation: "success",
            message: "User has been deleted successfully.",
            autoclose: 3000
          });
          this.state.editedUserId === userId && this.resetFormDetails();
        } else {
          ToastUtils.handleToast({
            operation: "error",
            message: get(response, "data.message")
          });
        }
      };

      setEditedUserId = _id => {
        this.editedFields = [];
        this.setState({
          editedUserId: _id
        });
      };

      /**
       * Create a new user
       */
      addNewUser = async () => {
        let form = JSON.parse(JSON.stringify(this.state.form));
        let isRenewal = includes(this.state.accessibleTools, "RG");
        if (this.checkForErrors()) {
          return;
        }

        this.setState({
          isFullPageLoaderActive: true
        });

        let newUserData = {
          email: form.email.value,
          roles: form.roles.value,
          firstName: form.firstName.value,
          lastName: form.lastName.value,
          password: form.password.value,
          ...(isRenewal &&
            get(form, "stateListValue.value.length") && {
              states: form.stateListValue.value
            }),
          ...(get(form, "contentRepositoriesValue.value.length") && {
            contentRepositories: form.contentRepositoriesValue.value
          }),
          emailAccess: form.emailAccess.value
        };

        let response;
        if (this.state.editedUserId) {
          this.saveEditedUser();
          this.setState({
            isFullPageLoaderActive: false,
            editedRowData: newUserData
          });

          return;
        } else {
          response = await this.props.createNewUser(newUserData);
        }

        this.setState({
          isFullPageLoaderActive: false,
          editedRowData: newUserData
        });

        if (response && response.success) {
          await this._fetchData();

          ToastUtils.handleToast({
            operation: "success",
            message: "User has been created successfully",
            autoclose: 3000
          });

          this.resetFormDetails();
        }
      };

      renderHead = () => {
        const HELPER_TEXT =
          "Welcome to the Users page. You can add new users on the right or you can edit or delete existing user details below.";
        return (
          <div className="heading">
            <HeadingName>Users</HeadingName>
            <HelperText>{HELPER_TEXT}</HelperText>
          </div>
        );
      };

      /**
       *Check accessible tools of the user
       *
       * @param {*} [tools=[]] Selected user roles
       * @returns Accessible tools of the user based in selected user roles
       */
      _checkUserPremissions = (tools = []) => {
        let accessibleTools = [];
        // Loop through selected tools
        each(tools, (eachTools = []) => {
          let splitArray = eachTools.split("-");

          // Split array on "-" and get the first element which consists of the tool initials
          // Eg: PG-Admin => splitArray = ["PG","Admin"];
          let toolInitals =
            splitArray[0] && splitArray[0].length === 2 ? splitArray[0] : "";

          // If toolInitals not included in accessible tools then push it in accessible tools.
          if (toolInitals && !includes(accessibleTools, toolInitals)) {
            accessibleTools.push(toolInitals);
          }
        });
        return accessibleTools;
      };

      onNext = () => {
        if (this.checkForErrors(true)) {
          return;
        }

        this.setTab({
          value: "repos"
        });
      };

      /**
       *Callback function for content repo checkbox click
       *
       * @param {*} form form state
       * @param {*} id Id of the selected checkbox
       */
      handleContentRepoCheckbox = (form, id) => {
        let indexOfLabel = form.contentRepositoriesValue.value.indexOf(id);
        // add role to the form if not present or else remove it
        if (includes(get(form, "contentRepositoriesValue.value"), id)) {
          form.contentRepositoriesValue.value.splice(indexOfLabel, 1);
        } else {
          form.contentRepositoriesValue.error = "";
          form.contentRepositoriesValue.value.push(id);
        }
        this.setState({
          form
        });
      };

      checkboxOperation = flag => {
        let { contentRepoDropdown, form } = JSON.parse(
          JSON.stringify(this.state)
        );
        let contentRepoId = [];
        if (flag === "all") {
          contentRepoId = map(contentRepoDropdown, "_id");
        } else if (flag === "none") {
          contentRepoId = [];
        }
        form.contentRepositoriesValue.value = contentRepoId;
        form.contentRepositoriesValue.error = this.checkValidation(
          contentRepoId,
          "contentRepositoriesValue",
          "contentRepoCheckbox"
        );
        this.editedFields.push("contentRepositoriesValue");
        this.setState({
          form
        });
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.props,
          ...$this.state
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

const IconWrapper = styled.span`
  height: auto;
  display: inline-block;
  padding: 5px;
  margin-top: -5px;
  margin-left: ${props => props.last && `5px`};
  &:hover {
    opacity: 0.7;
  }
`;

const DeleteIcon = styled(Delete)`
  cursor: pointer;
  padding: 1px;
`;

const EditSquareIcon = styled(EditWithNoShadow)`
  cursor: pointer;
  width: 16px;
  height: 17px;
  padding: 1px;
`;

const HelperText = styled.p`
  ${props => props.theme.SNIPPETS.HELPER_TEXT};
  margin-bottom: 16px;
`;

const HeadingName = styled.span`
  margin-left: -2px;
  display: inline-block;
  margin-bottom: 10px;
`;

export default Container;

import React from "react";
import container from "./container";
import styled from "styled-components";
import RadioButton from "components/radioBtn";
import Tags from "components/tags";

import { map } from "lodash";
import Button from "../button";

const UI_STRINGS = {
  permittedDomainHelperText:
    "Presentations can be shared with other users via email. You can restrict the domains to only allow presentations to be sent to the following domains. Press ENTER to separate multiple domains. Leave blank to allow presentations to be sent anywhere. Leaving this field blank allows the presentation to be shared with anyone."
};

const Security = props => {
  let {
    handleEmailItemsList,
    emailItems,
    methodList,
    activeDeliveryMethod,
    deliveryMethodHandler,
    saveDataHandler,
    resetDataHandler,
    isEmptyInput,
    emptyInput
  } = props;

  return (
    <Wrapper>
      <Float>
        <HeadingContainer>
          <Heading>Security</Heading>
          <SubHeading>Delivery Method</SubHeading>
        </HeadingContainer>
        <FloatRight>
          <ButtonWrapper>
            <Button
              text="Cancel"
              width={80}
              isFixed={true}
              type="secondary"
              onClick={resetDataHandler}
            />
          </ButtonWrapper>
          <Button
            text="Save"
            width={80}
            isFixed={true}
            type="primary"
            onClick={saveDataHandler}
          />
        </FloatRight>
      </Float>
      {map(methodList, (eachMethod, index) => {
        return (
          <RadioButtonWrapper key={index * Math.random()}>
            <RadioButton
              name={eachMethod.name}
              id={eachMethod.id}
              label={eachMethod.label}
              handleChange={() => deliveryMethodHandler(eachMethod.id)}
              defaultChecked={eachMethod.id === activeDeliveryMethod}
            />
          </RadioButtonWrapper>
        );
      })}
      <SubHeading className="email-domain">Permitted Email Domains</SubHeading>
      <HelperText>{UI_STRINGS.permittedDomainHelperText}</HelperText>
      <Tags
        handleEmailItemsList={handleEmailItemsList}
        emailItems={emailItems}
        validateFor="domain"
        isEmptyInput={isEmptyInput}
        emptyInput={emptyInput}
      />
    </Wrapper>
  );
};

export default container(Security);

const Wrapper = styled.div`
  width: 100%;
  padding: 27px 40px;
  border-radius: 4px;
  background-color: #fff;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  box-sizing: border-box;

  .email-domain {
    margin-top: 38px;
  }
`;

const Heading = styled.h2`
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  text-align: left;
`;

const SubHeading = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-weight: bold;
  margin: 6px 0 10px;
`;

const RadioButtonWrapper = styled.div`
  margin-bottom: 10px;
  label {
    opacity: 0.8;
    color: ${props => props.theme.COLOR.HEADING};
  }
`;

const FloatRight = styled.div`
  float: right;
`;
const HeadingContainer = styled.div`
  float: left;
`;

const Float = styled.div`
  width: 100%;

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 15px;
`;

const HelperText = styled.p`
  color: ${props => props.theme.COLOR.MAIN};
  margin: 13px 0 10px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
`;

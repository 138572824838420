import { confirmAlert } from "react-confirm-alert"; // Import
import React from "react";
import { TiDeleteOutline } from "react-icons/ti";
import styled from "styled-components";
import { filter } from "lodash";

const DeleteConfirmationAlert = ({
  message,
  onYesClick,
  onNoClick,
  note,
  onClose,
  onCancel
}) => {
  let crosMarkFlag = true;
  const closePopupOnCrossMark = clickCloseMark => {
    let btn = document.querySelector(".react-confirm-alert-button-group");

    if (btn && btn.querySelectorAll("button")) {
      crosMarkFlag = false;
      btn.querySelectorAll("button")[1].click();
    }

    onClose && onClose();
  };


  let buttons = [
    {
      label: "Yes",
      onClick: onYesClick
    },
    {
      label: "No",
      onClick: () => {
        if (crosMarkFlag) onNoClick && onNoClick(true);
      }
    },
    onCancel && {
      label: "Cancel",
      onClick: onCancel(closePopupOnCrossMark)
    }
  ];

  confirmAlert({
    message: message || "Do you really want to delete?",
    buttons: filter(buttons, button => button && button),
    childrenElement: () => (
      <>
        <CrossMark
          onClick={() => {
            closePopupOnCrossMark(false);
          }}
        >
          <TiDeleteOutline />
        </CrossMark>
        {note && (
          <div
            style={{
              fontSize: 12,
              paddingTop: 15,
              lineHeight: 1.3,
              width: "85%"
            }}
            dangerouslySetInnerHTML={{ __html: note }}
          />
        )}
      </>
    )
  });
};

export default DeleteConfirmationAlert;

const CrossMark = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 30px;
  cursor: pointer;
`;

import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import { getFilterList, saveContentFilterCategory } from "./services";
import {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  toggleExpandedForAll,
  getNodeAtPath,
  walk,
  getFlatDataFromTree,
  getVisibleNodeCount
} from "react-sortable-tree";
import {
  each,
  get,
  findLastIndex,
  map,
  filter,
  find,
  difference
} from "lodash";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";

//services
import * as services from "./services";

import ToastUtils from "utils/handleToast";
import {
  Delete,
  SquareEdit,
  Lock,
  Unlock,
  Show as Preview,
  Hidden,
  Indent
} from "assets/icons";

//styles
const SlideCount = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  position: absolute;
  left: 64%;
  color: #363636;
  @media (max-width: 1200px) {
    left: 54%;
  }
`;

/** Icons Style */
const StyledIcon = styled.span`
  opacity: ${props => (props.enableLevel ? 1 : 0.5)};
  color: #000000;
  cursor: pointer;
  margin-left: ${props => (props.required ? "29px" : 0)};
`;

const EditIcon = styled(SquareEdit)`
  width: 17px;
  height: 15px;
  margin-right: 10px;
  transform: translateY(1px);
`;

const IndentIcon = styled(Indent)`
  width: 17px;
  height: 17px;
  margin-right: 10px;
  transform: translateY(1px);
`;

const PreviewIcon = styled(Preview)`
  cursor: pointer;
`;

const HiddenIcon = styled(Hidden)`
  cursor: pointer;
`;

const DeleteIcon = styled(Delete)`
  width: 16px;
  height: 17px;
  margin-right: 10px;
`;

const RequiredIcon = styled(Lock)`
  width: 14px;
  height: 17px;
  margin: 0 10px;
`;

const NotRequiredIcon = styled(Unlock)`
  width: 14px;
  height: 18px;
  margin: 0 10px;
`;

const toastMessage = () =>
  ToastUtils.handleToast({
    operation: "error",
    message: "Please save your changes."
  });

/**
 * Map the state to props.
 */
const mapStateToProps = state => {
  const {
    CONTENT_SLIDE_SUCCESS,
    CONTENT_REPO_SLIDE__SUCCESS,
    LOADING_CONTENT_REPO_SLIDE,
    UPLOADING_SLIDE,
    GET_THEME_DATA,
    LOADING_CONTENT_SLIDE_LIST,
    SUCCESS_THEME_LIST
  } = state;

  return {
    ...CONTENT_SLIDE_SUCCESS,
    ...CONTENT_REPO_SLIDE__SUCCESS,
    ...LOADING_CONTENT_REPO_SLIDE,
    ...UPLOADING_SLIDE,
    ...GET_THEME_DATA,
    ...LOADING_CONTENT_SLIDE_LIST,
    ...SUCCESS_THEME_LIST
  };
};
const Container = Main =>
  connect(mapStateToProps, {
    ...services,
    getFilterList,
    saveContentFilterCategory
  })(
    class ContentSlide extends Component {
      state = {
        treeData: [],
        windowNode: {},
        categoryIdToEdit: null,
        selectedParentDetails: {},
        toggleExpand: false,
        editInput: null,
        newFocusIndex: 0,
        isEditedFlag: false,
        categoryTitle: "",
        inputError: "",
        currentSelectedSlide: false,
        searchQueryString: "",
        dropDownOptionsNew: [],
        editedSlide: {},
        selectedFilterCategory: "",
        parentNode: {},
        selectedFilters: [],
        displayFiltersList: false,
        activeTab: "slide",
        isEditedFilter: false
      };

      selectedParentELem = [];

      componentDidMount() {
        this.setWindowNode();
        window.addEventListener("resize", this.setWindowNode);
        this.fetchContentSlides();
      }

      componentDidUpdate(prevState) {
        if (!prevState.searchQueryString && this.state.searchQueryString) {
          this.selectedParentELem = [];
        }
      }

      // trigger when drag stat changes
      onDragStateChanged = ({ isDragging }) => {
        if (isDragging) {
          window.addEventListener("dragover", this.scrollWindow);
        } else {
          window.removeEventListener("dragover", this.scrollWindow);
        }
      };

      //on filter dropdown change
      filterOnDropChange = e => {
        this.isFilterTabEdited(false);
        let { dropDownOptionsNew } = this.state;
        dropDownOptionsNew = JSON.parse(JSON.stringify(dropDownOptionsNew));

        let selectedCategory = dropDownOptionsNew.filter(
          (element = {}) => element._id === e.target.value
        );
        let selectedFilters =
          selectedCategory.length && selectedCategory[0].contentFilter
            ? JSON.parse(JSON.stringify(selectedCategory[0].contentFilter))
            : [];

        this.setState({
          displayFiltersList: !!e.target.value,
          selectedFilterCategory: e.target.value,
          selectedFilters
        });
      };

      getCategoryItemForContentFilters = (mainList, id) => {
        let flag = false,
          categoryContentFilter = {};

        //Loop through filter category hierarchy to fin content filter of given id
        let loop = list => {
          each(list, (elem = {}) => {
            if (elem._id === id) {
              flag = true;
              categoryContentFilter = elem;
            }
            // Check if children present and required id is not found only then loop through children items
            if (Array.isArray(elem.children) && elem.children.length && !flag) {
              loop(elem.children);
            }
          });
        };

        loop(mainList);

        return categoryContentFilter.contentFilter || [];
      };

      saveFilters = async cb => {
        await this.isFilterTabEdited(true);
        let contentRepoId = this.props.match.params.contentRepoId;
        let { selectedFilters, selectedFilterCategory } = this.state;

        let { categoryData: categoryContentFilters = [] } = this.props;
        let contentFilters = this.getCategoryItemForContentFilters(
          categoryContentFilters,
          selectedFilterCategory
        );

        selectedFilters = selectedFilters.filter(
          elem => !(elem.indexOf("F-") > -1)
        );
        contentFilters = contentFilters.filter(
          elem => !(elem.indexOf("F-") > -1)
        );

        //added/removed filters
        let removedFilters = difference(
          contentFilters,
          selectedFilters
        ).map(elem => ({ _id: elem, removeFilter: true }));
        let addedFilters = difference(
          selectedFilters,
          contentFilters
        ).map(elem => ({ _id: elem, removeFilter: false }));
        let filters = [...removedFilters, ...addedFilters];

        this.state.isEditedFilter &&
          (await this.props.saveContentFilterCategory(
            { filters },
            "",
            contentRepoId,
            selectedFilterCategory
          ));

        filters.length && (await this.getParentCategories());
        cb && cb();
        this.fetchContentSlides();
      };

      isFilterTabEdited = flag => {
        this.setState({
          isEditedFilter: flag
        });
      };

      resetFilters = () => this.setState({ selectedFilters: [] });

      // scroll window when dragged element reaches window height
      scrollWindow = e => {
        const { windowNode } = this.state;
        if (windowNode && e.screenY > windowNode.innerHeight) {
          windowNode.scrollTo({
            top: e.screenY,
            behavior: "smooth"
          });
        }
      };

      // set window object for new values on resize
      setWindowNode = () => {
        this.setState({
          windowNode: window
        });
      };

      // featch content slide list
      fetchContentSlides = async () => {
        let id =
          (this.props.match.params && this.props.match.params.contentRepoId) ||
          "";
        await this.props.fetchContentSlideList(id);
        await this.props.fetchContentSlideList(id, true);

        let treeData = this.props.contentSlideList || [];
        //add level to the slide data receive by using the recurssive function
        let updateLevel = (treeSet, level = 0) => {
          each(treeSet, (slide, index) => {
            slide.level = level;
            slide.order = index;
            if (Array.isArray(slide.children) && slide.children.length) {
              updateLevel(slide.children, slide.level + 1);
            }
          });
        };

        updateLevel(treeData);

        this.setState(
          {
            treeData,
            updatedTreeData: JSON.parse(JSON.stringify(treeData))
          },
          () => {
            this.appendIndentIcon(treeData);
            this.hideDeleteButton(treeData);
          }
        );
      };

      // Function to get parent dropdown
      getParentCategories = async () => {
        let id = get(this, "props.match.params.contentRepoId") || "";
        // Fetch only if content repo id is present
        if (!id) return;

        await this.props.fetchContentSlideList(id, true);

        let { categoryData } = this.props;

        await this.createDropdownOptions(categoryData);
      };

      // callback for createdata for select parent dropdown
      createDropdownOptions = (treeData = []) => {
        let dropDownOption = [];

        // create data for select parent dropdown
        let createDropdownData = treeData => {
          treeData.forEach(data => {
            dropDownOption = [...dropDownOption, data];

            if (Array.isArray(data.children) && data.children.length) {
              return createDropdownData(data.children);
            }
          });

          return dropDownOption;
        };

        let dropDownOptionsNew = createDropdownData(treeData);
        this.setState({ dropDownOptionsNew });
      };

      manageParentStates = ({
        propName,
        value,
        cb,
        resetCurrentSelectedSlide
      }) => {
        this.setState(
          {
            [propName]: value
          },
          () => {
            cb && cb();

            // reset current selected slide
            resetCurrentSelectedSlide && resetCurrentSelectedSlide.call(this);
          }
        );
      };

      // generate actions for repo manager
      generateButtonNodeList = rowInfo => {
        let {
          enable,
          label,
          createdAt,
          isRequired,
          _id,
          contentSlideCategory,
          indentThis,
          hideDelete
        } = rowInfo.node;

        if (createdAt)
          createdAt = new moment(createdAt).format("MM/DD/YYYY HH:mm");

        let hideDeleteButton = label !== "slide" && !hideDelete ? true : false;

        return [
          <SlideCount
            style={{
              opacity: enable ? 1 : 0.5
            }}
            className="created-date"
          >
            {createdAt || null}
          </SlideCount>,
          indentThis && (
            <StyledIcon
              enableLevel={enable}
              title="Indent"
              style={{
                opacity: enable ? 1 : 0.5
              }}
              onClick={() => {
                if (!this.state.isEditedFlag) this.indentSlide(rowInfo);
              }}
              className="indent"
            >
              <IndentIcon size={15} />
            </StyledIcon>
          ),
          hideDeleteButton && (
            <StyledIcon enableLevel={enable} title="Delete">
              <DeleteIcon
                size={15}
                onClick={() => {
                  if (!this.state.isEditedFlag) {
                    if (label !== "slide") this.deleteHandler(rowInfo, _id);
                  } else {
                    toastMessage();
                  }
                }}
              />
            </StyledIcon>
          ),
          <StyledIcon
            enableLevel={enable}
            title="Edit"
            onClick={() => {
              this.setState({
                activeTab: "slide"
              });
              if (!this.state.isEditedFlag) {
                if (label !== "slide") {
                  this.setParentDetails({}, true);
                  this.setState({
                    editInput: _id
                  });
                } else {
                  this.getParentCategories();
                  this.setState({
                    currentSelectedSlide: true,
                    editedSlide: rowInfo.node,
                    parentNode: rowInfo.parentNode
                  });
                }
              } else {
                toastMessage();
              }
            }}
          >
            <EditIcon size={15} />
          </StyledIcon>,
          <StyledIcon
            enableLevel={true}
            title={!enable ? "Disabled" : "Enabled"}
            onClick={() => {
              if (!this.state.isEditedFlag) {
                if (label !== "slide") {
                  this.enableHandler({
                    _id: _id,
                    payload: { enable: !enable }
                  });
                } else {
                  this.enableHandler({
                    _id: _id,
                    payload: { enable: !enable },
                    contentSlideCategory: contentSlideCategory
                  });
                }
              } else {
                toastMessage();
              }
            }}
          >
            {enable ? <PreviewIcon /> : <HiddenIcon />}
          </StyledIcon>,
          <StyledIcon
            enableLevel={enable}
            title={isRequired ? "Required" : "Not Required"}
            onClick={() => {
              if (!this.state.isEditedFlag) {
                if (label !== "slide") {
                  this.enableHandler({
                    _id: _id,
                    payload: { isRequired: !isRequired }
                  });
                } else {
                  this.enableHandler({
                    _id: _id,
                    payload: { isRequired: !isRequired },
                    contentSlideCategory: contentSlideCategory
                  });
                }
              } else {
                toastMessage();
              }
            }}
          >
            {isRequired ? (
              <RequiredIcon size={15} />
            ) : (
              <NotRequiredIcon size={15} />
            )}
          </StyledIcon>
        ];
      };

      checkIfElementCanBeDropped = ({
        node,
        nextParent,
        prevParent,
        nextTreeIndex,
        prevTreeIndex
      }) => {
        // Return the data describing the dragged item
        const { label: DraggedItemLabel } = node;
        const { _id: nextParentId, label: DroppedIteamParentLabel, children } =
          nextParent || {};
        const { _id: prevParentId, children: prevParentChildren } =
          prevParent || {};
        // to prevent dropping on group one another and single slide on group
        let canDrag = true,
          counter = 0,
          groupElem = [];

        // prevnt group getting drop if next parent contains duplicate slide
        Array.isArray(prevParentChildren) &&
          prevParentChildren.length &&
          each(prevParentChildren, elem => {
            if (node.group && elem.group && node.group._id === elem.group._id)
              groupElem.push(elem);
          });

        Array.isArray(children) &&
          children.length &&
          children.forEach((item, index) => {
            if (
              item.title === node.title ||
              find(groupElem, { title: item.title })
            ) {
              counter++;
            }
            if (item._id === node._id) {
              // check for existance of next previous slides to the dragged slide and it belongs to agroup
              let nextPrevSlides =
                children[index - 1] &&
                children[index + 1] &&
                children[index - 1].group &&
                children[index + 1].group;

              // check if current itterable slide and dragged slide is equal and next previous slide group is equal
              let isNextPrevSlidesEqual =
                nextPrevSlides &&
                children[index - 1].group._id === children[index + 1].group._id;

              // prevent dropping single slide on group
              if (isNextPrevSlidesEqual && !node.group) {
                canDrag = false;
              } else if (
                // prevent dropping one group slide onto another group
                isNextPrevSlidesEqual &&
                node.group &&
                children[index - 1].group._id !== node.group._id &&
                children[index + 1].group._id !== node.group._id
              ) {
                canDrag = false;
              }
            }
          });

        // check if element is getting dropped on its previoius position
        if (
          (prevParent &&
            nextParent &&
            prevParent._id === nextParent._id &&
            this.checkForOrderChange(nextParent.children, node)) ||
          (!nextParent && !prevParent && nextTreeIndex === prevTreeIndex)
        ) {
          return false;
        }

        // if duplicate avoid dropping
        if (counter > 1 && nextParentId !== prevParentId) return false;

        // check if slide can be dropped
        if (
          DraggedItemLabel === "slide" &&
          DroppedIteamParentLabel &&
          DroppedIteamParentLabel !== "slide" &&
          canDrag
        ) {
          return true;
        }

        // check if header/subheader can be dropped
        if (
          (DraggedItemLabel === "sub-header" ||
            DraggedItemLabel === "header") &&
          (!DroppedIteamParentLabel ||
            DroppedIteamParentLabel === "category" ||
            (DroppedIteamParentLabel === "header" &&
              this.getChildElement(node, ["sub-header"]))) &&
          canDrag
        ) {
          return true;
        }

        // check if category can be dropped
        if (
          DraggedItemLabel === "category" &&
          (!DroppedIteamParentLabel ||
            (DroppedIteamParentLabel === "header" &&
              this.getChildElement(node, ["sub-header", "header"])) ||
            (DroppedIteamParentLabel === "category" &&
              this.getCategoryChildElement(node))) &&
          canDrag
        ) {
          return true;
        }

        return false;
      };

      // check if element getting dropped on its previous position
      checkForOrderChange = (childrens, node) => {
        let flag = false;

        each(childrens, (item, index) => {
          if (item._id === node._id && node.order === index) {
            flag = true;
          }
        });

        return flag;
      };

      // get node depth count
      getChildElement = (node, arrayToCheck, catflag = false) => {
        let flag = true;
        let { children, label: nodeLabel } = node;

        if (Array.isArray(children) && children.length) {
          each(children, item => {
            let { label } = item;
            if (arrayToCheck.indexOf(label) > -1) {
              flag = false;
            }
          });
        } else if (nodeLabel === "sub-header" && catflag) {
          flag = false;
        }

        return flag;
      };

      // get node depth count on category node
      getCategoryChildElement = node => {
        let flag = true;
        let { children } = node;
        if (Array.isArray(children) && children.length) {
          each(children, elem => {
            if (Array.isArray(elem.children) && elem.children.length) {
              flag = this.getChildElement(elem, ["sub-header"], true);
            }
          });
        }

        return flag;
      };

      /**
       *  Function to handle addition of new slide
       *  @param {Object} data the data payload for post request
       *  @param {Function} onSuccess a callback function to be called after succesful update
       *
       */
      addNewSlide = () => {
        this.setState({
          editedSlide: {},
          currentSelectedSlide: true,
          parentNode: {}
        });
        this.getParentCategories();
        this.props.clearThemeData && this.props.clearThemeData();
      };

      setResetCategory = (value, flag = false) => {
        if (flag) {
          this.setState({
            categoryIdToEdit: value,
            editInput: value
          });
        } else {
          this.setState({
            categoryIdToEdit: value
          });
        }
      };

      setParentDetails = (details, flag) => {
        const { isEditedFlag } = this.state;

        if (!isEditedFlag || flag) {
          this.setState({
            selectedParentDetails: details
          });
          this.setResetCategory(null, true);
        } else {
          toastMessage();
        }
      };

      /**
       * category create api call
       *
       * @param {Object} payload
       * @param {Object} rowInfo row information
       */
      onSave = async (payload, rowInfo, categoryId) => {
        let newNode = rowInfo.node;
        let { title } = newNode;
        let response = null;
        if (categoryId) {
          response = await this.props.createContentSlideCategory(
            payload,
            this.props.match.params.contentRepoId,
            "Record has been updated successfully.",
            categoryId
          );
        } else {
          response = await this.props.createContentSlideCategory(
            payload,
            this.props.match.params.contentRepoId,
            "Record has been created successfully."
          );

          newNode = this.props.contentSlideHeirarchyData
            ? this.props.contentSlideHeirarchyData
            : newNode;
        }

        if (response && response.success) {
          if (categoryId) newNode.title = payload.title || title;

          let treeData = this.changeNodeAtSelectedPath(newNode, rowInfo);
          this.appendIndentIcon(treeData);
          this.hideDeleteButton(treeData);
          this.state.currentSelectedSlide && this.getParentCategories();
        }

        return response.success;
      };

      // add new node under specific parent
      addNewNode = () => {
        const { selectedParentDetails, treeData } = this.state;
        let level = 0,
          treeIndex = undefined,
          label = "category",
          newFocusIndex = getVisibleNodeCount({ treeData });

        // to check wether parent is selected if yes the add the node under that parent
        if (Object.keys(selectedParentDetails).length) {
          level = selectedParentDetails.level + 1;
          // to assign label to the added node
          if (level === 1) {
            label = "header";
          } else if (level === 2) {
            label = "sub-header";
          }

          // create index to set focus on newly added node
          let { children } = selectedParentDetails;
          let childrenLength =
            Array.isArray(children) &&
            children.length &&
            getVisibleNodeCount({ treeData: children });

          treeIndex = selectedParentDetails.treeIndex;
          newFocusIndex = treeIndex + childrenLength;
        }

        let id = Math.random() + 1;
        if (level <= 2) {
          this.setState(
            prevState => ({
              treeData: addNodeUnderParent({
                treeData: prevState.treeData,
                parentKey: treeIndex,
                expandParent: true,
                newNode: {
                  _id: id,
                  label,
                  title: "",
                  isRequired: true,
                  level,
                  enable: true,
                  isNewlyAdded: true
                },
                getNodeKey: ({ treeIndex }) => treeIndex
              }).treeData,
              newFocusIndex
            }),
            () => {
              // set the node id
              this.setResetCategory(id);
              this.resetNewFocusIndex({ key: "isEditedFlag", value: true });
            }
          );
        }
      };

      /**
       * Remove node from data at specified path
       *
       * @param {Object} rowInfo row information
       */
      removeNodeFromTree = rowInfo => {
        let { path } = rowInfo;

        this.setState({
          treeData: removeNodeAtPath({
            treeData: this.state.treeData,
            path: path, // You can use path from here
            getNodeKey: ({ node: TreeNode }) => {
              return TreeNode._id;
            },
            ignoreCollapsed: false
          }),
          editedSlide: {},
          currentSelectedSlide: false,
          selectedParentDetails: {}
        });
      };

      /**
       * Change treeData at given path
       *
       * @param {Object} newNode changed data from response
       * @param {Object} rowInfo row information
       */
      changeNodeAtSelectedPath = (newNode, rowInfo, flag) => {
        let path = null;
        if (flag) {
          path = rowInfo;
        } else {
          path = rowInfo.path;
        }

        this.setState(prevState => ({
          treeData: changeNodeAtPath({
            treeData: prevState.treeData,
            path, // You can use path from here
            newNode: newNode,
            getNodeKey: ({ node: TreeNode }) => {
              return TreeNode._id;
            }
          }),
          selectedParentDetails: {}
        }));

        // update node level
        let updateLevel = (treeSet, level = 0) => {
          each(treeSet, (slide, index) => {
            slide.level = level;
            slide.order = index;
            if (Array.isArray(slide.children) && slide.children.length) {
              updateLevel(slide.children, slide.level + 1);
            }
          });
        };

        updateLevel(this.state.treeData);

        if (!flag) return this.state.treeData;
      };

      handleTreeOnChange = treeData => {
        this.setState({
          treeData,
          updatedTreeData: JSON.parse(JSON.stringify(treeData))
        });
      };

      // to expand all the node
      toggleNodeExpansion = expanded => {
        this.setState(prevState => ({
          treeData: toggleExpandedForAll({
            treeData: prevState.treeData,
            expanded
          }),
          toggleExpand: !this.state.toggleExpand
        }));

        // to push/remove exapnded/collapsed node to selectedParent array
        if (expanded) {
          let { treeData } = this.state;
          // returns flat treeData
          let flattened = getFlatDataFromTree({
            treeData,
            getNodeKey: ({ node }) => node._id,
            ignoreCollapsed: false
          });

          // push node id's to selectedParent array
          Array.isArray(flattened) &&
            flattened.length &&
            each(flattened, rowInfo => {
              let { node } = rowInfo;
              // push unique id's
              if (this.selectedParentELem.indexOf(node._id) < 0)
                this.selectedParentELem.push(node._id);
            });
        } else {
          this.selectedParentELem = [];
        }
      };

      /**
       * Set flag to reorder slides within its group
       *
       * @param {Object} nextParentNode next parent of the dragged slide
       * @param {Object} node dragged slide
       * @returns {Booolean}
       */
      reorderWithinGroup = (nextParentNode, node) => {
        let reorderGroup = true;

        // set flag to allow reordering within group
        let { children } = nextParentNode;
        children.forEach((item, index) => {
          // next and previous slide
          let nextElement = children[index + 1],
            prevElement = children[index - 1];

          // check previous slide existance and is it belongs to group and compare group with dragged element group
          let checkPreviousElement =
            get(prevElement, "group._id") === node.group._id;

          // check next slide existance and is it belongs to group and compare group with dragged element group
          let checkNextElement =
            get(nextElement, "group._id") === node.group._id;

          // compare if dragged and current iterable slide id is same and dragged slide belongs to group
          let isElementFound = item._id === node._id && node.group;

          // Check if next and previous slide to the dragged slide exists and it belongs to group
          let isNextPreviousElement =
            get(prevElement, "group") && get(nextElement, "group");

          // reorder group slide in first and last index
          if (
            isElementFound &&
            ((!prevElement && checkNextElement) ||
              (!nextElement && checkPreviousElement))
          ) {
            reorderGroup = false;
          } else if (
            // reorder group slide if next and prev element is a single slide
            isElementFound &&
            ((prevElement && !prevElement.group && checkNextElement) ||
              (nextElement && !nextElement.group && checkPreviousElement))
          ) {
            reorderGroup = false;
          } else if (
            // reorder group slide within its group
            isElementFound &&
            isNextPreviousElement &&
            (prevElement.group._id === nextElement.group._id ||
              nextElement.group._id !== node.group._id ||
              prevElement.group._id !== node.group._id)
          ) {
            reorderGroup = false;
          }
        });

        return reorderGroup;
      };

      // handle when node is moved for category and modules
      onMoveNode = ({ treeData, node, nextParentNode }) => {
        let { updatedTreeData } = this.state,
          counter = 0;

        each(treeData, item => {
          if (item.title === node.title) counter++;
        });

        if (counter > 1) {
          this.setState({ treeData: updatedTreeData });
          return;
        }

        let prevParent = null,
          groupSlidesDetails = [];
        // to get entire group element and its previous parent
        walk({
          treeData: updatedTreeData,
          getNodeKey: ({ node: treeNode }) => treeNode._id,
          callback: rowInfo => {
            let { node: walkNode, parentNode } = rowInfo;
            let { group, _id } = walkNode;

            if (node._id === _id) {
              if (nextParentNode && !nextParentNode.enable)
                walkNode.enable = nextParentNode.enable;
              prevParent = parentNode;
            }
            if (group && node.group && group._id === node.group._id) {
              if (nextParentNode && !nextParentNode.enable)
                walkNode.enable = nextParentNode.enable;
              groupSlidesDetails.push(walkNode);
            }
          },
          ignoreCollapsed: false
        });

        // drag all the belonging group slides if one of them dragged
        if (
          node.group &&
          nextParentNode &&
          this.reorderWithinGroup(nextParentNode, node)
        ) {
          // to add/remove element in/from next/previous parent node

          walk({
            treeData,
            getNodeKey: ({ node: treeNode }) => treeNode._id,
            callback: rowInfo => {
              let { node: walkNode } = rowInfo;
              // remove group elements from previous parent
              if (prevParent && walkNode._id === prevParent._id) {
                walkNode.children = filter(walkNode.children, (elem, index) => {
                  if (
                    prevParent &&
                    prevParent._id === nextParentNode._id &&
                    elem._id === node._id
                  ) {
                    return elem;
                  } else if (!find(groupSlidesDetails, { _id: elem._id })) {
                    return elem;
                  }
                });
              }

              // add group elements to previous parent
              if (nextParentNode && walkNode._id === nextParentNode._id) {
                let childrenElems = [...nextParentNode.children];

                each(nextParentNode.children, (elem, index) => {
                  if (elem._id === node._id) {
                    childrenElems.splice(index, 1, ...groupSlidesDetails);
                  }
                });
                walkNode.children = [...childrenElems];
              }
            },
            ignoreCollapsed: false
          });
        }

        // update node level
        let updateLevel = (treeSet, level = 0) => {
          each(treeSet, (slide, index) => {
            slide.level = level;
            slide.order = index;
            if (Array.isArray(slide.children) && slide.children.length) {
              updateLevel(slide.children, slide.level + 1);
            }
          });
        };

        updateLevel(treeData);

        let dataToReorder = nextParentNode ? nextParentNode : treeData;
        this.reorderContentSlides(
          dataToReorder,
          treeData,
          node,
          nextParentNode,
          prevParent
        );

        this.setState({
          treeData,
          updatedTreeData: JSON.parse(JSON.stringify(treeData))
        });
      };

      reorderContentSlides = async (
        reorderData,
        treeData,
        node = {},
        nextParentNode = {},
        prevParent = {}
      ) => {
        let payload = {},
          childrenArray = reorderData;

        // if reorderData is not a array of object
        if (!Array.isArray(reorderData) && get(nextParentNode, "_id")) {
          payload.nextParent = reorderData._id;
          childrenArray = reorderData.children;
        } else {
          childrenArray = !Array.isArray(reorderData)
            ? reorderData.children
            : reorderData;
        }

        // payload
        payload.children = map(childrenArray, item => {
          let { _id, title, label, group } = item;

          return {
            _id,
            title,
            type: label === "slide" ? label : "category",
            ...(get(nextParentNode, "_id") !== get(prevParent, "_id")
              ? node._id === _id ||
                (node.group && node.group._id === (group || {})._id)
                ? { isReordered: true }
                : {}
              : {}) //condtion to add isReorder flag if parent changes.
          };
        });

        let response = await this.props.contentSlideReorder(
          payload,
          this.props.match.params.contentRepoId
        );

        if (response && response.success) {
          // update label based on level
          walk({
            treeData,
            getNodeKey: ({ node: treeNode }) => treeNode._id,
            callback: rowInfo => {
              let { node, parentNode } = rowInfo;
              switch (node.level) {
                case 0:
                  node.label = "category";
                  break;
                case 1:
                  node.label = node.label !== "slide" ? "header" : node.label;
                  break;
                case 2:
                  node.label =
                    node.label !== "slide" ? "sub-header" : node.label;
                  break;
                default:
                  node.label = "slide";
              }

              if (node.label !== "slide") {
                node.parent = parentNode ? parentNode._id : null;
              } else {
                node.contentSlideCategory = parentNode._id;
              }

              if (
                node.expanded &&
                this.selectedParentELem.indexOf(node._id) < 0
              )
                this.selectedParentELem.push(node._id);
            },
            ignoreCollapsed: false
          });

          this.setState(
            {
              treeData
            },
            () => {
              this.appendIndentIcon(treeData);
              this.hideDeleteButton(treeData);
            }
          );
        }
      };

      /**
       * Function to handle the enable and disable state of the content sldie
       * @param {String} Id of the content Slide
       * @param {Boolean} Value of the content Slide toggle state
       */
      enableHandler = async ({
        _id: contentSlideId,
        payload,
        contentSlideCategory: contentSlideCategoryId
      }) => {
        let response = null;

        let contentRepoId =
          (this.props.match.params && this.props.match.params.contentRepoId) ||
          "";

        let message = "Record has been updated successfully.";

        if (!contentSlideCategoryId) {
          response = await this.props.createContentSlideCategory(
            payload,
            contentRepoId,
            message,
            contentSlideId
          );
        } else {
          response = await this.props.updateContentSlide(
            contentRepoId,
            contentSlideCategoryId,
            contentSlideId,
            payload,
            message
          );
        }

        if (response && response.success) {
          await this.fetchContentSlides();
        }
      };

      // change focus index edited flag and category title
      resetNewFocusIndex = ({ key, value }) => {
        this.setState({
          [key]: value
        });
      };

      // delete content slide
      deleteHandler = async (rowInfo, contentSlideId) => {
        let contentRepoId =
          (this.props.match.params && this.props.match.params.contentRepoId) ||
          "";
        DeleteConfirmationAlert({
          onYesClick: async () => {
            let response = await this.props.deleteContentSlideCategory(
              contentRepoId,
              contentSlideId
            );

            if (response && response.success) {
              this.resetNewFocusIndex({
                key: "categoryTitle",
                value: ""
              });
              this.removeNodeFromTree(rowInfo);
            }
          }
        });
      };

      // update treeData whenever slide is added/removed to group
      updateGroupOnContentSlideList = (slideId, flag, groupData) => {
        let { treeData } = this.state;

        // iterate through treeData to make any changes
        walk({
          treeData,
          getNodeKey: ({ node: treeNode }) => treeNode._id,
          callback: rowInfo => {
            let { node, parentNode } = rowInfo;

            if (slideId === node._id) {
              let groupTitle = null;
              if (!groupData) {
                groupTitle = node.group;
              }

              // group response when slide is added
              node.group = groupData;

              let childrens = [...parentNode.children];
              each(parentNode.children, (elem, index) => {
                if (elem._id === slideId && flag) {
                  // add new element to a group
                  childrens.splice(index, 1, node);
                } else if (elem._id === slideId && !groupData && groupTitle) {
                  // delete the group
                  childrens = this.reArrangeGroup(
                    [...childrens],
                    groupTitle,
                    node,
                    index
                  );
                } else if (elem._id === slideId && groupData) {
                  // add the group under first element of group
                  childrens = this.reArrangeGroup(
                    [...childrens],
                    node.group,
                    node,
                    index
                  );
                }
              });

              // assing changed childrens after group is added/deleted
              parentNode.children = childrens;

              this.expandSelectedSlideParent(treeData, rowInfo.path);
            }
          },
          ignoreCollapsed: false
        });

        this.setState({ treeData });
      };

      //re-arrange group
      reArrangeGroup = (childrens, group, node, index) => {
        childrens.splice(index, 1);
        let indexNew = findLastIndex(childrens, {
          group: { title: group.title }
        });

        childrens.splice(indexNew + 1, 0, node);

        return childrens;
      };

      // set focus on group category when group is changed from dropdown or group is deleted
      setFocusOnGroupDropdownChange = (id, groupId) => {
        let { treeData } = this.state;
        // iterate through treeData to make any changes
        walk({
          treeData,
          getNodeKey: ({ node: treeNode }) => treeNode._id,
          callback: rowInfo => {
            let { node } = rowInfo;
            let { _id, group } = node;
            if (groupId && group && group._id === groupId) node.group = null;

            if (_id === id)
              this.expandSelectedSlideParent(treeData, rowInfo.path);
          },
          ignoreCollapsed: false
        });

        this.setState({ treeData });
      };

      // expands parent elements of content slides/category
      expandSelectedSlideParent = (treeData, path) => {
        // expand parent node
        let newPath = [...path],
          counter = path.length - 1;

        // get path of parent nodes one by one and expand
        each(path, elem => {
          if (counter >= 0) {
            let returnedNode = getNodeAtPath({
              treeData,
              path: newPath,
              getNodeKey: ({ node: TreeNode }) => {
                return TreeNode._id;
              }
            });

            //  to bring focus index when slide is added to
            if (counter === path.length - 1) {
              let newFocusIndex =
                returnedNode.treeIndex - 2 === 0
                  ? 1
                  : returnedNode.treeIndex - 2;
              this.resetNewFocusIndex({
                key: "newFocusIndex",
                value: newFocusIndex
              });
            }

            // expand the parent node of slide which is added to group
            if (
              Array.isArray(returnedNode.node.children) &&
              returnedNode.node.children.length
            )
              returnedNode.node.expanded = true;

            // remove path from the array whose node is fetched
            newPath.splice(counter, 1);
            counter--;
          }
        });
      };

      //add flag to hide delete button if slide exist
      hideDeleteButton = treeData => {
        // iterate through treeData to make any changes
        walk({
          treeData,
          getNodeKey: ({ node: treeNode }) => treeNode._id,
          callback: rowInfo => {
            let { node } = rowInfo;
            let { children } = node;
            node.hideDelete = false;
            let flag = false;

            let recusiveFunc = cSlides => {
              each(cSlides, elem => {
                if (elem.label === "slide") flag = true;
                if (Array.isArray(elem.children) && elem.children.length) {
                  recusiveFunc(elem.children);
                }
              });
            };

            if (Array.isArray(children) && children.length)
              recusiveFunc(children);

            node.hideDelete = flag;
          },
          ignoreCollapsed: false
        });

        this.setState({
          treeData
        });
      };

      // add indent icon to slides if it is placed directly under category
      appendIndentIcon = treeData => {
        walk({
          treeData,
          getNodeKey: ({ node: treeNode }) => treeNode._id,
          callback: rowInfo => {
            let { node, parentNode } = rowInfo;
            let { label: nodeLabel, title } = node;
            let { label } = parentNode || {};
            node.indentThis = false;

            if (label === "category" && nodeLabel === "slide") {
              node.indentThis = true;
            } else if (
              nodeLabel === "header" &&
              title !== "[No Subheader Displayed]" &&
              this.getChildElement(node, ["sub-header"])
            ) {
              node.indentThis = true;
            }
          },
          ignoreCollapsed: false
        });

        this.setState({
          treeData
        });
      };

      // indent slide on indent button click
      indentSlide = async rowInfo => {
        let { node } = rowInfo;
        let { _id, label, contentSlideCategory } = node;
        let response = null,
          payload = { indent: true },
          message = "Record has been updated successfully.";

        if (label === "slide") {
          response = await this.props.updateContentSlide(
            this.props.match.params.contentRepoId,
            contentSlideCategory,
            _id,
            payload,
            message
          );
        } else {
          response = await this.props.createContentSlideCategory(
            payload,
            this.props.match.params.contentRepoId,
            message,
            _id
          );
        }

        if (response && response.success) await this.fetchContentSlides();
      };

      /**
       * Handle repo search
       * Source logic to handle repo search was in under repo manager
       */
      handleRepoSearch = e => {
        if (!this.state.isEditedFlag) {
          this.setState({
            searchQueryString: e.target.value
          });
        } else {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please save your changes."
          });
        }
      };

      resetRightPanelForm = () => {
        this.setState({
          displayFiltersList: false,
          selectedFilters: [],
          selectedFilterCategory: ""
        });
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        };

        return <Main {...stateMethodProps} />;
      }
    }
  );
export default Container;

import React from "react";
import styled from "styled-components";

//components
import Button, { SecondaryButton } from "components/button";
import hexToRgba from "utils/hexToRgba";
import Container from "./container.jsx";
import AnnouncementContent from "./components/announcementContent";
import ViewMore from "./components/viewMore";
import { get } from "lodash";

const AnnouncementPopup = props => {
  let {
    onBellIconClick,
    isAnnouncementLoading,
    showAll,
    userProfileMeta,
    showAllAnnouncement,
    manageAnnouncementsHandler,
    announcementList
  } = props;

  let isShowManageAnnouncements =
    get(userProfileMeta, `roles`, []).indexOf("PG-System-Admin") !== -1;

  let announcementDataList = get(announcementList, "data.announcements");

  return (
        <>
          {showAll ? (
            <ViewMore {...props} />
          ) : (
            <AnnouncementPopupWrapper>
              <Announcement onClick={onBellIconClick} />
              <AnnouncementContentContainer>
                <Title>Announcements</Title>
                <SettingsContent>
                  <AnnouncementContent {...props} />
                  {isAnnouncementLoading && (
                    <NoAnnouncementLoader>Loading...</NoAnnouncementLoader>
                  )}
                  <ButtonContainer>
                    {announcementDataList && announcementDataList.length ? (
                      <SecondaryButton
                        text="View More"
                        width="100%"
                        float="none"
                        onClick={showAllAnnouncement}
                        buttonClass="view-more"
                      />
                    ) : (
                      !isAnnouncementLoading && (
                        <NoAnnouncement>No Announcements</NoAnnouncement>
                      )
                    )}

                    {isShowManageAnnouncements && (
                      <Button
                        text="Manage Announcements"
                        width="100%"
                        float="none"
                        onClick={manageAnnouncementsHandler}
                      />
                    )}
                  </ButtonContainer>
                </SettingsContent>
              </AnnouncementContentContainer>
            </AnnouncementPopupWrapper>
          )}
        </>
  );
};

const AnnouncementPopupWrapper = styled.div`
  display: inline-block;
  width: 402px;
  border-radius: 2px;
  margin-top: 23px;
  position: absolute;
  z-index: 26;
  right: 101px;
`;

const Announcement = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => hexToRgba(props.theme.COLOR.BLACK, 0.36)};
  z-index: 10;
`;

const Title = styled.h3`
  padding: 19px 6.9%;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.14);
  color: ${props => props.theme.COLOR.MAIN};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 16px;
  font-weight: bold;
`;

const AnnouncementContentContainer = styled.div`
  width: 100%;
  border-radius: 2px;
  margin-top: 8px;
  position: absolute;
  z-index: 11;
  right: 0;
  background-color: ${props => props.theme.COLOR.WHITE};

  &::before {
    content: "";
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 11px solid ${props => props.theme.COLOR.WHITE};
    position: absolute;
    top: -11px;
    right: 7px;
  }
`;

const SettingsContent = styled.div``;

const ButtonContainer = styled.div`
  button {
    height: 54px;
    border-radius: 0;
    font-size: 14px;
  }
  .view-more {
    box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.07);
    border: 1px solid transparent;
  }
`;

const NoAnnouncementLoader = styled.h3`
  padding: 19px 0;
  color: ${props => props.theme.COLOR.MAIN};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 12px;
  text-align: center;
`;

const NoAnnouncement = styled.h3`
  padding: 19px 0;
  color: ${props => props.theme.COLOR.MAIN};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 16px;
  text-align: center;
  font-weight: bold;
`;

export default Container(AnnouncementPopup);

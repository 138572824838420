import React, { PureComponent } from "react";
import styled from "styled-components";
import { get, filter, trim } from "lodash";
import ValidationUtils from "utils/ValidationUtils";
import PollingUtils from "utils/PollingUtils";
import ToastUtils from "utils/handleToast";
import { connect } from "react-redux";

//components
import { Delete, Show, Hidden } from "assets/icons";
import { mapStateToProps, actions } from "./mapStateToProps";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";

const UI_STRINGS = {
  DELETE_THEME:
    "Do you want to remove this template from existing presentations?",
  EMPTY_FIELD_ERROR_MESSAGE: "This field is required.",
  SPECIAL_CHAR_ERROR_MESSAGE: "Please do not enter the special character.",
  WHITE_SPACE_ERROR_MESSAGE: "Please enter a valid input.",
  UNSAVED_PROMPT_ERROR:
    "You haven't saved your progress. Hitting refresh or back will lose your work."
};

const Container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class SortableTree extends PureComponent {
      constructor(props) {
        super(props);
        this.state = {
          treeData: [],
          showThemePreview: false,
          activeThemeDetails: {},
          disableUploadButton: false,
          validFileStatus: null,
          themeNameError: ""
        };

        this.themeNameRef = React.createRef();
      }

      componentDidMount() {
        window.addEventListener("beforeunload", this.checkIfThemeUploading);

        this.fetchThemeList();
        this.getContentRepoName();
      }

      checkIfThemeUploading = e => {
        if (this.state.disableUploadButton) {
          e.preventDefault();
          e.returnValue = UI_STRINGS.UNSAVED_PROMPT_ERROR;
        } else {
          return null;
        }
      };

      componentWillUnmount() {
        window.removeEventListener("beforeunload", this.checkIfThemeUploading);
        PollingUtils.stopPolling();
      }

      // manage valid file status
      manageValidFileStatus = obj => {
        this.setState(obj);
      };

      fetchThemeList = async () => {
        await this.props.getThemeList(this.props.match.params.contentRepoId);

        this.setState({
          treeData: this.props.themeList || {}
        });
      };

      getContentRepoName = () => {
        this.props.getContentRepoName(this.props.match.params.contentRepoId);
      };

      /**
       * Function to handle Drag drop changes
       * @param {Object} This will have the updated tree object of the data
       */
      handleTreeOnChange = treeData => {
        this.setState(
          {
            treeData
          },
          () => this.changeThemeOrder(treeData)
        );
      };

      /**
       * Reorder themes after drag and drop
       * @param {array} themes
       */
      changeThemeOrder = async themes => {
        let themeIds = Array.isArray(themes)
          ? themes.map(({ _id }) => _id)
          : [];
        themeIds.length &&
          (await this.props.reorderThemes(
            this.props.match.params.contentRepoId,
            { themeIds }
          ));
      };

      /**
       * Function to generate the icons that are required on the drag drop container
       */
      generateButtonNodeList = rowInfo => {
        let { enable, _id: themeId } = rowInfo;
        let message = `Theme has been successfully ${
          !enable ? "enabled" : "disabled"
        }.`;

        return [
          <DeleteStyledIcon
            onClick={() => this.deleteDeactivateTheme(themeId, enable)}
            title="Delete"
          >
            <DeleteIcon />
          </DeleteStyledIcon>,
          <StyledIcon
            title={enable ? "Enabled" : "Disabled"}
            onClick={() =>
              this.saveEditedThemeDetails({ enable: !enable }, themeId, message)
            }
          >
            {enable ? <Show size={15} /> : <Hidden size={15}></Hidden>}
          </StyledIcon>
        ];
      };

      /**
       * Delete or deactivate a theme
       */
      deleteDeactivateTheme = (themeId, enable) => {
        let { contentRepoId } = this.props.match.params;
        DeleteConfirmationAlert({
          message: UI_STRINGS.DELETE_THEME,
          note: `Clicking on "Yes" will delete the theme permanently and remove from the existing presentations.
          ${
            enable
              ? '<br/> Clicking on "No" will disable the theme and keep them in existing presentations.'
              : ""
          }
          `,
          onYesClick: async () => {
            await this.props.deleteOrDeactivateTheme(
              contentRepoId,
              themeId,
              "deleted"
            );

            this.fetchThemeList();
          },
          onNoClick: async (flag = true) => {
            let msg = `Theme has been successfully disabled`;
            flag &&
              enable &&
              this.saveEditedThemeDetails({ enable: false }, themeId, msg);
          }
        });
      };

      /**
       * Function to handle the preview Modal of the theme
       */
      _handleTitleClick = selectedId => {
        let themeList = this.props.themeList || {};
        let activeThemeData = filter(themeList, eachTheme => {
          return eachTheme._id === selectedId;
        });

        this.setState({
          showThemePreview: true,
          activeThemeDetails: activeThemeData[0]
        });
      };

      /**
       * Function to handle the Modal Close state
       */
      handleModal = () => {
        this.setState({
          showThemePreview: false
        });
      };

      /**
       * function to save edited data
       * @param {Object} body data to be posted
       * @param {String} themeId current edited theme
       */
      saveEditedThemeDetails = async (body, themeId, message) => {
        let contentRepositoryId = get(this.props.match.params, `contentRepoId`);

        await this.props.saveTheme(contentRepositoryId, themeId, body, message);
        this.fetchThemeList();
      };

      handleFileChange = () => {
        let themeNameError = this.handleTextValidation(
          this.themeNameRef.current.value
        );

        this.setState({
          themeNameError
        });

        if (themeNameError) {
          return true;
        }
        return false;
      };

      /**
       *
       * @param {Object} { metaDeta, file } metaData of the file to be uploaded, original file
       * @param {*} cb callback function to clear the file drop zone
       */
      uploadFileToRepo = async ({ metaDeta, file }, cb) => {
        metaDeta.title = trim(get(this.themeNameRef, `current.value`));
        delete metaDeta.resource;

        this.manageValidFileStatus({ disableUploadButton: true });

        // post metaData to get presigned url and ingestID
        await this.props.createTheme(
          this.props.match.params.contentRepoId,
          metaDeta
        );

        let { themePresignedIngestDetails } = this.props || {};

        if (!Object.keys(themePresignedIngestDetails).length) {
          this.manageValidFileStatus({ disableUploadButton: false });
          cb && cb();
          return;
        }

        const { presignedUrl, ingestId } = themePresignedIngestDetails || {};

        // use presigned url to upload file to aws
        await this.props.uploadThemeToAws(presignedUrl, file);

        PollingUtils.startPolling({
          pollingAction: () => {
            // start polling using ingestId
            this.pollingActionForUpload({ id: ingestId, cb });
          },
          timeoutDuration: 600000, // 10 min
          timeoutCallback: () => {
            this.props.stopThemeUpload();
            PollingUtils.stopPolling();
            ToastUtils.handleToast({
              operation: "error",
              message: "File upload is taking too long. Please try again later."
            });
          }
        });
      };

      pollingActionForUpload = async ({ id, cb }) => {
        this.props.getFilePollingStatus &&
          (await this.props.getFilePollingStatus(id));
        let { filePollingStatus } = this.props || {};

        if (get(filePollingStatus, `data.status`) === "Completed") {
          // stop polling if status is completed
          PollingUtils.stopPolling();
          this.setState({ disableUploadButton: false });

          ToastUtils.handleToast({
            operation: "success",
            message: "Theme has been successfully uploaded."
          });
          this.fetchThemeList();
          this.themeNameRef.current.value = "";

          cb && cb();
        } else if (
          // stop request polling if service status is not authenticated or status is failed
          get(filePollingStatus, `status` !== 200) ||
          get(filePollingStatus, `data.status`) === "Failed"
        ) {
          this.setState({ disableUploadButton: false });

          PollingUtils.stopPolling();
          cb && cb();
          ToastUtils.handleToast({
            operation: "error",
            message: get(filePollingStatus, `data.message`)
          });
        }
      };

      handleTextValidation = value => {
        if (ValidationUtils.checkIfEmptyField(value)) {
          return UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfspecialChar(value)) {
          return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(value)) {
          return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
        } else {
          return null;
        }
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props,
          handleTitleClick: this._handleTitleClick,
          handleModal: this.handleModal
        };

        return <Main {...stateMethodProps} />;
      }
    }
  );

const DeleteIcon = styled(Delete)`
  width: 14px;
  height: 15px;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const DeleteStyledIcon = styled.span`
  cursor: pointer;
`;

export default Container;

import React from "react";
import styled, { css } from "styled-components";
import { QuestionIcon } from "assets/icons";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";

const ReactHint = ReactHintFactory(React);
/**
 * render hint on hover
 */
const onRenderContent = (target, content) => {
  return (
    <HintContainer className="custom-hint__content">
      <HintContent>{content}</HintContent>
    </HintContainer>
  );
};

const SelectButtons = props => {
  let { matchModules, handleCompatibleDocumentClick } = props;

  return (
    <SelectButtonsContainer>
      <ReactHint events onRenderContent={onRenderContent} />
      <SelectButtonsHolder>
        {matchModules &&
          matchModules.map(function(btn, i) {
            return (
              <SelectButton key={i} currentButton={btn}>
                <Button
                  title={btn}
                  onClick={() => handleCompatibleDocumentClick(btn)}
                >
                  {btn}
                </Button>
                <IconWrapper>
                  <StyledInfoIcon
                    size={24}
                    data-rh={"Best fit document types to help you get started"}
                    data-rh-at="right"
                  />
                </IconWrapper>
              </SelectButton>
            );
          })}
      </SelectButtonsHolder>
    </SelectButtonsContainer>
  );
};

const SelectButtonsContainer = styled.div``;

const SelectButtonsHolder = styled.div`
  margin-bottom: 40px;
`;

const PlacematButton = css`
  background-color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};

  &:hover {
    color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};
    background-color: ${props => props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR_PALLETE.LIPSTICK};
  }
`;

const FlierButton = css`
  background-color: ${props => props.theme.COLOR_PALLETE.APPLE_GREEN};

  &:hover {
    color: ${props => props.theme.COLOR_PALLETE.APPLE_GREEN};
    background-color: ${props => props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR_PALLETE.APPLE_GREEN};
  }
`;

const BrochureButton = css`
  background-color: ${props => props.theme.COLOR_PALLETE.GOLDEN};

  &:hover {
    color: ${props => props.theme.COLOR_PALLETE.GOLDEN};
    background-color: ${props => props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR_PALLETE.GOLDEN};
  }
`;

const SelectButton = styled.div`
  display: block;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  button {
    ${props =>
      props.currentButton === "Select Placemat"
        ? PlacematButton
        : props.currentButton === "Select Flier"
        ? FlierButton
        : BrochureButton}
  }
`;

const Button = styled.button`
  width: 130px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  text-align: center;
  color: ${props => props.theme.COLOR.WHITE};
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
  transition: all 0.5s ease 0s;
  outline: none;
`;

const IconWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const StyledInfoIcon = styled(QuestionIcon)`
  color: ${props => props.theme.COLOR.MAIN};
`;

const HintContent = styled.span`
  color: ${props => props.theme.COLOR.WHITE};
`;

const HintContainer = styled.div`
  width: 90%;
  padding: 10px;
  background-color: ${props => props.theme.COLOR.BLACK};
  border-radius: 4px;
`;

export default SelectButtons;

import React, { Component } from "react";

import ToastUtils from "utils/handleToast";
import ValidationUtils from "utils/ValidationUtils";
import featureFlags from "utils/featureFlags.js";
import { get } from "lodash";

const UI_STRINGS = {
  EMPTY_FIELD_ERROR_MESSAGE: "This field is required.",
  SPECIAL_CHAR_ERROR_MESSAGE: "Please do not enter the special character.",
  WHITE_SPACE_ERROR_MESSAGE: "Please enter a valid input."
};

const container = Main =>
  class Container extends Component {
    state = {
      designData: {
        siteData: {
          type: "text",
          label: "Site Name (This appears as the website title in the browser)",
          error: "",
          value: "",
          id: "siteName"
        },
        descriptionText: {
          type: "text",
          label: "Site Description (This appears on the landing page)",
          error: "",
          value: "",
          id: "desc"
        },

        logo: {
          type: "file",
          label: "Logo",
          original: {
            location: "",
            dataUri: ""
          },
          cropped: {
            location: "",
            dataUri: ""
          },

          dimensions: {
            width: 0,
            height: 0,
            x: 0,
            y: 0
          },
          isEdited: false,
          error: "",
          showDefaultFile: false
        },

        heroImage: {
          type: "file",
          label: "Hero Image",
          isDefaultImage: false,
          original: {
            location: "",
            dataUri: ""
          },
          cropped: {
            location: "",
            dataUri: ""
          },
          dimensions: {
            width: 0,
            height: 0,
            x: 0,
            y: 0
          },
          isEdited: false,
          error: "",
          showDefaultFile: false
        },

        primaryColorData: {
          type: "color",
          label: "Primary Color (Dark)",
          error: "",
          value: "#fefefe",
          id: "primary"
        },
        secondaryColorData: {
          type: "color",
          label: "Secondary Color (Light)",
          error: "",
          value: "",
          id: "secondary"
        }
      },
      isDataChange: false
    };

    componentDidMount() {
      this.props.systemConfigurationData && this._updateDesignData();
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.systemConfigurationData !== this.props.systemConfigurationData
      ) {
        this._updateDesignData();
      }
    }

    /**
     * Function to update the design data
     */

    _updateDesignData = () => {
      let designData = { ...this.state.designData };

      //get values from the api
      let { color, companyLogo, companyHeroImage, siteName, description } =
        this.props.systemConfigurationData || {};

      let {
        logo,
        heroImage,
        siteData,
        descriptionText,
        primaryColorData,
        secondaryColorData
      } = designData;

      siteData.value = siteName || "";
      descriptionText.value = description || "";
      primaryColorData.value = color.primaryColor || "";
      secondaryColorData.value = color.secondaryColor || "";

      logo.original.location = get(companyLogo, "original.location") || "";
      logo.cropped.location = get(companyLogo, "cropped.location") || "";
      logo.dimensions = { ...companyLogo.dimensions } || {};

      heroImage.original.location = get(companyHeroImage, "isDefaultImage")
        ? get(companyHeroImage, "defaultImage.location")
        : get(companyHeroImage, "original.location") || "";
      heroImage.dimensions = { ...companyHeroImage.dimensions } || {};
      heroImage.cropped.location =
        get(companyHeroImage, "cropped.location") || "";
      heroImage.isDefaultImage = get(companyHeroImage, "isDefaultImage");

      //assign value back to the main object
      designData["siteData"] = siteData;
      designData["descriptionText"] = descriptionText;
      designData["primaryColorData"] = primaryColorData;
      if (!featureFlags.presentation.hideSecondaryColor) {
        designData["secondaryColorData"] = secondaryColorData;
      }
      designData["logo"] = logo;
      designData["heroImage"] = heroImage;

      this.setState({
        designData,
        isDataChange: false
      });
    };

    /**
     *  Function to update the value for the data inputs
     * @param {Object} This will have an object of value and id of the Input
     */
    _handleInputChange = ({ value, id }) => {
      let designData = { ...this.state.designData };

      let updatedData = {};

      Object.keys(designData || {}).map(input => {
        let item = designData[input];

        if (
          (item.id === id && id !== "secondary") ||
          (id === "secondary" && !featureFlags.presentation.hideSecondaryColor)
        ) {
          item.value = value;
          item.error = this._handleDesignValidation(value, item.type) || "";
        }
        return (updatedData[input] = item);
      });
      designData = updatedData;

      this.setState({
        designData,
        isDataChange: true
      });
    };

    /**
     * Function to validate the input data
     * @param {String} will have the value of an input field
     */
    _handleDesignValidation = (value = "") => {
      if (ValidationUtils.checkIfEmptyField(value)) {
        return UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfWhiteSpace(value)) {
        return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
      } else if (ValidationUtils.checkIfspecialChar(value)) {
        return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
      }
    };

    /**
     * Function to update Image data on Edit
     * @param {String} will have the Data Uri of an image
     * @param {Object} This will have the dimensions of the cropped image
     * @param {String} Name of the changed input field
     */

    handleEditImage = (img, data, input) => {
      if (data) {
        let designData = { ...this.state.designData };
        let imageData = designData[input];

        //get data uri
        imageData["cropped"].dataUri = img;

        imageData.isEdited = true;

        //update the dimension
        let dimension = imageData["dimensions"];
        dimension.width = data.width || 0;
        dimension.height = data.height || 0;
        dimension.x = data.left || 0;
        dimension.y = data.top || 0;
        imageData["dimensions"] = dimension;

        //reassign to the main object
        designData[input] = imageData;
        imageData["showDefaultFile"] = false;

        this.setState({
          designData,
          isDataChange: true
        });
      }
    };

    /**
     * Function to update Image data on new file Upload
     * @param {Array} will have the Default File Array
     * @param {String} Name of the changed input field
     */
    onFileUpload = async (file, input) => {
      let designData = { ...this.state.designData };

      let imageData = designData[input] || {};

      if (input === "heroImage") {
        imageData["isDefaultImage"] = false;
      }

      imageData["original"].location = (file.length && file[0].preview) || "";

      imageData.isEdited = true;

      //get data uri of image uploaded
      let dataUri = await this._getDataUri(file);
      imageData["original"].dataUri = dataUri;

      //reset data
      imageData["cropped"].dataUri = "";
      imageData["cropped"].location = "";

      //update the dimension
      let dimension = imageData["dimensions"];
      dimension.width = 1000;
      dimension.height = 1000;
      dimension.x = 1000;
      dimension.y = 1000;
      imageData["dimensions"] = dimension;
      imageData["error"] = "";

      imageData["showDefaultFile"] = false;

      this.setState({
        designData,
        isDataChange: true
      });
    };

    /**
     * Function to get the data URI of the file uploaded
     * @param {Array} Array of the file uploaded
     */
    _getDataUri = async file => {
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      const result = await new Promise((resolve, reject) => {
        reader.onload = function() {
          resolve(reader.result);
        };
      });
      return result;
    };

    /**
     * Callback on the change of the colour picker
     * @param {String} Hex valu of the color selected
     * @param {Object} Input field of the which the color has changed
     */
    handleColorChange = (value, input) => {
      let designData = { ...this.state.designData };
      designData[input].value = value || "";

      this.setState({
        designData,
        isDataChange: true
      });
    };

    /**
     * Function to save the Design data
     */

    _saveButtonHandler = async () => {
      if (!this.state.isDataChange) {
        return;
      }

      let designData = { ...this.state.designData };
      let error = this._checkError(designData);
      if (error) {
        ToastUtils.handleToast({
          operation: "error",
          message: "Please fill all the fields"
        });
        return;
      }

      let {
        logo,
        heroImage,
        siteData,
        descriptionText,
        primaryColorData,
        secondaryColorData
      } = designData;

      //get color detaols
      let colorData = {
        primaryColor: primaryColorData.value,
        ...(!featureFlags.presentation.hideSecondaryColor && {
          secondaryColor: secondaryColorData.value
        })
      };

      //get the Company logo details
      let logoDimensions = logo["dimensions"] || {};
      for (let key in logoDimensions) {
        //set default to 1000 to cover complete image if the image is not cropped
        logoDimensions[key] =
          typeof logoDimensions[key] !== "string" ? logoDimensions[key] : 1000;
      }

      let heroImageDimension = heroImage["dimensions"] || {};
      for (let key in heroImageDimension) {
        //set default to 1000 to cover complete image if the image is not cropped
        heroImageDimension[key] =
          typeof heroImageDimension[key] !== "string"
            ? heroImageDimension[key]
            : 1000;
      }

      let logoDetails = {
        dimensions: logo["dimensions"] || {},
        ...(logo["original"]["dataUri"] && {
          original: {
            imageData: logo["original"]["dataUri"]
          }
        }),
        ...(logo["cropped"]["dataUri"] && {
          cropped: {
            imageData: logo["cropped"]["dataUri"]
          }
        })
      };

      //get the Company Hero image details
      let heroImageDetails = {
        dimensions: heroImage["dimensions"] || {},

        ...(heroImage["original"]["dataUri"] && {
          original: {
            imageData: heroImage["original"]["dataUri"]
          }
        }),
        ...(heroImage["cropped"].dataUri && {
          cropped: {
            imageData: heroImage["cropped"]["dataUri"]
          }
        }),
        isDefaultImage: heroImage["isDefaultImage"]
      };

      //api body
      let apiBody = {
        siteName: siteData.value.trim() || "",
        description: descriptionText.value.trim() || "",
        color: colorData || {},
        ...(logo["isEdited"] && { companyLogo: logoDetails || {} }),
        ...(heroImage["isEdited"] && {
          companyHeroImage: heroImageDetails || {}
        })
      };

      //save data
      let response = await this.props.saveSystemConfigurationData(apiBody);
      //update the primary & the secondary color
      response.success &&
        this.props.getClientConfig &&
        (await this.props.getClientConfig());

      //update the uploaded image on data saved
      this._updateDesignData();

      this.setState({
        designData
      });
    };

    /**
     * Function to check Errors
     * @param {Object} Updated data of the form
     */
    _checkError = designData => {
      let error = false;

      Object.keys(designData).forEach(input => {
        let dataValue = designData[input];
        if (dataValue.type === "file" && dataValue.location === "") {
          error = true;
          dataValue.error = UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;

          this.setState({
            designData
          });
        } else if (
          dataValue.type === "text" &&
          !dataValue.value.trim().length
        ) {
          this._handleInputChange({
            value: "",
            id: dataValue.id
          });
          error = true;
        }
      });

      return error;
    };

    _onCancelHandler = () => {
      let designData = { ...this.state.designData };
      let updatedData = {};
      Object.keys(designData || {}).forEach(input => {
        designData[input].error = "";
        if (designData[input].type === "file") {
          //resset default image flag for company hero image
          if (input === "HeroImage") {
            designData[input].isDefaultImage = get(
              this.props.systemConfigurationData,
              "companyHeroImage.isDefaultImage"
            );
          }

          designData[input].showDefaultFile = true;
          designData[input].original.dataUri = "";
          designData[input].cropped.dataUri = "";
          designData[input].isEdited = false;
        }
        updatedData[input] = input;
      });
      this.setState({
        designData: updatedData
      });
      this._updateDesignData();
    };

    setDefaulImageHandler = () => {
      let {
        designData,
        designData: { heroImage }
      } = { ...this.state };

      heroImage.isDefaultImage = true;
      heroImage.isEdited = true;

      //get values from the api
      let { companyHeroImage } = this.props.systemConfigurationData || {};
      heroImage.original.location = get(
        companyHeroImage,
        "defaultImage.location"
      );

      heroImage.cropped.location =
        get(companyHeroImage, "isDefaultImage") &&
        (get(companyHeroImage, "cropped.location") || "");

      //reset state
      heroImage.dimensions =
        get(companyHeroImage, "isDefaultImage") &&
        get(companyHeroImage, "cropped.location")
          ? get(companyHeroImage, "dimensions")
          : {
              width: 1000,
              height: 1000,
              x: 1000,
              y: 1000
            };
      heroImage.original.dataUri = "";
      heroImage.cropped.dataUri = "";
      heroImage.showDefaultFile = true;

      this.setState({
        designData,
        isDataChange: true
      });
    };

    render() {
      const { state } = this;

      const MainProps = {
        ...state,
        systemConfigurationData: this.props.systemConfigurationData,
        isLoading: false,
        handleInputChange: this._handleInputChange,
        handleEditImage: this.handleEditImage,
        onFileUpload: this.onFileUpload,
        saveButtonHandler: this._saveButtonHandler,
        handleColorChange: this.handleColorChange,
        cancelButtonHanlder: this._onCancelHandler,
        setDefaulImageHandler: this.setDefaulImageHandler
      };

      return <Main {...MainProps} />;
    }
  };

export default container;

import React from "react";
import { Route } from "react-router-dom";

// App Components
import Users from "pages/users";

/**
 * Users Routes list
 * @param {Object} auth
 * @param {Function} checkAuthSession
 */
const userRoutes = (auth, checkAuthSession) => {
  const routes = (
    <>
      <Route
        exact
        path="/admin/users"
        render={props => checkAuthSession(<Users auth={auth} {...props} />)}
      />
    </>
  );

  return routes;
};

export default userRoutes;

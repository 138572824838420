import React, { Component } from "react";
import { get, flatten, map } from "lodash";
import ToastUtils from "utils/handleToast";

const Container = Main =>
  class ThemePreview extends Component {
    constructor(props) {
      super(props);

      this.state = {
        activeThemeName: {
          value: "",
          error: "",
          initialValue: ""
        },
        isThemeTitleEditable: false,

        activeThemeDetails: {},
        isSlidePreviewToggleOpen: true,
        showContent: true,
        currentActiveIndex: 0,
        slideTypeName: "",
        slideTaggedAs: "",
        slideThumbnail: null,
        availableSlides: []
      };

      this.titleRef = React.createRef();
    }

    componentDidMount() {
      let { activeThemeName } = this.state;
      let activeThemeDetails = this.props.activeThemeDetails || {};
      this.formatThemeData(activeThemeDetails);

      activeThemeName.value = get(activeThemeDetails, `title`);
      activeThemeName.initialValue = get(activeThemeDetails, `title`);
      this.setState({
        activeThemeDetails,
        activeThemeName
      });
    }

    formatThemeData = activeThemeDetails => {
      let previewRequiredFields = [
        "Blank",
        "Cover",
        "Divider",
        "PrimaryCover",
        "Content"
      ];

      let availableSlides = [];
      for (const property in activeThemeDetails) {
        if (previewRequiredFields.indexOf(property) > -1) {
          if (activeThemeDetails[property].length > 1) {
            // set a property count for every slide as per the index
            let activeThemeDetailsWithCount = map(
              activeThemeDetails[property],
              (field, index) => {
                field.count = index + 1;
                return field;
              }
            );

            availableSlides.push(activeThemeDetailsWithCount);
          } else {
            activeThemeDetails[property][0].count = 1;
            availableSlides.push(activeThemeDetails[property][0]);
          }
        }
      }

      // flatten the array in case if there is more than 1 type of slide
      availableSlides = flatten(availableSlides);

      this.setState({
        availableSlides
      });
    };

    /**
     * Function when title editing is cancelled
     */
    _onThemeTitleCancel = () => {
      let { activeThemeName } = this.state;
      activeThemeName["value"] = activeThemeName.initialValue;
      activeThemeName.error = "";

      this.setState({
        activeThemeName,
        isThemeTitleEditable: false
      });
    };

    /**
     * Function for saving the updated title value
     */
    _onThemeTitleSave = () => {
      let {
        activeThemeName,
        activeThemeDetails: { _id: themeId }
      } = this.state;

      let { saveEditedThemeDetails, handleModal } = this.props;
      if (activeThemeName.error) {
        ToastUtils.handleToast({
          operation: "error",
          message: "Please enter a valid theme title."
        });
        return false;
      }

      if (activeThemeName.value !== activeThemeName["initialValue"]) {
        saveEditedThemeDetails(
          { title: activeThemeName.value.trim() },
          themeId,
          "Theme title has been updated successfully."
        );
        handleModal();
      }
      activeThemeName["initialValue"] = activeThemeName.value;

      this.setState({
        activeThemeName,
        isThemeTitleEditable: false
      });
    };

    /**
     * Function to handle the title input change
     * @param {String} the value of the title input
     */
    _onThemeTitleChange = value => {
      let { activeThemeName } = this.state;

      activeThemeName["error"] = this.props.handleTextValidation(value);
      activeThemeName["value"] = value;
      this.setState({
        activeThemeName
      });
    };

    /**
     * Function to handle the edit icon click of title
     */
    _onThemeTitleEdit = () => {
      this.setState(
        {
          isThemeTitleEditable: true
        },
        () => {
          this.titleRef.current.focus();
        }
      );
    };

    /**
     * Handle the Toggle bar
     */
    _onSlideBarToggle = () => {
      this.setState({
        isSlidePreviewToggleOpen: !this.state.isSlidePreviewToggleOpen
      });

      //This is used to display the content once the full width is achievent
      setTimeout(() => {
        this.setState({
          showContent: !this.state.showContent
        });
      }, 450);
    };

    navigationHandler = (previous, next) => {
      let { currentActiveIndex, activeThemeDetails } = this.state;

      currentActiveIndex = previous
        ? currentActiveIndex - 1
        : currentActiveIndex + 1;

      this.setState({
        currentActiveIndex,
        activeThemeDetails,
        isThemeTitleEditable: false
      });
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props,
        onThemeTitleEdit: this._onThemeTitleEdit,
        onThemeTitleChange: this._onThemeTitleChange,
        onThemeTitleSave: this._onThemeTitleSave,
        onThemeTitleCancel: this._onThemeTitleCancel,
        onSlideBarToggle: this._onSlideBarToggle,
        prevCtaHandler: this._prevCtaHandler,
        nextCtaHandler: this._nextCtaHandler
      };

      return <Main {...stateMethodProps} />;
    }
  };

export default Container;

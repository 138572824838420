import React from "react";
import hexToRgba from "utils/hexToRgba";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";

import { Dropdown as DropdownIcon } from "assets/icons";

import StyledDropzone from "./components/chooseFile";

const InputField = props => {
  let {
    type,
    handleChange,
    placeholder,
    error,
    value,
    width,
    option,
    reupload,
    isBulkUploadPending
  } = props;
  let $isError = !!error;

  return (
    <FormGroup width={width} ischeckbox={type === "checkbox"}>
      <Label>{placeholder}</Label>

      {type === "file" && (
        <>
          <StyledDropzone
            acceptExtensions={
              reupload || isBulkUploadPending
                ? ["png", "jpg"]
                : ["png", "jpg", "zip"]
            }
            uploadFileType="bulkImage"
            manageValidFileStatus={props.manageValidFileStatus}
            helperText={`You may upload a .png, ${
              reupload || isBulkUploadPending ? `or .jpg` : `.jpg or .zip`
            } file here.`}
            invalidErrorMessage={"Please upload a valid file."}
            handleFileChange={props.handleFileChange}
            value={value}
          />
        </>
      )}

      {type === "dropdown" && (
        <>
          <ParentSlideComponent>
            <AngleDown>
              <DropdownIcon />
            </AngleDown>
            <DropDown
              autoFocus={true}
              className="category-select"
              onChange={e => handleChange(e.target.value)}
              value={value}
            >
              {_.map(option, (item, index) => {
                let itemKey =
                  item instanceof Object ? _.get(item, "_id") : item;
                let itemValue =
                  item instanceof Object ? _.get(item, "title") : item;

                return (
                  <DropDownOption
                    value={index === 0 ? "" : itemKey}
                    className="select-options"
                    key={itemKey + index}
                  >
                    {itemValue}
                  </DropDownOption>
                );
              })}
            </DropDown>
          </ParentSlideComponent>
        </>
      )}

      {$isError && <Error>{error}</Error>}
    </FormGroup>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.number,
  handleChange: PropTypes.func
};

InputField.defaultProps = {
  type: "text",
  placeholder: "Placeholder",
  name: "Name",
  error: null
};

const InputBox = styled.input`
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  font-size: 14px;
  font-family: ${props => props.theme.FONT.REG};
  background-color: ${props => hexToRgba(props.theme.COLOR.WHITE, "0.18")};
  border: 1px solid
    ${props => hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, "0.4")};
  border-radius: 4px;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

const FormGroup = styled.div`
  padding-top: ${props => (props.ischeckbox ? "0px" : "18px")};
  max-width: ${props => (props.width ? `${props.width}px` : "auto")};
  width: 100%;
  border-radius: 4px;
  position: relative;
`;

const Error = styled.span`
  min-width: 191px;
  position: absolute;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.ERROR, "1")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;
  font-weight: normal;
  bottom: -12px;
  left: 0;
  pointer-events: none;
`;

const Label = styled.label`
  /* position: absolute; */
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, "0.64")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  font-weight: bold;
  top: 22px;
  left: 10px;
  transition: 0.3s ease all;

  ${props =>
    props.isFixedTitle
      ? `
      top: 0px;
      left: 0px;  
      font-weight: bold;
    `
      : `${InputBox}:focus ~ &,
    ${InputBox}:not(:focus):valid ~ & {
      transform: translateY(-22px);
      font-weight: bold;
      left: 0;
    }}`}
`;

//Dropdown
const AngleDown = styled.label`
  position: absolute;
  padding-top: 5px;
  right: 0;
  width: 31px;
  height: 20px;
  top: 0;
  z-index: -1;
  height: 30px;
  background: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
`;

const ParentSlideComponent = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 4px;
  background: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
`;

const DropDown = styled.select`
  width: 100%;
  height: 30px;
  padding: 0 9px;
  border: none;
  border-radius: 4px;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
  font-family: ${props => props.theme.FONT.REG};
  background: transparent;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 30px;
  cursor: pointer;
  outline: none;
  padding-right: 40px;
`;

const DropDownOption = styled.option``;

export default InputField;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { walk } from "react-sortable-tree";
import { find, each } from "lodash";
//services
import * as services from "../../services";
import { getContentRepoName } from "../../../themes/services";
import { getFilterList } from "../../services";
import ValidationUtils from "utils/ValidationUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";
import PollingUtils from "utils/PollingUtils";
import { downloadFile } from "utils/download";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";

const EMPTY_FIELD_ERROR_MESSAGE = "This field is required.";
const SPECIAL_CHAR_ERROR_MESSAGE = "Please do not enter the special character.";
const WHITE_SPACE_ERROR_MESSAGE = "Please enter a valid input.";
const FILE_UPLOAD_NOT_VALID_MESSAGE = "File uploaded is not valid.";
const MAX_LAYOUT_SIZE_MESSAGE = "Maximum file size of 20MB.";
const mapStateToProps = state => {
  const {
    CONTENT_SLIDE_SUCCESS,
    LOADING_CONTENT_SLIDE_LIST,
    SUCCESS_THEME_LIST,
    LOADING_THEME_LIST,
    CONTENT_REPO_SLIDE__SUCCESS,
    LOADING_CONTENT_REPO_SLIDE,
    GET_THEME_DATA,
    FILTERS_LIST_SUCCESS,
    FILTERS_LIST_LOADING
  } = state;

  return {
    ...CONTENT_SLIDE_SUCCESS,
    ...LOADING_CONTENT_SLIDE_LIST,
    ...SUCCESS_THEME_LIST,
    ...LOADING_THEME_LIST,
    ...CONTENT_REPO_SLIDE__SUCCESS,
    ...LOADING_CONTENT_REPO_SLIDE,
    ...GET_THEME_DATA,
    ...FILTERS_LIST_SUCCESS,
    ...FILTERS_LIST_LOADING
  };
};

const Container = Main =>
  connect(mapStateToProps, { ...services, getContentRepoName, getFilterList })(
    withRouter(
      class RepoManager extends Component {
        constructor(props) {
          super(props);

          this.state = {
            editSlide: false,
            contentRepoSettings: {
              orderOptions: [
                {
                  label: "My own order",
                  id: "custom"
                },
                {
                  label: "Alphabetical",
                  id: "alphabetical"
                }
              ],
              checkbox: [
                {
                  label: "Hide disabled items",
                  id: "hideDisabledSlides",
                  checked: false
                },
                {
                  label: "Allow a dynamically created overview page",
                  id: "overview",
                  checked: false
                }
              ],
              orderValue: "custom"
            },
            slideFormValidations: {
              name: {
                value: "",
                errorMessage: "",
                required: true
              },
              parent: {
                value: "",
                errorMessage: "",
                required: true
              },
              description: {
                value: "",
                errorMessage: "",
                required: false
              },
              author: {
                value: "",
                errorMessage: "",
                required: true
              },
              upload: {
                value: {},
                errorMessage: "",
                required: true
              },
              required: {
                value: false,
                required: false
              }
            },
            groupDetails: {
              selectedGroup: "",
              groupId: "",
              editGroupValue: "",
              isUpdatingGroupTitle: false,
              inputError: "",
              groupOption: [],
              searchValue: "",
              isSearching: false,
              slideList: [],
              searchSlideList: [],
              isNew: false,
              errorEmptyList: "",
              categoryDetail: {}
            },
            showPreview: false,
            previewSlide: "",
            isBuilding: false,
            previewImageLoading: true,
            filterListData: [],
            selectedOption: {},
            isFiltersEdited: false
          };
          this.timeout = 0;

          this.slideName = React.createRef();
          this.slideParent = React.createRef();
          this.slideDescription = React.createRef();
          this.slideAuthor = React.createRef();
          this.slideUpload = React.createRef();
        }

        async componentDidMount() {
          this.fetchContentRepoDetails();

          const filterList = await this.props.getFilterList(
            this.props.match.params.contentRepoId,
            true
          );

          this.filterListDataFormating(filterList.data);

          await this._fetchSlideGroup();
        }

        filterListDataFormating = (filterList = []) => {
          let filterListData =
            (Array.isArray(filterList) &&
              filterList.map(this._filterItemsHandler)) ||
            [];
          this.setState({ filterListData });
        };

        _filterItemsHandler = (eachItem = {}) => {
          let { title, _id, children } = eachItem;
          return {
            label: title,
            title,
            ...(children && { options: this._filterOptionnHandler(children) }),
            value: _id,
            _id
          };
        };

        _filterOptionnHandler = (children = []) =>
          children.map(this._filterItemsHandler);

        handleFilterChange = (selected, _id) => {
          let { selectedOption } = this.state;
          selectedOption[_id] = selected;
          this.setState({ selectedOption, isFiltersEdited: true });
        };

        handleStateChange = ({ key, value }) => {
          this.setState({
            [key]: value
          });
        };

        componentDidUpdate(prevProps) {
          let { editedSlide, parentNode } = this.props;
          let {
            editedSlide: prevEditedSlide,
            parentNode: prevParentNode
          } = prevProps;

          if (
            editedSlide &&
            (editedSlide._id !== prevEditedSlide._id ||
              prevParentNode._id !== parentNode._id)
          ) {
            this.mapSlideDetails(editedSlide, parentNode);
          }
        }

        /**
         *Map the selected slide detail with the respective field
         *
         */
        mapSlideDetails = async (details = {}, parentNode) => {
          let { slideFormValidations, groupDetails } = JSON.parse(
            JSON.stringify(this.state)
          );

          // map saved values from api to slideFormValidations for populating data on edit
          (Object.keys(slideFormValidations) || []).forEach(key => {
            slideFormValidations[key]["value"] = {
              name: details.title,
              parent: parentNode._id || details.contentSlideCategory,
              description: details.description,
              author: details.author,
              required: details.isRequired,
              upload: { name: details.filename }
            }[key];
            slideFormValidations[key]["errorMessage"] = "";
          });

          // Show selected group
          groupDetails["groupId"] = get(details, "group._id", "");

          if (details._id) {
            await this.props.fetchThemeData(
              this.props.contentRepoId,
              details._id
            );
          }

          let selectedOption =
            (await this._getSelectedFilters(details.contentFilter)) || {};

          this.setState({
            slideFormValidations,
            groupDetails,
            selectedOption
          });
        };

        /**
         * Get formatted selected filter option for each parent filter.
         * Loops through the filters present in the content repository and return the selected contentFilter
         * formatted wrt to its parent for dropdown
         *
         * @param {*} [contentFilter=[]] Array of selected filters
         * @returns {Object}  Returns selected content filters in the below format
         * [parentId: {
              title: 
              name: 
              label:
              _id:
            }]
         */
        _getSelectedFilters = async (contentFilter = []) => {
          let { filterListData } = this.state;
          let selectedData = {};
          if (contentFilter.length) {
            await each(filterListData, async filters => {
              if (filters.options.length) {
                await each(filters.options, eachOption => {
                  if (contentFilter.indexOf(eachOption._id) > -1) {
                    selectedData[filters._id] = [
                      ...(Array.isArray(selectedData[filters._id])
                        ? selectedData[filters._id]
                        : []),
                      eachOption
                    ];
                  }
                });
              }
            });
          }
          return selectedData;
        };

        // fetch contentRepo details and set setting associated that contentRepo
        fetchContentRepoDetails = async () => {
          let { contentRepoSettings } = this.state;

          await this.props.getContentRepoName(this.props.contentRepoId);
          const { hideDisabledSlides, useCustomSortOrder, overviewData = {} } =
            this.props.selectedContentRepo || {};

          contentRepoSettings.orderValue = !useCustomSortOrder
            ? "alphabetical"
            : "custom";

          contentRepoSettings.checkbox[0].checked = hideDisabledSlides;
          contentRepoSettings.checkbox[1].checked = overviewData.enable;

          this.setState({
            contentRepoSettings
          });
        };

        onDropDownChange = async e => {
          let {
            slideFormValidations,
            groupDetails: { groupId },
            isFiltersEdited
          } = this.state;
          let {
            editedSlide: { contentSlideCategory },
            dropDownOptionsNew
          } = this.props;

          slideFormValidations.parent.errorMessage = "";
          slideFormValidations.parent.value = e.target.value;

          let contentFilter =
            dropDownOptionsNew[e.target.selectedIndex - 1].contentFilter;

          let selectedOption =
            (await this._getSelectedFilters(contentFilter)) || {};

          // Check if the group id is associated for parent
          groupId && this.handleParentForGroups(contentSlideCategory);

          this.setState({
            slideFormValidations,
            selectedOption,
            isFiltersEdited
          });
        };

        // slide content required checkbox is clicked
        onRequiredClick = e => {
          let { slideFormValidations } = this.state;

          slideFormValidations.required.value = e.target.checked;

          this.setState({
            slideFormValidations
          });
        };

        /**
         * Manage States with prop and value dynamically
         */
        manageStates = ({ propName, value, cb, resetCurrentSelectedSlide }) => {
          this.setState(
            {
              [propName]: value
            },
            () => {
              cb && cb();

              // reset current selected slide
              resetCurrentSelectedSlide && resetCurrentSelectedSlide.call(this);
            }
          );
        };

        emptyState = () => {
          let { slideFormValidations, groupDetails } = JSON.parse(
            JSON.stringify(this.state)
          );

          this.props.resetNewFocusIndex({
            key: "currentSelectedSlide",
            value: null
          });

          groupDetails.editGroupValue = "";
          groupDetails.inputError = "";
          groupDetails.isUpdatingGroupTitle = false;
          groupDetails.selectedGroup = "";
          groupDetails.groupId = "";
          groupDetails.slideList = [];

          Object.keys(slideFormValidations).forEach(formKey => {
            slideFormValidations[formKey] = {
              value: "",
              errorMessage: "",
              required: slideFormValidations[formKey]["required"]
            };
          });

          this.props.resetNewFocusIndex({
            key: "editedSlide",
            value: {}
          });

          this.setState({
            activeTab: "slide",
            groupDetails,
            slideFormValidations,
            selectedOption: {}
          });
        };

        /**
         * Save changes to update the content repo setting
         */
        saveChangesHandler = async () => {
          let {
            contentRepoSettings: { orderValue, hideDisabledSlides, overview }
          } = this.state;

          const { hideDisabledSlides: hideDisabledSlidesContentRepoData } =
            this.props.selectedContentRepo || {};

          let body = {
            useCustomSortOrder: orderValue === "custom",
            hideDisabledSlides,
            overviewData: {
              enable: overview
            }
          };

          let contentRepoConfig = this.props.contentRepoConfig;

          let id =
            (contentRepoConfig &&
              contentRepoConfig.header &&
              contentRepoConfig.header.contentRepoId) ||
            "";

          await this.props.updateSlideSettings(body, id);

          if (hideDisabledSlidesContentRepoData !== hideDisabledSlides)
            this.props.fetchContentSlides && this.props.fetchContentSlides();

          // fetch contentRepo details and set contentRepo settings
          this.fetchContentRepoDetails();
        };

        /**
         * Function to handle the Radio button changes
         * @param {String} key to be updated
         * @param {String} value changed value
         */
        handleInputChange = ({ key, value, slideValue, slideLabel }) => {
          let { contentRepoSettings, slideFormValidations } = this.state;

          if (key) contentRepoSettings[key] = value;

          // code for slide form validation
          if (slideLabel) {
            slideFormValidations[slideLabel].value = slideValue;
            const required = slideLabel === "description" ? false : true;

            slideFormValidations[
              slideLabel
            ].errorMessage = this.checkValidation(slideValue, required);
          }

          this.setState({
            contentRepoSettings,
            slideFormValidations
          });
        };

        // Function to handle slide save
        onSlideSave = async () => {
          let {
            slideFormValidations,
            groupDetails,
            selectedOption,
            isFiltersEdited
          } = this.state;

          let filters = [];
          each(Object.keys(selectedOption), filter => {
            each(selectedOption[filter], option => {
              filters.push(option._id);
            });
          });

          let submitFlag = true;
          let {
            contentRepoId,
            saveContentSlide,
            editedSlide,
            uploadSlideToAws
          } = this.props;

          // Fields whose text validations need to be ignored
          let ignoreTextValidation = get(editedSlide, "_id")
            ? ["upload", "required"]
            : ["required"];

          let contentRepositoryId =
            get(this, "props.match.params.contentRepoId") || contentRepoId;

          let checkIfScrolled = false;
          // Loop through forms to check errors on any field
          (Object.keys(slideFormValidations) || []).forEach(formKey => {
            let { value, required } = slideFormValidations[formKey] || {};
            if (ignoreTextValidation.indexOf(formKey) > -1) return;
            let error =
              formKey === "upload"
                ? this.checkValidation(value.name, required)
                : this.checkValidation(value, required);
            if (error) {
              slideFormValidations[formKey]["errorMessage"] = error;
              // window
              if (!checkIfScrolled) {
                if (formKey === "name") {
                  let ele = this.slideName.current;
                  ele.scrollIntoView();
                  checkIfScrolled = true;
                } else if (formKey === "parent") {
                  let ele = this.slideParent.current;
                  ele.scrollIntoView();
                  checkIfScrolled = true;
                } else if (formKey === "description") {
                  let ele = this.slideDescription.current;
                  ele.scrollIntoView();
                  checkIfScrolled = true;
                } else if (formKey === "author") {
                  let ele = this.slideDescription.current;
                  ele.scrollIntoView();
                  checkIfScrolled = true;
                } else if (formKey === "upload") {
                  let ele = this.slideUpload.current;
                  ele.scrollIntoView();
                  checkIfScrolled = true;
                }
              }

              submitFlag = false;
            }
          });

          if (submitFlag && contentRepositoryId) {
            this.setState({
              isBuilding: true
            });

            let title = get(slideFormValidations, "name.value");
            let description =
              get(slideFormValidations, "description.value") || "";
            let author = get(slideFormValidations, "author.value");
            let group = get(groupDetails, "groupId") || null;
            let isRequired =
              get(slideFormValidations, "required.value") || false;
            let fileName = get(slideFormValidations, "upload.value.name");
            let selectedSlideId = get(editedSlide, "_id");

            // Send only if new parent is different than old parent
            let isParentEdited =
              get(slideFormValidations, "parent.value") !==
              get(editedSlide, "contentSlideCategory");
            let parent = isParentEdited
              ? get(slideFormValidations, "parent.value")
              : get(editedSlide, "contentSlideCategory");

            let isGroupEdited =
              get(groupDetails, "groupId") !== get(editedSlide, "group._id");
            let isFileEdited = fileName !== get(editedSlide, "filename");
            // body to send to save api
            let body = {
              ...(title && { title }),
              ...(author && { author }),
              ...(isGroupEdited && { group }),
              isRequired,
              description,
              ...(isParentEdited && { newParent: parent }),
              ...(isFileEdited && { fileName }),
              ...(isFileEdited && { fileType: "ppt" }),
              ...(isFiltersEdited && { filters })
            };

            // Post meta data of slide
            let { data, success } =
              (await saveContentSlide(
                contentRepositoryId,
                parent,
                body,
                selectedSlideId
              )) || {};
            if (success && data.ingestId) {
              // use presigned url to upload file to aws
              let { success } =
                (await uploadSlideToAws(
                  data.presignedUrl,
                  get(slideFormValidations, "upload.value")
                )) || {};

              if (!success) return;

              PollingUtils.startPolling({
                pollingAction: () => {
                  this.pollingActionForSlideUpload(
                    data.ingestId,
                    contentRepositoryId,
                    parent,
                    body
                  );
                },
                timeoutCallback: () => {
                  PollingUtils.stopPolling();
                  // Enable save button
                  this.setState({ isBuilding: false, isFiltersEdited: false });

                  ToastUtils.handleToast({
                    operation: "error",
                    message:
                      "Slide upload is taking too long. Please try again later."
                  });
                },
                timeoutDuration: 500000
              });
            } else if (success) {
              // Enable save button
              ToastUtils.handleToast({
                operation: "success",
                message: "Slide has been successfully updated."
              });
              await (this.props.fetchContentSlides &&
                this.props.fetchContentSlides());

              this.setState({ isBuilding: false, isFiltersEdited: false });

              // Clear file name and data
              this.emptyState();

              // Set focus on the currently editing slide parent
              this.props.setFocusOnGroupDropdownChange(parent);
            } else {
              // Enable save button
              this.setState({ isBuilding: false });
            }
          }

          this.setState({ slideFormValidations });
        };

        /**
         * @param {String} ingestId ingest id to be used for polling
         * @param {String} contentRepoId currently selected content repo id
         * @param {String} categoryId category id which is the parent of the currently open slide
         * @param {Object} body body to be sent
         */
        pollingActionForSlideUpload = async (
          ingestId,
          contentRepoId,
          categoryId,
          body
        ) => {
          let slidePollingStatus = await this.props.getSlidePollingStatus(
            ingestId
          );
          let slideId = get(
            slidePollingStatus,
            "data.contentDocumentDetails.documentId"
          );
          if (get(slidePollingStatus, `data.status`) === "Completed") {
            // On successful upload
            await this.onSuccessfulUpload(contentRepoId, categoryId, slideId);
          } else if (
            get(slidePollingStatus, `status` !== 200) ||
            get(slidePollingStatus, `data.status`) === "Failed"
          ) {
            // Enable save button
            this.setState({ isBuilding: false });

            PollingUtils.stopPolling();
            this.props.stopSlidePolling();
            ToastUtils.handleToast({
              operation: "error",
              message: get(slidePollingStatus, `data.message`)
            });
          }
        };

        /**
         *Clear upload file data
         *
         */
        clearFileData = () => {
          let { slideFormValidations } = JSON.parse(JSON.stringify(this.state));
          slideFormValidations["upload"] = {
            value: "",
            errorMessage: ""
          };
          this.setState({
            slideFormValidations
          });
        };

        /**
         *Callback function on Successful file upload
         *
         * @param {*} contentRepoId Id of the current content repository
         * @param {*} parentId Id of the parent where the lside resides
         */
        onSuccessfulUpload = async (contentRepoId, parentId, slideId) => {
          PollingUtils.stopPolling();
          await (this.props.fetchContentSlides &&
            this.props.fetchContentSlides());

          // Clear file name and data
          this.emptyState();

          // Stop loader
          this.props.stopSlidePolling();

          // Set focus on the currently editing slide parent
          this.props.setFocusOnGroupDropdownChange(parentId);

          ToastUtils.handleToast({
            operation: "success",
            message: "Slide(s) has been successfully created."
          });

          this.setState({ isBuilding: false });
        };

        // Function to handle layout upload
        onLayoutUpload = (e, targetID) => {
          let { slideFormValidations } = this.state;
          const targetFile = e.target.files;
          if (targetFile.length > 0) {
            const fileRequiredExtension = ["pptx", "ppt"];
            const fileNameExtension = targetFile[0].name
              .split(".")
              .splice(-1)[0];
            const checkSize = ValidationUtils.compareFileSize(
              targetFile[0].size,
              20
            );

            const uploadFlag =
              fileRequiredExtension.indexOf(fileNameExtension) > -1 &&
              checkSize;
            if (fileRequiredExtension.indexOf(fileNameExtension) === -1) {
              slideFormValidations.upload.errorMessage = FILE_UPLOAD_NOT_VALID_MESSAGE;
            } else if (!checkSize) {
              slideFormValidations.upload.errorMessage = MAX_LAYOUT_SIZE_MESSAGE;
            } else if (uploadFlag) {
              slideFormValidations.upload.errorMessage = "";
              slideFormValidations.upload.value = targetFile[0];
            }

            this.setState({
              slideFormValidations
            });
          }
        };

        /**
         * Reset the value to it's default state when modal is opened or closed
         */
        resetValueHandler = () => {
          // fetch contentRepo details and set contentRepo settings
          this.fetchContentRepoDetails();
        };

        /**
         * Group Title Change
         * @param {String} The new value inputted by the user
         */
        groupTitleChangeHandler = value => {
          let { groupDetails } = this.state;
          groupDetails.editGroupValue = value;
          this.setState({
            groupDetails,
            inputError: this.checkValidation(value)
          });
        };

        /**
         * Handler Choose Group Dropdown
         * @param {String} id of the dropdown selected
         */
        onGroupDropDownHandler = id => {
          let { groupDetails } = this.state;

          let { contentSlideGroupList } = this.props;
          let selectedGroupData = (contentSlideGroupList || []).filter(
            item => id === item._id
          );
          let groupTitle = selectedGroupData[0]
            ? selectedGroupData[0].title
            : "";
          groupDetails.selectedGroup = groupTitle;
          groupDetails.editGroupValue = groupTitle;
          groupDetails.groupId = id;

          this.setState({
            groupDetails
          });

          //Fetch slides of the group
          groupDetails.groupId && this._fetchContentSlidesOfGroup();
        };

        /**
         * Update the group data. To make input active on edit and Add new click
         */
        editGroupDataHandler = () => {
          let { groupDetails } = this.state;

          //set the group details value
          groupDetails.isUpdatingGroupTitle = true;
          groupDetails.isUpdatingGroupTitle = true;

          this.setState({
            groupDetails
          });
        };

        /**
         * Add the new group to the slides
         */
        addNewGroupHandler = () => {
          let { groupDetails } = this.state;

          //set the group details value
          groupDetails.editGroupValue = "";
          groupDetails.isUpdatingGroupTitle = true;
          groupDetails.selectedGroup = "";
          groupDetails.inputError = "";

          this.setState({
            groupDetails,
            addNewGroup: true
          });
        };

        /**
         * Reset the Group Title on the cross click
         */
        resetGroupTitleHandler = () => {
          let { groupDetails } = this.state;

          //set the group details value
          groupDetails.editGroupValue = groupDetails.selectedGroup;
          groupDetails.isUpdatingGroupTitle = false;
          groupDetails.inputError = "";

          this.setState({
            groupDetails,
            addNewGroup: false
          });
        };

        /**
         * Update the group title when user click the check button
         */
        updateGroupTitleHandler = async () => {
          let { groupDetails, addNewGroup } = this.state;
          let { editGroupValue, selectedGroup, groupId } = groupDetails;
          //Do not call api when the previous data and the current data is the same
          if (selectedGroup === editGroupValue) {
            groupDetails.isUpdatingGroupTitle = false;

            this.setState({
              groupDetails
            });
            return;
          }

          //Check for Error message and if any return with proper validation message
          let errorMessage = this.checkValidation(editGroupValue);

          if (errorMessage) {
            groupDetails.inputError = errorMessage;
            this.setState({
              groupDetails
            });

            ToastUtils.handleToast({
              operation: "error",
              message: "Please fill the required field."
            });

            return;
          }

          //Get content repo iD
          let contentRepoConfig = this.props.contentRepoConfig;

          let contentRepoId =
            (contentRepoConfig &&
              contentRepoConfig.header &&
              contentRepoConfig.header.contentRepoId) ||
            "";

          let body = {
            title: editGroupValue.trim()
          };
          let response = await this.props.updateContentRepogroup(
            body,
            contentRepoId,
            `Group has been successfully ${groupId ? "updated" : "created"}.`,
            !addNewGroup && groupId
          );

          //assign the updated value
          groupDetails.editGroupValue = "";
          groupDetails.inputError = "";
          groupDetails.isUpdatingGroupTitle = false;

          if (response.success) {
            await this.props.fetchContentSlides();
            await this._fetchSlideGroup();

            let GroupData = this.props.contentSlideGroupList || [];
            let selectedGroupData = GroupData.filter(
              item => groupDetails.groupId === item._id
            );

            groupDetails.groupId =
              (selectedGroupData.length && selectedGroupData[0]._id) || "";

            groupDetails.editGroupValue =
              (selectedGroupData.length && selectedGroupData[0].title) || "";
            groupDetails.selectedGroup =
              (selectedGroupData.length && selectedGroupData[0].title) || "";

            this.setState({
              groupDetails,
              addNewGroup: false
            });

            // Open the respective group which is highlighted
            this.props.setFocusOnGroupDropdownChange(
              groupDetails.categoryDetail._id
            );

            //Fetch slides of the group
            groupDetails.groupId && this._fetchContentSlidesOfGroup();
          }
        };

        /**
         * Function to hanlde the validation of the input field
         * @param {String} The value inputted by the user
         */
        checkValidation = (value = "", required = true) => {
          if (ValidationUtils.checkIfEmptyField(value) && required) {
            return EMPTY_FIELD_ERROR_MESSAGE;
          } else if (ValidationUtils.checkIfWhiteSpace(value)) {
            return WHITE_SPACE_ERROR_MESSAGE;
          } else if (ValidationUtils.validateFileName(value)) {
            return SPECIAL_CHAR_ERROR_MESSAGE;
          } else {
            return null;
          }
        };

        /**
         * Fetch the updated slide group
         */
        _fetchSlideGroup = async () => {
          let { groupDetails } = this.state;
          //Get content repo ID
          let contentRepoConfig = this.props.contentRepoConfig;
          let contentRepoId =
            (contentRepoConfig && contentRepoConfig.header.contentRepoId) || "";

          await this.props.fetchSlideGroup(contentRepoId);

          //Add choose category to 0th index
          let groupOption = this.props.contentSlideGroupList;

          Array.isArray(groupOption) &&
            groupOption.unshift({
              title: "Choose Group",
              _id: 1
            });

          groupDetails.groupOption = groupOption;

          this.setState({
            groupDetails
          });
        };

        /**
         * Delete Particular group from the slide
         */
        deleteGroupHandler = async () => {
          let { groupDetails } = this.state;

          let contentRepoConfig = this.props.contentRepoConfig;
          let contentRepoId =
            (contentRepoConfig && contentRepoConfig.header.contentRepoId) || "";
          let groupId = groupDetails.groupId;
          let response = await this.props.deleteContentRepogroup(
            contentRepoId,
            groupId
          );

          if (response.success) {
            this.props.setFocusOnGroupDropdownChange(
              groupDetails.categoryDetail._id,
              groupDetails.groupId
            );
            groupDetails.selectedGroup = "";
            groupDetails.editGroupValue = "";
            groupDetails.slideList = [];
            groupDetails.categoryDetail = {};
            groupDetails.groupId = "";
          }

          await this._fetchSlideGroup();
          await this.props.fetchContentSlides();
        };

        handleChange = e => {
          let { groupDetails } = this.state;

          clearTimeout(this.timeout);
          this.timeout = setTimeout(this.onSearch, 1000);
          groupDetails.searchValue = e.target.value.trim();
          this.setState({
            groupDetails
          });
        };

        onSearch = async () => {
          let { groupDetails } = this.state;
          let value = groupDetails.searchValue;
          if (
            groupDetails.searchValue.length &&
            groupDetails.searchValue.length > 2
          ) {
            let contentRepoConfig = this.props.contentRepoConfig;
            let contentRepoId =
              (contentRepoConfig && contentRepoConfig.header.contentRepoId) ||
              "";
            groupDetails.isSearching = true;

            await this.props.searchSlideGroup(
              contentRepoId,
              groupDetails.groupId,
              value
            );

            groupDetails.searchSlideList = this.props.searchSlideList || [];
            groupDetails.errorEmptyList = !groupDetails.searchSlideList.length
              ? "No Data Found"
              : "";
          } else {
            groupDetails.isSearching = false;
            groupDetails.searchSlideList = [];
            groupDetails.errorEmptyList = "";
          }

          this.setState({
            groupDetails
          });
        };

        /**
         * Fetch the content slides of the particular group
         */
        _fetchContentSlidesOfGroup = async () => {
          let { groupDetails } = this.state;
          //Get content repo ID
          let contentRepoConfig = this.props.contentRepoConfig;
          let contentRepoId =
            (contentRepoConfig && contentRepoConfig.header.contentRepoId) || "";
          let response = await this.props.getSlideListOfGroup(
            contentRepoId,
            groupDetails.groupId
          );

          if (response.success) {
            groupDetails.slideList = this.props.groupContentSlideList || [];
            groupDetails.categoryDetail = {};
            groupDetails.isNew = groupDetails.slideList.length ? false : true;
            let { treeData } = this.props;
            let flag = true;

            // to get category detail
            walk({
              treeData,
              getNodeKey: ({ node: treeNode }) => treeNode._id,
              callback: rowInfo => {
                let { node, parentNode } = rowInfo;
                if (find(groupDetails.slideList, { _id: node._id })) {
                  groupDetails.categoryDetail = parentNode || {};
                  if (flag) {
                    this.props.setFocusOnGroupDropdownChange(node._id);
                    flag = false;
                  }
                }
              },
              ignoreCollapsed: false
            });
            this.setState({
              groupDetails
            });
          }
        };

        /**
         * Content Slide Update
         */
        contentSlideUpdate = async (
          contentRepoId,
          contentSlideCategoryId,
          slideId,
          groupId
        ) => {
          let { groupDetails } = this.state;

          let payload = groupId
            ? {
                group: groupId
              }
            : {
                group: null
              };

          let response = await this.props.updateContentSlide(
            contentRepoId,
            contentSlideCategoryId,
            slideId,
            payload,
            "Record has been updated successfully."
          );

          if (response && response.success) {
            //Fetch slides of the group
            groupDetails.groupId && this._fetchContentSlidesOfGroup();

            let groupData = response.data.group;
            // update group on content slide list
            this.props.updateGroupOnContentSlideList(
              slideId,
              groupDetails.isNew,
              groupData
            );

            // clear searchSlideList when slide is assigned to a group for the first time
            if (groupDetails.isNew) {
              groupDetails.isNew = false;
            }

            groupDetails.searchSlideList = groupDetails.searchSlideList.filter(
              item => item._id !== slideId
            );

            this.setState({
              groupDetails
            });
          }
        };

        /**
         *Function to handle preview and download of file
         *
         */
        handleFile = (file, flag) => {
          let { url } = file;
          if (flag === "download") {
            downloadFile(url);
          } else if (flag === "view") {
            this.setState(prevState => ({
              showPreview: !prevState.showPreview,
              previewSlide: url,
              previewImageLoading: true
            }));
          } else {
            this.setState({
              showPreview: false,
              previewSlide: ""
            });
          }
        };

        handleUploadClick = (e, inputRef) => {
          inputRef && inputRef.current.click();
        };

        removeFile = inputRef => {
          let { slideFormValidations } = JSON.parse(JSON.stringify(this.state));
          slideFormValidations["upload"]["value"] = "";
          slideFormValidations["upload"]["error"] = "";

          // Empty current value
          inputRef.current.value = null;

          this.setState({
            slideFormValidations
          });
        };

        handleImageLoad = () => {
          this.setState({
            previewImageLoading: false
          });
        };

        /**
         *If parent is changed for grouped slide remove the group associated of the slide
         *
         * @param {*} id Id of the parent category
         */
        handleParentForGroups = id => {
          DeleteConfirmationAlert({
            message:
              "Changing parent would remove your slide from the group. Do you still want to continue?",
            onYesClick: () => {
              this.setState(prevState => ({
                groupDetails: {
                  ...prevState.groupDetails,
                  groupId: ""
                }
              }));
            },
            onNoClick: () => {
              this.preservedPreviousParent(id);
            },
            onClose: () => {
              this.preservedPreviousParent(id);
            }
          });
        };

        preservedPreviousParent = id => {
          let { slideFormValidations } = this.state;
          slideFormValidations["parent"]["value"] = id;
          this.setState({
            slideFormValidations
          });
        };
        render() {
          const $this = this;
          /** Merge States and Methods */
          const stateMethodProps = {
            ...$this,
            ...$this.state,
            ...$this.props,
            filter: this.props.filters || []
          };
          return <Main {...stateMethodProps} />;
        }
      }
    )
  );
export default Container;

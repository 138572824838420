import React from "react";

import {
  Renewals,
  Letters,
  Users,
  Modules,
  Reporting,
  Docs,
  Settings,
  RefreshIcon,
  SlidePreviewImageBadge
} from "assets/icons";

import featureFlags from "utils/featureFlags.js";

export const toolOptionsList = [
  {
    name: "Renewal Generator",
    value: "renewal"
  },
  {
    name: "Presentation Maker",
    value: "presentation"
  },
  {
    name: "Collateral Maker",
    value: "placemat"
  }
];

export const menuOptions = {
  presentation: [
    {
      name: "Presentation Dashboard",
      value: "presentation",
      icon: <Docs />,
      link: "/presentation/list",
      accessTo: ["PG-Users", "PG-System-Admin"]
    },
    {
      name: "Content Repositories",
      value: "content-repo",
      icon: <Modules />,
      link: "/presentation/admin/content-repo",
      accessTo: ["PG-System-Admin"]
    },
    {
      name: "Images",
      value: "images",
      icon: <SlidePreviewImageBadge />,
      link: "/presentation/admin/images",
      accessTo: ["PG-System-Admin"]
    },
    {
      name: "History",
      value: "history",
      icon: <RefreshIcon />,
      link: "/presentation/admin/history",
      accessTo: ["PG-System-Admin"]
    },
    {
      name: "Users",
      value: "users",
      icon: <Users />,
      link: "/admin/users?tool=pg",
      accessTo: ["PG-System-Admin"]
    },
    {
      name: "Reporting",
      value: "reporting",
      icon: <Reporting />,
      link: "/admin/reports?tool=pg",
      accessTo: ["PG-System-Admin"]
    },
    {
      name: "Setup",
      value: "setup",
      icon: <Settings />,
      link: "/presentation/admin/setup",
      accessTo: ["PG-System-Admin"]
    }
  ],
  renewal: [
    {
      name: "Rates Dashboard",
      value: "rates",
      icon: <Docs />,
      link: "/renewal/rates",
      accessTo: ["RG-Underwriter", "RG-Admin"],
      helperText: "(Upload Rates)"
    },
    {
      name: "Renewals Dashboard",
      value: "renewals",
      icon: <Docs />,
      link: "/renewal/renewals",
      accessTo: ["RG-Sales", "RG-Admin"],
      helperText: "(Build Renewals)"
    },
    {
      name: "Users",
      value: "users",
      icon: <Users />,
      link: "/admin/users?tool=rg",
      accessTo: ["RG-Admin"]
    },
    {
      name: "Reporting",
      value: "reporting",
      icon: <Reporting />,
      link: "/admin/reports?tool=rg",
      accessTo: ["RG-Admin"]
    },
    {
      name: "Letters",
      value: "letters",
      icon: <Letters />,
      link: "/renewal/admin/letters",
      accessTo: ["RG-Admin"]
    },
    {
      name: "Topics",
      value: "topics",
      icon: <Modules />,
      link: "/renewal/admin/topics",
      accessTo: ["RG-Admin"]
    },
    {
      name: "All Renewals and Rates",
      value: "all",
      icon: <Renewals />,
      link: "/renewal/admin",
      accessTo: ["RG-Admin"]
    }
  ],
  placemat: [
    {
      name: "Documents Dashboard",
      value: "placemat",
      icon: <Docs />,
      link: "/marketing-materials/documents",
      accessTo: ["MG-Users", "MG-Admin"]
    },
    {
      ...(featureFlags.marketingMaterials.categoryDashboard && {
        name: "Categories",
        value: "content-repo",
        icon: <Letters />,
        link: "/marketing-materials/admin/categories",
        accessTo: ["MG-Admin"]
      })
    },
    {
      name: "Modules",
      value: "modules",
      icon: <Modules />,
      link: "/marketing-materials/admin/modules",
      accessTo: ["MG-Admin"]
    },
    {
      name: "Users",
      value: "users",
      icon: <Users />,
      link: "/admin/users?tool=mg",
      accessTo: ["MG-Admin"]
    },
    {
      name: "Reporting",
      value: "reporting",
      icon: <Reporting />,
      link: "/admin/reports?tool=mg",
      accessTo: ["MG-Admin"]
    },
    {
      name: "All Documents",
      value: "all",
      icon: <Renewals />,
      link: "/marketing-materials/admin/dashboard",
      accessTo: ["MG-Admin"]
    }
  ]
};
